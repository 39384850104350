import React, { useState, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import API from 'api';
import commonInputStyle from 'styles/commonInputStyle.module.scss';
import { Wrapper, Error } from '../components';
import clsx from 'clsx';
import { Button } from 'views/UserOverlay/components';
import style from './loginAndVerify.module.scss';
import { setAuthDetails } from 'views/Auth/authSlice';
import { OverlayContext, ROUTES } from '../OverlayContext';
import { fbPixelConfig } from '../../../utils/fbPixelConfig';
import { ProgressBarBtn } from 'components/Button/ProgressBarBtn';
import { validate } from 'views/UserOverlay/Validator';
import { Checkbox } from 'antd';
import { getDefaultTrackingValues, eventType, eventLocation, clickAction } from '../../../utils/Tracker/track';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';

export const Verify = (): JSX.Element => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [counter, setCounter] = useState(60);
  const [showResend, setShowResend] = useState(true);
  const [promotionalChecked, setPromotionalChecked] = useState(true);
  const [showPromotionalOptIn, setShowPromotionalOptIn] = useState(false);

  const {
    userDetails,
    clientDetails,
    setRoute,
    routeData,
    updateView,
    postLoginFlow,
    standaloneMode,
    redirectMode,
    setUserDetails,
  } = useContext(OverlayContext);
  const inputRef = useRef(null);
  // @ts-ignore

  const locationState = routeData as { alreadyRegistered: boolean; loginMode: 'EMAIL' | 'PHONE' };
  const phoneNumber = userDetails.phoneNumber;
  const email = userDetails.email;
  let timer: NodeJS.Timeout;
  if (!phoneNumber && !email) setRoute(ROUTES.LOGIN);

  useEffect(() => {
    (async () => {
      const accountDetailsResponse = await API.user.AccountDetails({ phoneNumber: phoneNumber, email: email });
      if (!API.validators.checkSuccessCode(accountDetailsResponse)) {
        throw { message: 'Please try again later' };
      } else {
        updateView({ action: 'OTP', userId: accountDetailsResponse?.data?.userId });
      }
      if (!accountDetailsResponse.data.promotionalOptIn) setShowPromotionalOptIn(true);
    })();
  }, []);
  const analyticsStore = useSelector((store: RootState) => store.analytics);

  const loginContentView = () => {
    const loginContentViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_OTP,
      clientId: clientDetails.clientId,
      contentId: clientDetails.clientContentId,
      anonId: analyticsStore.anonId,
    };
    API.analyst.eventPost({ ...loginContentViewObject });
  };
  useEffect(() => {
    loginContentView();
  }, []);

  let counting = false;
  const startTimerForResend = (count: number) => {
    if (!counting) {
      counting = true;
      count--;
      timer = setInterval(function () {
        if (count > 0) {
          setCounter(count);
          count--;
        } else {
          setCounter(60);
          clearInterval(timer);
          counting = false;
          setShowResend(true);
        }
      }, 1000);
    }
  };

  const submitOTP = async () => {
    setError('');
    const loginResponse = await API.auth.LoginOtp({
      OTP: otp,
      promotionalOptIn: promotionalChecked,
      ...(locationState.loginMode === 'EMAIL' ? { email } : { phoneNumber }),
      clientId: clientDetails.clientId,
      eventLocation: eventLocation.CONTENT_FLOW_OTP,
      anonId: analyticsStore.anonId,
    });

    if (API.validators.matchStatusCode(loginResponse, 401)) {
      return setError(loginResponse.data.message);
    }
    if (API.validators.checkSuccessCode(loginResponse)) {
      fbPixelConfig('CompleteRegistration', {
        currency: `INR`,
        value: userDetails.contentPrice,
        status: true,
        content_ids: clientDetails.clientContentId,
        contents: 'LoginType' + locationState.loginMode,
      });
      const { token, phoneNumber, userId, sessionId, expiresAt, version, email } = loginResponse.data as any;
      dispatch(setAuthDetails({ token, phoneNumber, userId, userType: 'USER', sessionId, expiresAt, version, email }));

      const updatedUser = { ...userDetails };
      updatedUser.userId = userId;

      await postLoginFlow({ userId, setError, sessionId });
    } else {
      setError(loginResponse.data.message);
    }
  };

  async function resendOtpSubmit() {
    setError('');
    let resendCode;
    if (locationState.loginMode === 'EMAIL') {
      resendCode = await API.auth.SendVerificationEmail({ email, clientId: clientDetails.clientId });
    } else {
      resendCode = await API.auth.SendOtp({
        phoneNumber,
        clientId: clientDetails.clientId,
        eventLocation: eventLocation.CONTENT_FLOW_OTP,
        anonId: analyticsStore.anonId,
      });
    }
    if (!API.validators.checkSuccessCode(resendCode)) {
      setError(resendCode.data.message);
    }
    setShowResend(false);
    startTimerForResend(60);
  }

  const otpValid = otp.length === 4;

  useEffect(() => {
    if (otpValid && !error) {
      submitOTP();
    }
  }, [otp]);

  useEffect(() => {
    // @ts-ignore
    inputRef?.current?.focus();
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onChangeOTP = (e: any) => {
    const { value } = e.target;
    if (value.length && !validate(value, { pattern: /^\d+$/ })) setError('OTP must be 4 digits');
    else if (error) setError('');
    setOtp(value);
  };
  return (
    <Wrapper hideCross={standaloneMode && !redirectMode}>
      <div className={style['login-container']}>
        <div className={clsx(style['login-form-wrapper'], style['wrapper-top-padding'])}>
          <div className={style['title']}> OTP Verification </div>
          <div className={style['sub-title']}>
            Enter the OTP sent to {locationState.loginMode === 'EMAIL' ? <> {email}</> : <>+91 {phoneNumber} </>}
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
              <div className={style['wrap-label-user']}>
                <div
                  className={commonInputStyle['input-text']}
                  style={{ width: '250px', margin: 'auto', paddingLeft: '0px' }}
                >
                  <input
                    id="sel-verify-otp-inp"
                    type="tel"
                    onChange={onChangeOTP}
                    maxLength={4}
                    style={{ textAlign: 'center' }}
                    ref={inputRef}
                    className={commonInputStyle['otp-box']}
                  />
                </div>
                <div style={{ marginTop: '30px' }}>
                  {showPromotionalOptIn && (
                    <div className={style['terms-condition']}>
                      <Checkbox
                        id="sel-promotional-check"
                        type="checkbox"
                        checked={promotionalChecked}
                        onChange={() => {
                          setPromotionalChecked(!promotionalChecked);
                        }}
                      />
                      <p style={{ fontSize: '13px', opacity: '0.6' }}>Receive updates and special offers</p>
                    </div>
                  )}
                  {!otpValid || error ? (
                    <Button.DarkBlue
                      id="sel-login-phone-enter"
                      buttonText="Submit"
                      disabled
                      type="submit"
                      customStyle={{ width: '100%', height: '48px', marginTop: '8px' }}
                    />
                  ) : (
                    <ProgressBarBtn />
                  )}

                  {showResend ? (
                    <div className={style['otp-request']}>
                      Didn’t receive OTP?
                      <span
                        className={style['cursor-blue-link']}
                        onClick={resendOtpSubmit}
                        id="el-get-OTP-verify"
                        style={{ fontSize: '13px' }}
                      >
                        Resend
                      </span>
                    </div>
                  ) : (
                    <div className={style['resend-otp-text']} id="sel-verify-otp-count">
                      You can request another OTP in {counter} seconds
                    </div>
                  )}

                  <div className={style['otp-request']}>
                    Not you?
                    <span
                      className={style['cursor-blue-link']}
                      onClick={() => setRoute(ROUTES.LOGIN)}
                      style={{ fontSize: '13px' }}
                    >
                      Click here
                    </span>
                  </div>
                </div>

                {error && (
                  //@ts-ignore
                  <Error customStyle={{ position: 'absolute', width: '100%' }} id="sel-verify-invalid-error">
                    {error}
                  </Error>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};
