import API from 'api';
import mobileRedirectionMessage from 'utils/notifyAppToRedirect';
import { addQueryParamToUrl } from 'views/UserOverlay/paywallUtils';

const loginChallengeRedirectKey = '_csc-login-challenge-redirect';

export async function loginChallengeCheck(sessionId: string) {
  const searchParams = new URLSearchParams(location.search);
  let loginChallengeId = searchParams.get('loginChallenge') || '';

  // siteUrl will have login challenge id in case flow begins from subscribe now button!
  if (!loginChallengeId && searchParams.get('siteUrl')) {
    const siteUrl = decodeURIComponent(searchParams.get('siteUrl') || '');
    const siteUrlSearchParams = new URLSearchParams(new URL(siteUrl).search);
    loginChallengeId = siteUrlSearchParams.get('loginChallenge') || '';
  }
  const loginChallegeResponse = await API.user.updateLoginChallenge(loginChallengeId, sessionId || '');

  if (loginChallegeResponse.status === 200) {
    const { redirectTo } = loginChallegeResponse.data as { redirectTo: string };
    if (redirectTo) {
      sessionStorage.setItem(loginChallengeRedirectKey, redirectTo);
    }
  }
}

export async function redirectToContent({
  redirectUrl,
  clientId,
  clientContentId,
  setError,
  sessionId,
}: {
  redirectUrl: string;
  clientId: string;
  clientContentId?: string;
  setError?: (s: string) => any;
  sessionId: string;
}) {
  // Added this for android redirection
  mobileRedirectionMessage();

  const loginChallengeRedirectUrl = sessionStorage.getItem(loginChallengeRedirectKey);
  if (loginChallengeRedirectUrl) return (window.location.href = loginChallengeRedirectUrl);

  let finalRedirectUrl = redirectUrl;
  if (clientContentId) {
    const redirectUrlResponse = await API.client.RedirectUserToContent({
      clientId: clientId,
      clientContentId: clientContentId,
    });
    if (!API.validators.checkSuccessCode(redirectUrlResponse)) {
      return setError?.('Failed to load redirect URL');
    }
    finalRedirectUrl = redirectUrlResponse.data?.redirectUrl;
  }

  finalRedirectUrl = addQueryParamToUrl({ key: '_csc-sessionId', value: sessionId || '' }, finalRedirectUrl);
  window.location.href = decodeURIComponent(finalRedirectUrl);
}
