export default function mobileRedirectionMessage() {
  // for android

  try {
    // const subscriptionPurchased = JSON.parse(localStorage.getItem('selectedSubscriptions') || '{}');
    const adFree = false;

    if (adFree) {
      // @ts-ignore
      redirectionHandler.onAdFree();
    } else {
      // @ts-ignore
      redirectionHandler?.redirectToContent();
    }
  } catch (error) {
    console.warn('did not find android redirect handler');
  }

  // for flutter
  try {
    // @ts-ignore
    window?.LOGINCHALLENGE?.postMessage('loginSuccess');
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('LOGINCHALLENGE', 'loginSuccess');
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }
}

export function paymentHandlerForApp(paymentDetails: {
  subscriptionId: string;
  clientId: string;
  currency: string;
  amount: number;
  tierId: string;
  userId: string;
  contentId?: string;
}) {
  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.callbackHandler.postMessage(paymentDetails);
  } catch (error) {
    console.warn('did not find ios redirect handler');
  }

  //for flutter
  try {
    // @ts-ignore
    window.APPLEPAY.postMessage('callbackHandler');
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }
}

export function changePlanRedirectionForMobile() {
  // for android

  try {
    // @ts-ignore
    redirectionHandler?.redirectToPlans();
  } catch (error) {
    console.warn('did not find android redirect handler');
  }

  // for flutter
  try {
    // @ts-ignore
    window.LOGINCHALLENGE.postMessage('changeplan');
  } catch (error) {
    console.warn('did not find ios redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('LOGINCHALLENGE', 'changeplan');
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }
}

export function cancelPlanRedirectionForMobile() {
  // for android
  try {
    // @ts-ignore
    redirectionHandler?.redirectToPlans();
  } catch (error) {
    console.warn('did not find android redirect handler');
  }

  // for flutter
  try {
    // @ts-ignore
    window.CANCEL.postMessage('cancel');
  } catch (error) {
    console.warn('did not find ios redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('CANCEL', 'cancel');
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }
}

export function googleLoginButtonHandlerForMobile() {
  // for ios

  try {
    //@ts-ignore
    window.webkit.messageHandlers.signgooglelogin.postMessage('signgooglelogin');
  } catch (error) {
    console.warn('did not find ios redirect handler');
  }
}

export function autoLoginMobileMessageHandler(value: string) {
  // for flutter
  try {
    // @ts-ignore
    window.AUTOLOGIN.postMessage(value);
  } catch (error) {
    console.warn('did not find autoLoginMobileMessageHandler handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('AUTOLOGIN', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }
}
