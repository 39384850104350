import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import style from '../login/LoginCustomisation.module.scss';
import { Form } from 'antd';
import { LoginWithGoogleButton } from 'components/user/LoginUI';
import logoUrl from '../../UserOverlay/assets/icons/logo.png';
import { ReactComponent as EmailUser } from './../../../../src/assets/emailUser.svg';
import { validateEmail, ValidateMobileNumber } from '../../../utils/validators';
import facebookLogo from 'assets/facebookLogo.svg';
import { setAuthDetails } from 'views/Auth/authSlice';
import * as queryString from 'query-string';
import { Button } from 'views/UserOverlay/components';
const UserSignUpAndLogin = (props: any) => {
  const {
    type,
    clientId,
    setPhoneNumber,
    setUserEmail,
    setLoginType,
    data,
    uiConfig,
    onScreenChange,
    isNotBorder,
    loginClick,
  }: {
    type: string;
    clientId: string;
    setPhoneNumber: any;
    setUserEmail: any;
    setLoginType: any;
    data: any;
    uiConfig: any;
    onScreenChange: any;
    isNotBorder?: boolean;
    loginClick: (loginType: 'EMAIL' | 'MOBILE' | 'GOOGLE') => any;
  } = props;
  const signInBtnCustomStyle = {
    margin: '40px auto 0px',
    width: '370px',
    height: '48px',
    font: 'normal normal 500 16px/33px Montserrat',
  };

  const facebookBtnCustomStyle = {
    width: '105px',
    height: '35px',
    background: '#1A77F2',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    font: ' normal normal 700 12px/14px Arial',
    marginTop: '0px',
    padding: '0px 8px',
  };
  const googleBtnCustomeStyle = {
    width: '105px',
    height: '35px',
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    font: ' normal normal 700 12px/14px Arial',
    color: '#757575',
    marginTop: '0px',
  };

  const [isMoreDetails, setIsMoreDetails] = useState(false);
  const dispatch = useDispatch();

  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState('');
  let pageUrl = '';
  if (type === 'SSO') {
    pageUrl = new URLSearchParams(location.search).get('pageUrl') as string;
  } else if (type === 'SUBS') {
    pageUrl = window.location.href;
  } else if (type === 'CONTENT_FLOW') {
    pageUrl = new URLSearchParams(location.search).get('pageUrl') as string;
  }

  const [enableSubmitButton, setEnableSubmitButton] = useState(false);

  const checkinput = (value: any) => {
    if (data.enableEmailLogin) {
      if (validateEmail(value)) {
        setUserEmail(value);
        setLoginType('EMAIL');
        return setEnableSubmitButton(true);
      }
    }
    if (data.enableMobileLogin) {
      if (ValidateMobileNumber(value)) {
        setPhoneNumber(value);
        setLoginType('PHONE');
        return setEnableSubmitButton(true);
      }
    }
    setEnableSubmitButton(false);
  };
  const stringifiedParams = queryString.stringify({
    client_id: '367167408962065',
    redirect_uri: `${process.env.REACT_APP_API_URL}/auth/facebook/redirect`,
    scope: ['email'].join(','), // comma seperated string
    response_type: 'code',
    state: JSON.stringify({
      url: decodeURIComponent(pageUrl),
      clientId,
    }),
    auth_type: 'rerequest',
    display: 'popup',
  });
  const facebookLoginUrl = `https://www.facebook.com/v4.0/dialog/oauth?${stringifiedParams}`;

  const postGoogleLogin = async (googleData: any) => {
    const { token, email, userId, phoneNumber, sessionId, expiresAt, version } = googleData as any;
    dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, phoneNumber, email, userType: 'USER', version }));
    location.reload();
  };
  return (
    <>
      {!isMoreDetails && !isOtp && (
        <div className={style['container-wrap']}>
          <div className={style['container']} style={isNotBorder ? { background: 'transparent', border: 'none' } : {}}>
            <Form
              onFinish={() => {
                if (enableSubmitButton) {
                  onScreenChange('otp');
                }
              }}
            >
              <img className={style['logo']} src={data?.brandLogoUrl ? data?.brandLogoUrl : logoUrl} alt="Logo" />
              <div className={style['message']} style={{ color: uiConfig?.mainTitle?.color }}>
                {uiConfig?.mainTitle?.text || 'Welcome back!'}
              </div>
              <div className={style['longMessage']} style={{ color: uiConfig?.subTitle?.color }}>
                {uiConfig?.subTitle?.text || 'Please enter your sign in details below'}
              </div>
              <div className={style['signUpAndLogin']}>
                <span className={style['spanSignUpAndLogin']} style={{ color: uiConfig?.formTitle?.color }}>
                  {' '}
                  {uiConfig?.formTitle?.text || 'Sign In/Sign Up'}
                </span>
              </div>
              <>
                <div style={{ margin: '20px auto 0px' }} className={style['inputFieldContainer']}>
                  {<EmailUser className={style['userInput']} />}
                  <input
                    onChange={(e) => {
                      checkinput(e.target.value);
                    }}
                    placeholder={uiConfig?.primaryLogintext?.text || 'Mobile / Email'}
                    className={style['inputField']}
                    type="text"
                    maxLength={40}
                  />
                </div>
              </>

              <Button
                disabled={!enableSubmitButton}
                customStyle={{
                  ...signInBtnCustomStyle,
                  backgroundColor: uiConfig?.buttonText?.buttonColor,
                  color: uiConfig?.buttonText?.color,
                }}
                customMobileStyle={{
                  width: '95%',
                }}
                onClickFunction={() => {
                  onScreenChange('otp');
                }}
              >
                {uiConfig?.buttonText?.text || 'Get OTP'}
              </Button>
            </Form>

            {(data?.enableGoogleLogin || data?.enableFacebookLogin) && (
              <div className={style['continueWithContainer']} style={{ margin: '38px auto 0px' }}>
                <hr className={style['horizontalLine']} />
                <div className={style['continueWith']} style={{ color: uiConfig?.dividerText?.color }}>
                  {uiConfig?.dividerText?.text || 'Or Continue with'}
                </div>
                <hr className={style['horizontalLine']} />
              </div>
            )}
            <div
              onClick={() => {
                loginClick('GOOGLE');
              }}
              className={style['otherLogin']}
            >
              {data?.enableGoogleLogin && (
                <Button customStyle={googleBtnCustomeStyle}>
                  <div style={{ display: 'flex' }}>
                    <div className={style['googleFacebookApple']}>
                      {' '}
                      <LoginWithGoogleButton
                        postGoogleLogin={postGoogleLogin}
                        setError={setError}
                        clientId={clientId}
                        text="Google"
                        marginAfterGoogleLogin={{ marginRight: '15px' }}
                        type={type}
                      />
                    </div>
                  </div>
                </Button>
              )}
              {data?.enableFacebookLogin && (
                <>
                  {' '}
                  <Button customStyle={facebookBtnCustomStyle}>
                    <a href={facebookLoginUrl} target="_PARENT" rel="noreferrer">
                      <div style={{ display: 'flex' }}>
                        <img height={16} width={16} src={facebookLogo} alt="Facebook logo" />
                        <div className={style['googleFacebookApple']}>Facebook</div>
                      </div>
                    </a>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSignUpAndLogin;
