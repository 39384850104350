import React, { useEffect, useContext } from 'react';
import style from './smallAnimatedCheck.module.scss';
import { OverlayContext } from '../../OverlayContext';
import { fbPixelConfig } from '../../../../utils/fbPixelConfig';

export default function AnimatedCheck(): JSX.Element {
  const { clientDetails, userDetails } = useContext(OverlayContext);
  useEffect(() => {
    fbPixelConfig('Purchase', {
      currency: `INR`,
      value: userDetails.contentPrice,
      status: true,
      content_ids: clientDetails.clientContentId,
    });
  }, []);
  return (
    <div className={style['container']}>
      <svg className={style['checkmark']} viewBox="0 0 52 52">
        <circle className={style['checkmark-circle']} cx="26" cy="26" r="25" fill="none" />
        <path className={style['checkmark-check']} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </div>
  );
}
