import React, { useState, useEffect, useContext } from 'react';
import style from 'views/UserOverlay/views/loginAndVerify.module.scss';
import { GoogleLogin } from 'react-google-login';
import googleLogo from 'assets/google-logo.svg';
import API from 'api';
import { TermsOfServicesLink } from 'consts';
import CSS from 'csstype';
import { OverlayContext } from 'views/UserOverlay/OverlayContext';
import { Checkbox } from 'antd';
import { getDefaultTrackingValues, eventType, eventLocation } from '../../utils/Tracker/track';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { googleLoginButtonHandlerForMobile } from 'utils/notifyAppToRedirect';
import UserSignUpAndLogin from 'views/ClientDashboard/loginUI/UserSignUpAndLogin';
import { type } from 'os';

export const TandCComponent = (props: { checked: boolean; setError?: any; setChecked: any; error?: string }) => (
  <>
    <div className={style['terms-condition']}>
      <Checkbox
        id="sel-term-condition-check"
        type="checkbox"
        className={style['checkbox']}
        checked={props.checked}
        onChange={(e) => {
          if (e.target.checked) props.setError?.('');
          props.setChecked(!props.checked);
        }}
      />
      <p>
        I agree to the
        <a
          href={TermsOfServicesLink}
          target="_blank"
          rel="noreferrer"
          id="sel-term-condition-pdf"
          className={style['cursor-blue-link']}
        >
          Terms of service
        </a>
      </p>
    </div>
  </>
);

export function LoginWithGoogleButton(props: {
  setError?: any;
  postGoogleLogin: (arg0: any, arg1: any) => any;
  customStyle?: CSS.Properties;
  text?: string;
  marginAfterGoogleLogin?: CSS.Properties;
  clientId?: string;
  clientTierId?: string;
  type?: string;
}) {
  const { clientDetails } = useContext(OverlayContext);

  const [disableGoogleLogin, setDisableGoogleLogin] = useState(false);
  const [googleErrorOnClick, setGoogleErrorOnClick] = useState('');
  const [thirdPartyCookiesBlocked, setThirdPartyCookiesBlocked] = useState(false);

  const handleGoogleLogin = async (googleData: any) => {
    props.setError('');
    if (googleData.error === 'idpiframe_initialization_failed') {
      setThirdPartyCookiesBlocked(true);
    } else if (googleData?.details?.startsWith('Not a valid origin')) {
      console.error('google login failed : not a valid origin');
      setGoogleErrorOnClick('Google login not enabled for this domain');
      setDisableGoogleLogin(true);
    } else {
      if (!googleData.tokenId) return props.setError('Google login cancelled / failed');

      const googleLoginResponse = await API.auth.GoogleLogin({
        token: googleData.tokenId,
        clientId: props.clientId,
        clientTierId: props.clientTierId,
      });
      if (!API.validators.checkSuccessCode(googleLoginResponse))
        return props.setError(googleLoginResponse.data.message);
      if (API.validators.checkSuccessCode(googleLoginResponse)) {
        const { token, email, userId, sessionId, expiresAt, version } = googleLoginResponse.data as any;
        await props.postGoogleLogin(
          {
            token,
            email,
            userId,
            sessionId,
            expiresAt,
            version,
          },
          props?.setError,
        );
      } else {
        props.setError('Login failure, please try later');
      }
    }
  };

  return (
    <>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        buttonText="Login With Google"
        onSuccess={thirdPartyCookiesBlocked ? undefined : handleGoogleLogin}
        onFailure={thirdPartyCookiesBlocked ? undefined : handleGoogleLogin}
        uxMode={thirdPartyCookiesBlocked ? 'redirect' : 'popup'}
        cookiePolicy={'single_host_origin'}
        responseType={thirdPartyCookiesBlocked ? 'code' : undefined}
        render={(renderProps: any) => (
          <button
            id="sel-login-google"
            type="button"
            onClick={() => {
              googleLoginButtonHandlerForMobile();
              if (disableGoogleLogin) {
                props.setError(googleErrorOnClick);
              } else {
                renderProps.onClick();
              }
              if (thirdPartyCookiesBlocked) {
                sessionStorage.setItem('loginSiteUrl', window.location.href);
                window.location.href = `https://accounts.google.com/signin/oauth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=openid%20email&redirect_uri=${process.env.REACT_APP_API_URL}/auth/google/redirect`;
              }
            }}
            className={style['google-btn']}
          >
            <img src={googleLogo} alt="googleLogo" className={style['google-logo']} />
            {props.text}
          </button>
        )}
      />
    </>
  );
}

export function LoginUI(props: {
  onPhoneSubmit: (phoneNumber: string, setError: (error: string) => any) => any;
  onEmailSubmit: (email: string, setError: (error: string) => any) => any;
  postGoogleLogin: (
    userData: {
      token: string;
      email: string;
      userId: string;
      sessionId: string;
      expiresAt: string;
      version: string;
    },
    setError: (error: string) => any,
  ) => any;
  onLoginMethodChange?: (loginMethod: 'EMAIL' | 'PHONE') => any;
  loginMethod?: 'EMAIL' | 'PHONE';
  validCouponCode?: string;
  detectedCountry?: string;
  marginAfterGoogleLogin?: boolean;
  clientId?: string;
  viewId?: string;
  isOverlay?: boolean;
}) {
  const { clientDetails } = useContext(OverlayContext);
  const analyticsStore = useSelector((store: RootState) => store.analytics);

  const loginContentView = () => {
    const loginContentViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
      clientId: clientDetails.clientId,
      contentId: clientDetails.clientContentId,
      anonId: analyticsStore.anonId,
    };
    API.analyst.eventPost({ ...loginContentViewObject });
  };
  useEffect(() => {
    loginContentView();
  }, []);

  return (
    <>
      <form>
        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
          <div className={style['wrap-label']}>{<UserSignUpAndLogin type="CONTENT_FLOW" />}</div>
        </div>
      </form>
    </>
  );
}
