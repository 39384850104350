import React, { useState, useEffect, useContext } from 'react';
import API from 'api';
import { useSelector } from 'react-redux';
import style from './thankYou.module.scss';
import { SubscriptionContext } from '../SubscriptionContext';
import { RootState } from 'app/rootReducer';
import { includeGtag, pageViewForIT, purchaseSubsEventForIToday } from 'utils/gtagConfig';
import { fireFacebookConversionPixelForIT } from 'utils/fbPixelConfig';
import { loginChallengeCheck, redirectToContent } from './SubsUtils';

/**
 * NOTE: do NOT use subscription page params here because this page is reached after a transaction redirect
 * (you can only get data from contentdetails of the transaction via the subscription context)
 */
function ThankYou() {
  const { userDetails, subscriptionSettings, clientName, clientId, clientContentId, offerIds } = useContext(
    SubscriptionContext,
  );
  const [error, setError] = useState('');
  const authStore = useSelector((store: RootState) => store.auth);
  const [moreOffers, setMoreOffers] = useState(
    [] as { text: string; iconUrl: string; redirectLink: string; associatedOffers: string[] }[],
  );
  const [timer, setTimer] = React.useState(7);
  const [autoRedirect, setAutoRedirect] = useState(true);

  useEffect(() => {
    if (!subscriptionSettings) return;
    const filteredOffers = [];

    // login challenge code
    loginChallengeCheck(authStore.sessionId || '');

    if (subscriptionSettings?.thankYouPageElements?.length) {
      for (const offer of subscriptionSettings?.thankYouPageElements) {
        if (!offer?.associatedOffers?.length) {
          filteredOffers.push(offer);
        } else {
          const passed = offer?.associatedOffers?.some((offer: any) => offerIds?.indexOf(offer) >= 0);
          if (passed) filteredOffers.push(offer);
        }
      }
    }

    setMoreOffers(filteredOffers);
  }, [subscriptionSettings]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    } else if (autoRedirect && timer === 0) {
      _redirectToContent();
    }
  });

  useEffect(() => {
    (async () => {
      // hardcoded only for indiatoday in Sandbox & live
      if (['60c47342f9f9aa60f29d52b0', '609bf79cb479fa223a707ccb'].includes(clientId)) {
        const getPurchasedData = await API.user.latestPurchaseDetails({ clientId });
        const transactionId: string = getPurchasedData?.data?.purchaseId;
        const price: number = getPurchasedData?.data?.price;
        const currency: string = getPurchasedData?.data?.currency;

        await includeGtag('AW-10877099323');
        pageViewForIT();
        purchaseSubsEventForIToday({
          value: price,
          currency,
          transactionId,
        });
        fireFacebookConversionPixelForIT('837900744174810', price, currency);
      }
    })();
  }, []);

  const _redirectToContent = async () => {
    return redirectToContent({
      redirectUrl: subscriptionSettings.redirectUrl,
      setError: (s: string) => {
        setAutoRedirect(false);
        setError(s);
      },
      clientId,
      clientContentId,
      sessionId: authStore.sessionId || '',
    });
  };

  return (
    <div className={style['main-container']}>
      <p className={style['main-header']}>
        {subscriptionSettings.successTitle ? subscriptionSettings.successTitle : 'Thank you for Subscribing!'}
      </p>
      {subscriptionSettings.successIconUrl ? (
        <img className={style['cover-img']} src={subscriptionSettings.successIconUrl} alt="brandCover-img" />
      ) : (
        ''
      )}
      <div className={style['col-reverse']}>
        <p className={style['description-text']}>
          Your subscription details have been mailed to {userDetails.email || userDetails.secondaryEmail}
          <br />
          <p className={style['description-text-mobile']}></p>
          {subscriptionSettings.successMessage}
        </p>
        <p style={{ color: 'red' }}>{error}</p>
        {/* FIXME: replace with non-hardcoded values */}

        <div className={style['offer-container']}>
          {moreOffers?.length ? <p className={style['offer-text']}> More Offers For You</p> : <></>}

          {moreOffers?.map((offer, i) => (
            <div key={i} className={style['offer-card']}>
              {offer.iconUrl && (
                <img className={style['img']} src={offer.iconUrl} alt="offer-img" width={30} height={30} />
              )}
              <a href={offer.redirectLink} target="blank">
                {' '}
                <p className={style['text']}>{offer.text}</p>{' '}
              </a>
            </div>
          ))}
        </div>

        {autoRedirect && (
          <p style={{ fontSize: '16px', color: 'black' }}>
            You will be Redirected to {clientName} in <span style={{ color: '#b10f15' }}>{timer} seconds</span>
          </p>
        )}

        <button className={style['continue-button']} onClick={() => _redirectToContent()}>
          Continue
        </button>
        {autoRedirect && (
          <p
            className={style['description-text']}
            style={{
              cursor: 'pointer',
              marginTop: '16px',
              fontSize: '16px',
              textDecoration: 'underline',
              color: '#b10f15',
            }}
            onClick={() => setAutoRedirect(false)}
          >
            Stay on this page
          </p>
        )}
      </div>
    </div>
  );
}

export default ThankYou;
