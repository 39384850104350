import React, { useEffect, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { fbPixelConfig, fireFacebookPixel } from '../../../utils/fbPixelConfig';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postMessage, shouldRedirect } from '../paywallUtils';
import { OverlayContext } from '../OverlayContext';
import TestTemplate from '../../ClientDashboard/Paywall/TestTemplate';
import { Wrapper } from '../components';
import DefaultTemplate from 'views/ClientDashboard/Paywall/DefaultTemplate';
import API from 'api';
import LoadingCircle from '../components/loaders/LoadingCircle';
import { setAnalyticsData } from '../../../app/analyticsSlice';
interface DisplayData {
  templateId: {
    _id: string;
    name: string;
    paywallType: string;
    displayType: string;
    deviceType: string;
    number_of_cta: number;
  };
  content: string;
  json: string;
}
export interface PaywallData {
  isFallback: boolean;
  journeyId: string;
  data?: {
    configuration: {
      desktop: DisplayData;
      mobile: DisplayData;
    };
    _id: string;
  };
}

export default function Welcome() {
  const ref1 = useRef(null);
  const dispatch = useDispatch();
  const wrapperRef = useRef<any>();
  const authStore = useSelector((store: RootState) => store.auth);
  const [scrolledToPaywall, setScrolledToPaywall] = useState(false);
  const history = useHistory();
  const { userDetails, loading, buttonMode, updateView, clientDetails, postLoginFlow } = useContext(OverlayContext);
  const searchParams = new URLSearchParams(location.search);
  const [paywallData, setPaywallData] = useState({ isFallback: false } as PaywallData);
  const [paywallDataLoading, setPaywallDataLoading] = useState(true);
  let paywallResponse = '' as any;
  const paywallId = searchParams.get('paywallId') || '';
  dispatch(setAnalyticsData({ paywallId }));

  const isMobile = useSelector((state: RootState) => state.config.isMobile);
  useEffect(() => {
    if (scrolledToPaywall) updateView({ action: 'SCROLL' });
  }, [scrolledToPaywall]);

  useEffect(() => {
    if (paywallData?.data?._id) {
      updateView({ action: 'SCROLL', paywallId: paywallData?.data?._id });
    }
  }, [paywallData]);

  useEffect(() => {
    if (wrapperRef?.current?.offsetHeight) {
      postMessage.SET_IFRAME_DIMENSION(
        wrapperRef.current.offsetHeight + 20,
        buttonMode ? wrapperRef.current.offsetWidth : 0,
      );
    }
  }, [wrapperRef.current?.offsetHeight, loading]);

  useEffect(() => {
    if (!updateView) return;
    if (!wrapperRef.current) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setScrolledToPaywall(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );
    observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    if (!loading) {
      //@ts-ignore
      const facebookViewEvent = userDetails.contentPixels?.facebook?.events.find((o) => o.eventType === 'VIEW');
      //@ts-ignore
      const fbPixelId = userDetails.contentPixels?.facebook?.pixelId;
      if (facebookViewEvent) {
        fireFacebookPixel(fbPixelId, facebookViewEvent.name);
      }
    }
  }, [loading]);

  useEffect(() => {
    // if status is present, there is a redirect to add money page
    if (history.action === 'POP' && !searchParams.get('status')) {
      postMessage.WELCOME_PAGE_REACHED();
      if (authStore.token) {
        postMessage.LOGGEDIN(true, authStore.sessionId);
      }
    }
    if (searchParams.get('status') === 'FAILURE_PRICE') {
      if (searchParams.get('pageUrl')) {
        const clientUrl = searchParams.get('pageUrl')?.split('?')[0];
        window.location.href = clientUrl + '?error=FAILURE_PRICE';
      } else {
        postMessage.WELCOME_PAGE_REACHED();
      }
    }
  }, [location.search]);

  useEffect(() => {
    fbPixelConfig('PageView');
  }, []);

  useEffect(() => {
    // if status is present, there is a redirect to add money page
    if (history.action === 'POP' && !searchParams.get('status')) {
      postMessage.WELCOME_PAGE_REACHED();
      if (authStore.token) {
        postMessage.LOGGEDIN(true, authStore.sessionId, authStore.userId);
      } else {
        if (!shouldRedirect()) postMessage.CLEAR_SESSION_DETAILS();
      }
    }
    if (searchParams.get('status') === 'FAILURE_PRICE') {
      if (searchParams.get('pageUrl')) {
        const clientUrl = searchParams.get('pageUrl')?.split('?')[0];
        window.location.href = clientUrl + '?error=FAILURE_PRICE';
      } else {
        postMessage.WELCOME_PAGE_REACHED();
        // setError('Price of the content updated, please retry payment');
      }
    }

    if (searchParams.get('error') === 'FAILURE_PRICE') {
      // setError('Price of the content updated, please retry payment');
    }
  }, [location.search]);

  const templateId = isMobile
    ? paywallData.data?.configuration?.mobile?.templateId?._id
    : paywallData.data?.configuration?.desktop?.templateId?._id;
  dispatch(setAnalyticsData({ paywallId, templateId }));

  const callPostLogin = async ({ userId, sessionId }: { userId: string; sessionId: string }) => {
    await postLoginFlow({ userId, sessionId, templateId });
  };

  const callpaywallfunction = async () => {
    if (paywallId) {
      paywallResponse = await API.client.paywallConfig({ paywallId, clientid: clientDetails.clientId });
      if (!API.validators.checkSuccessCode(paywallResponse)) {
        console.error('no active pawall found');
      } else {
        const templateData = isMobile
          ? paywallResponse.data?.configuration?.mobile?.templateId
          : paywallResponse.data?.configuration?.desktop?.templateId;

        const paywallType = templateData?.paywallType;
        const templateId = templateData?._id;
        const sessionId = authStore.sessionId || '';
        const userId = authStore.userId || userDetails?.userId;
        if (userId && paywallType === 'REGWALL') {
          await postLoginFlow({ userId, sessionId, templateId });
        } else {
          setPaywallData(paywallResponse.data);
          setPaywallDataLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    callpaywallfunction();
  }, [clientDetails?.clientId, loading]);

  const displayType = isMobile
    ? paywallData.data?.configuration?.mobile?.templateId?.displayType
    : paywallData.data?.configuration?.desktop?.templateId?.displayType;

  if (paywallDataLoading || loading)
    return (
      <>
        <LoadingCircle />
      </>
    );

  return (
    <div ref={ref1}>
      {paywallData?.isFallback ||
      (!paywallData.data?.configuration?.desktop?.content && !isMobile) ||
      (!paywallData.data?.configuration?.mobile?.content && isMobile) ? (
        <DefaultTemplate />
      ) : (
        <Wrapper
          hideCross
          hideFooter
          displayType={displayType}
          bgColor={
            //@ts-ignore
            paywallData.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.json?.styleForBackground?.border
              ?.mainDiv?.backgroundColor || '#212426'
          }
        >
          <TestTemplate
            paywallData={paywallData}
            paywallDataLoading={paywallDataLoading}
            callPostLogin={callPostLogin}
          />
        </Wrapper>
      )}
    </div>
  );
}
