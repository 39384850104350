import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { setConfigDetails } from './configslice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthParent from 'views/Auth/AuthParent';
const ContextWrapper = lazy(() => import('views/Dashboard/DashboardContext'));
import Dashboard from 'views/Dashboard/Dashboard';
import { setAuthDetails } from 'views/Auth/authSlice';
// Not using lazy load on user overlay for maximum speed + no distortion
import UserOverlay from 'views/UserOverlay/Overlay';
import LoginCheck from 'views/LoginCheck';
import CookieCheck from 'views/CookieCheck';
const AdminDashboard = lazy(() => import('views/AdminDashboard/AdminDashboard'));
const ClientDashboard = lazy(() => import('views/ClientDashboard/ClientDashboard'));
const GenericErrorPage = lazy(() => import('../components/GenericErrorPage'));
const Donation = lazy(() => import('views/Donation/Donation'));
const EReader = lazy(() => import('views/EReader/EReaderHomePage'));
import { RootState } from 'app/rootReducer';
import { consts } from 'utils';
import './App.less';
import LoadingCircle from 'views/UserOverlay/components/loaders/LoadingCircle';
import SubscriptionMain from 'views/Subscription/SubscriptionMain';
import ConscentLoginOverlay from 'views/ConscentLogin/ConscentLoginOverlay';
import BundledSubscriptionMain from 'views/BundledSubscriptions/BundledSubscriptionMain';
import SubsEditPage from 'components/SubsEditPage';
import { setAnalyticsData } from './analyticsSlice';
import AutoLogin from 'views/ClientDashboard/components/AutoLogin/AutoLogin';
import { hotJar } from 'utils/Tracker/hotjarConfig';
import UserStatus from 'views/ClientDashboard/components/UserStatus/UserStatus';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  if (window.innerWidth <= 575) {
    dispatch(setConfigDetails({ isMobile: true }));
  } else {
    dispatch(setConfigDetails({ isMobile: false }));
  }

  if (window.innerWidth <= 992) {
    dispatch(setConfigDetails({ isTabletOrMobile: true }));
  } else {
    dispatch(setConfigDetails({ isTabletOrMobile: false }));
  }

  const isMobile = useSelector((state: RootState) => state.config.isMobile);
  // console.log(isMobile, 'isMobile_check');

  const isTabletOrMobile = useSelector((state: RootState) => state.config.isTabletOrMobile);
  const authStore = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    const loginCode = searchParams.get('loginCode');
    const anonId = searchParams.get('anonId') || '';
    dispatch(setAnalyticsData({ anonId }));
    if (loginCode) {
      // remove backslashes in case json stringification added slashes
      dispatch(setAuthDetails({ ...JSON.parse(loginCode.replace(/\\/g, '')), userType: 'CLIENT' }));
      return history.push(consts.CLIENT_HOME_PATH);
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth, 'window_innerwidth');
      if (isMobile && window.innerWidth > 575) {
        dispatch(setConfigDetails({ isMobile: false }));
      } else if (!isMobile && window.innerWidth <= 575) {
        dispatch(setConfigDetails({ isMobile: true }));
      }

      if (isTabletOrMobile && window.innerWidth > 992) {
        dispatch(setConfigDetails({ isTabletOrMobile: false }));
      } else if (!isTabletOrMobile && window.innerWidth <= 992) {
        dispatch(setConfigDetails({ isTabletOrMobile: true }));
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  useEffect(() => {
    hotJar();
  }, []);
  // Configure subdomain based routing
  const hostname = window.location.hostname;
  let subdomainType: 'ADMIN' | 'CLIENT' | 'USER' | 'EREADER' | '' = '';
  const domainParts = hostname.split('.');
  if (domainParts.length > 1) {
    const subdomain = domainParts[0];
    if (subdomain.indexOf('user') >= 0) {
      subdomainType = 'USER';
      dispatch(setConfigDetails({ subdomainType }));
    } else if (subdomain.indexOf('client') >= 0) {
      subdomainType = 'CLIENT';
      dispatch(setConfigDetails({ subdomainType }));
    } else if (subdomain.indexOf('admin') >= 0) {
      subdomainType = 'ADMIN';
      dispatch(setConfigDetails({ subdomainType }));
    } else if (subdomain.indexOf('e-reader') >= 0) {
      subdomainType = 'EREADER';
      dispatch(setConfigDetails({ subdomainType }));
    }
    dispatch(setConfigDetails({ subdomainType }));
  }

  const searchParams = new URLSearchParams(location.search);

  const nextUrl = searchParams.get('nextUrl');
  useEffect(() => {
    const redirectUserToSubscriptionsId = searchParams.get('subscription');

    if (redirectUserToSubscriptionsId)
      sessionStorage.setItem('redirectUserToSubscriptionsId', redirectUserToSubscriptionsId);

    // nextUrl is only for client
    if (!authStore.token || authStore.userType !== 'CLIENT') return;
    if (nextUrl) {
      return history.push('dashboard/' + nextUrl);
    }
  });

  return (
    <>
      <Switch>
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_HOME_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <ContextWrapper>
                <Dashboard />
              </ContextWrapper>
            </Suspense>
          </Route>
        )}
        {
          // Using routes within fragments breaks the switch,
          // which is why the user condition is repeated again for a new route
        }
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_OVERLAY_PATH}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <UserOverlay />
            </GoogleOAuthProvider>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_LOGIN_CHECK_PATH}>
            <LoginCheck />
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_COOKIE_CHECK_PATH}>
            <CookieCheck />
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_DONATION_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <Donation />
            </Suspense>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_SUBSCRIPTION_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <SubscriptionMain />
            </Suspense>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_BUNDLED_SUBSCRIPTION_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <BundledSubscriptionMain />
            </Suspense>
          </Route>
        )}
        <Route path={consts.USER_EDIT_SUBS}>
          <Suspense fallback={<LoadingCircle />}>
            <SubsEditPage phonenumber={authStore.phoneNumber || ''} email={authStore.email || ''} />
          </Suspense>
        </Route>

        <Route path={consts.GENERIC_ERROR_PATH}>
          <Suspense fallback={<LoadingCircle />}>
            <GenericErrorPage />
          </Suspense>
        </Route>

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_CONSCENT_LOGIN}>
            <Suspense fallback={<LoadingCircle />}>
              <ConscentLoginOverlay />
            </Suspense>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.AUTO_LOGIN_USER}>
            <Suspense fallback={<LoadingCircle />}>
              <AutoLogin />
            </Suspense>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_STATUS}>
            <Suspense fallback={<LoadingCircle />}>
              <UserStatus />
            </Suspense>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'ADMIN') && (
          <Route path={consts.ADMIN_HOME_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <AdminDashboard />
            </Suspense>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'CLIENT') && (
          <Route path={consts.CLIENT_HOME_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <ClientDashboard />
            </Suspense>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'EREADER') && (
          <Route path={consts.EREADER_HOME_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <EReader />
            </Suspense>
          </Route>
        )}
        <Route path="/">
          <AuthParent />
        </Route>
      </Switch>
    </>
  );
}

export default App;
