import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import API from 'api';
import { Layout, Menu } from 'antd';
import style from './dashboard.module.scss';
const Account = lazy(() => import('./Account'));
const About = lazy(() => import('./About'));
const Wallet = lazy(() => import('./Wallet'));
const EditDetails = lazy(() => import('./EditDetails'));
const Password = lazy(() => import('./Password'));
const Consumption = lazy(() => import('./Consumption'));
import { ReactComponent as UserIcon } from '../../assets/headerIcons/userIcon.svg';
import { ReactComponent as HistoryIcon } from '../../assets/headerIcons/historyIcon.svg';
import { ReactComponent as WalletIcon } from '../../assets/headerIcons/walletIcon.svg';
import { ReactComponent as HelpIcon } from '../../assets/headerIcons/helpIcon.svg';
import logoIcon from '../../assets/Logo/conscentFavicon.svg';
import LoadingCircle from 'views/UserOverlay/components/loaders/LoadingCircle';

const { Header, Content } = Layout;

enum DASHROUTE_KEYS {
  ACCOUNT = 'account',
  CONSUMPTION = 'consumption',
  WALLET = 'wallet',
  ABOUT = 'about',
  EDIT = 'edit',
  PASSWORD = 'password',
  BILLS = 'bills',
}

export default function Dashboard() {
  const history = useHistory();
  const authStore = useSelector((store: RootState) => store.auth);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [showCross, setShowCross] = useState(false);
  const { path } = useRouteMatch();
  const location = useLocation();
  let currentKey = location.pathname.slice(path.length, location.pathname.length).replace(/\//, '');
  if (currentKey === '') currentKey = 'account';
  if (!(Object.values(DASHROUTE_KEYS) as string[]).includes(currentKey)) {
    history.push('/');
  }

  useEffect(() => {
    if (!authStore.token) history.push('/');
    if (authStore.userType !== 'USER') history.push('/');
    const searchParams = new URLSearchParams(location.search);
    let redirectTo = searchParams.get('redirectTo');
    if (redirectTo) {
      sessionStorage.setItem('redirectTo', redirectTo);
    } else {
      if (searchParams.get('hideCross')) {
        sessionStorage.removeItem('redirectTo');
      } else {
        redirectTo = sessionStorage.getItem('redirectTo');
      }
    }
    if (redirectTo) {
      setRedirectUrl(redirectTo);
      setShowCross(true);
    }

    const redirectUserToSubscriptionsId = sessionStorage.getItem('redirectUserToSubscriptionsId');

    if (redirectUserToSubscriptionsId)
      history.push(`/dashboard/consumption?subscription=${redirectUserToSubscriptionsId}`);
  }, []);

  return (
    <Layout className={style['site-layout']}>
      {/* cross button in redirect mode */}
      {showCross && (
        <div
          style={{
            width: '100%',
            position: 'fixed',
            zIndex: 2001,
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 12,
          }}
        >
          <div
            style={{
              fontSize: '18px',
              marginRight: 18,
              marginTop: 4,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (redirectUrl) {
                window.location.replace(redirectUrl);
              }
            }}
          >
            X
          </div>
        </div>
      )}
      <Header>
        <Menu
          theme="dark"
          style={showCross ? { paddingTop: 22 } : {}}
          mode="horizontal"
          selectedKeys={[currentKey]}
          onSelect={({ key }) => {
            window.scrollTo(0, 0);
            history.push(`${path}/${key}` as string);
          }}
        >
          <div>
            <img src={logoIcon} className={style['logo']} />
          </div>
          <Menu.Item key={DASHROUTE_KEYS.ACCOUNT} id="sel-profile">
            <div className={style['header-icon']}>
              <UserIcon className={style['set-svg-icon']} />
            </div>
            <p className={style['header-title']}>Profile</p>
          </Menu.Item>
          <Menu.Item key={DASHROUTE_KEYS.WALLET} id="sel-wallet">
            <div className={style['header-icon']}>
              <WalletIcon className={style['set-svg-icon']} />
            </div>
            <p className={style['header-title']}>Wallet</p>
          </Menu.Item>
          <Menu.Item key={DASHROUTE_KEYS.CONSUMPTION} id="sel-history">
            <div className={style['header-icon']}>
              <HistoryIcon className={style['set-svg-icon']} />
            </div>
            <p className={style['header-title']}>History</p>
          </Menu.Item>
          <Menu.Item key={DASHROUTE_KEYS.ABOUT} id="sel-about">
            <div className={style['header-icon']}>
              <HelpIcon className={style['set-svg-icon']} />
            </div>
            <p className={style['header-title']}>Help</p>
          </Menu.Item>
        </Menu>
      </Header>

      <Content className={style['custom-bg']}>
        <Suspense fallback={<LoadingCircle />}>
          {currentKey === DASHROUTE_KEYS.ACCOUNT && <Account redirectUrl={redirectUrl} />}
          {currentKey === DASHROUTE_KEYS.CONSUMPTION && <Consumption />}
          {currentKey === DASHROUTE_KEYS.WALLET && <Wallet />}
          {currentKey === DASHROUTE_KEYS.ABOUT && <About />}
          {currentKey === DASHROUTE_KEYS.EDIT && <EditDetails />}
          {currentKey === DASHROUTE_KEYS.PASSWORD && <Password />}
        </Suspense>
      </Content>
    </Layout>
  );
}
