import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { postMessage } from '../UserOverlay/paywallUtils';
import { ConscentLoginContext, CONSCENTLOGIN_ROUTES, ContextWrapper } from './ConscentLoginContext';
import { ConscentLogin } from './ConscentLogin';
import API from 'api';

const ConscentLoginMain = () => {
  const authStore = useSelector((store: RootState) => store.auth);
  const { route, setRoute } = useContext(ConscentLoginContext);

  useEffect(() => {
    let sessionExists = !!authStore.sessionId;

    (async () => {
      const validateSessionResponse = await API.user.validateSession(authStore.sessionId || '');
      if (!API.validators.checkSuccessCode(validateSessionResponse)) {
        sessionExists = false;
      } else {
        sessionExists = !!validateSessionResponse.data.sessionExists;
      }

      if (!authStore.token || !sessionExists) {
        return setRoute(CONSCENTLOGIN_ROUTES.LOGIN);
      } else {
        postMessage.LOGGEDIN(true, authStore.sessionId);
      }
    })();
  }, []);

  return <>{route === CONSCENTLOGIN_ROUTES.LOGIN && <ConscentLogin />}</>;
};

export default function ConscentLoginOverlay() {
  return (
    <ContextWrapper>
      <ConscentLoginMain />
    </ContextWrapper>
  );
}
