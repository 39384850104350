import React from 'react';
import style from './blueButton.module.scss';
import clsx from 'clsx';
import CSS from 'csstype';

export function BlueButton({
  children,
  customStyle,
  disabled,
  opacity,
  enableColor,
  type,
  ...props
}: {
  children?: any;
  customStyle?: CSS.Properties;
  enableColor?: string;
  disabled?: boolean;
  opacity?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onClick?: () => unknown;
  [key: string]: any;
}) {
  return (
    <button
      className={clsx(style['blue-button'], disabled && style['disabled-opacity'])}
      style={{ ...customStyle, backgroundColor: enableColor } || {}}
      disabled={disabled}
      type={type || 'submit'}
      {...props}
      onClick={() => {
        props.onClick?.();
      }}
    >
      {children}
    </button>
  );
}
