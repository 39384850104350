import React from 'react';

export const ThankYou = () => {
  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '100px', background: '#add8e652', height: '300px' }}>
      <p style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '30px' }}>
        Thank You!
      </p>
    </div>
  );
};
