import clsx from 'clsx';
import React from 'react';
import style from './progressBar.module.scss';

export default function ProgressBar({ activeTab }: { activeTab: string }) {
  return (
    <div className={style['main-container']}>
      <div className={style['sub-container']}>
        <div className={clsx(style['text'], activeTab === 'plan' && style['active'])}>Select Plan</div>
        <div className={style['divider']}></div>
        <div className={clsx(style['text'], activeTab === 'address' && style['active'])}>Add Details</div>
        <div className={style['divider']}></div>
        <div className={clsx(style['text'], activeTab === 'payment' && style['active'])}>Make Payment</div>
      </div>
    </div>
  );
}
