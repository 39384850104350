import { Checkbox, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import API from 'api';
import clsx from 'clsx';
import { isMobile, mobileModel } from 'react-device-detect';
import FloatInputBox from 'components/InputBox/FloatInputBox';
import style from './reviewSubscription.module.scss';
import axios from 'axios';
import { SubscriptionContext, SUBSCRIPTION_ROUTES } from '../SubscriptionContext';
import { getCurrencySymbol } from '../../../data/getCurrencySymbol';
import { Currencies } from '../../../data/currencies';
import { initializePayments, deinitializePayments, makePayment } from 'utils/paymentUtility';
import { currencyType } from 'data/currencies';
import verifiedIcon from 'assets/verifiedIcon.svg';
import edit_black from 'assets/client/edit_black.svg';
import bullet from 'assets/bullet.svg';
import { logError } from 'utils';
import { minAmountForCurrencies } from 'data/minimumAmountForCurrencies';
import { isValidSecondaryPhone } from 'utils/regularExpressions';
import { validateEmail } from 'utils/validators';
import { getCountryNameFromCountryCode } from 'utils/getCountryNameFromCountryCode';
import { includeGtag, pageViewForIT } from 'utils/gtagConfig';
import { fireFacebookAddToCartPixelForIT } from 'utils/fbPixelConfig';
import { addQueryParamToUrl } from 'views/UserOverlay/paywallUtils';
import { loginChallengeCheck } from './SubsUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { paymentHandlerForApp, changePlanRedirectionForMobile } from 'utils/notifyAppToRedirect';
import { validate } from 'views/UserOverlay/Validator';
import { getDefaultTrackingValues, eventType, eventLocation, clickAction } from '../../../utils/Tracker/track';

const API_URL = process.env.REACT_APP_API_URL;

interface FieldValues {
  name: string;
  email: string;
  phone: string;
}

export default function ReviewSubscription() {
  const {
    selectedSubscription,
    selectedTier,
    userDetails,
    detectedCurrency,
    detectedCountry,
    payForSubscription,
    subscriptionPageParams,
    setSelectedTier,
    updateView,
    getUtmParameters,
    viewId,
    availFreeTrial,
    setUserDetails,
    setRoute,
  } = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(false);
  const [fieldAutoFocus, setFieldAutoFocus] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [error, setError] = useState('');
  const [fieldValuesError, setFieldValuesError] = useState('');
  const [renewSubscription, setRenewSubscription] = useState(availFreeTrial);
  const [selectedOffers, setSelectedOffers] = useState([] as { iconUrl: string; benefits: string }[]);
  const [freeTrialDuration, setFreeTrialDuration] = useState(0);
  const [fieldValues, setFieldValues] = useState({
    name: '',
    email: '',
    phone: '',
  } as FieldValues);
  const authStore = useSelector((store: RootState) => store.auth);
  const [phoneError, setPhoneError] = useState('' as string);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  //@ts-ignore
  const params = new URL(document.location).searchParams;
  const landingPageId = params.get('landingPageId');
  const landingPageTitle = params.get('landingPageTitle');
  // const paywallId = params.get('paywallId');
  const siteUrl = new URLSearchParams(decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || ''));
  const paywallId = siteUrl.get('paywallId');
  const tierId = params.get('tierid');

  useEffect(() => {
    loginChallengeCheck(authStore.sessionId || '');
    updateView({
      action: 'REVIEW-SUB',
      subscriptionDetails: {
        subscriptionId: selectedSubscription?._id,
        tierId: selectedTier._id,
        title: selectedSubscription.title,
        price: selectedTier.price,
        currency: detectedCurrency,
      },
    });
  }, []);

  useEffect(() => {
    if (selectedTier?.offers) {
      const selectedOffers = selectedTier.offers?.filter(
        (item) => subscriptionPageParams.offerIds?.indexOf(item._id) > -1,
      );
      setSelectedOffers(selectedOffers);
    }
  }, [subscriptionPageParams.offerIds, selectedTier]);

  useEffect(() => {
    if (!selectedTier?.currency || !detectedCurrency) return;
    (async () => {
      if (userDetails.wallet.currency) {
        setSelectedCurrency(userDetails.wallet.currency);
      } else {
        setSelectedCurrency(selectedCurrency || detectedCurrency);
        setShowCurrencySelect(true);
      }
    })();
  }, [selectedTier?.currency, detectedCurrency]);
  useEffect(() => {
    renewSubscription && renewsBtnClick();
  }, [renewSubscription]);

  useEffect(() => {
    return () => {
      deinitializePayments();
    };
  }, []);

  useEffect(() => {
    (async () => {
      // hardcoded only for indiatoday in sandbox & live
      if (['60c47342f9f9aa60f29d52b0', '609bf79cb479fa223a707ccb'].includes(subscriptionPageParams.clientId)) {
        await includeGtag('AW-10877099323');
        pageViewForIT();
        // addToCartEventForIToday();
        fireFacebookAddToCartPixelForIT(
          '837900744174810',
          selectedTier.price >= minAmountForCurrencies[selectedCurrency as currencyType]
            ? selectedTier.price
            : minAmountForCurrencies[selectedCurrency as currencyType],
          selectedTier.currency,
        );
      }
    })();
  }, []);

  useEffect(() => {
    setFieldValues({
      name: userDetails.name || userDetails.defaultName,
      email: userDetails.email || userDetails.secondaryEmail || userDetails.defaultEmail || '',
      phone: userDetails.phoneNumber || userDetails.secondaryPhoneNumber || userDetails.defaultPhone || '',
    });
    if (!userDetails.name) {
      setFieldAutoFocus({ ...fieldAutoFocus, name: true });
    } else if (!(userDetails.email || userDetails.secondaryEmail)) {
      setFieldAutoFocus({ ...fieldAutoFocus, email: true });
    } else if (!(userDetails.phoneNumber || userDetails.secondaryPhoneNumber)) {
      setFieldAutoFocus({ ...fieldAutoFocus, phone: true });
    }
  }, [userDetails._id, userDetails.userDetailsSet]);

  useEffect(() => {
    initializePayments(selectedCurrency as currencyType, detectedCountry, renewSubscription);
    if (selectedCurrency) updateSubscriptionPrice();
  }, [selectedCurrency, renewSubscription]);

  const updateSubscriptionPrice = async () => {
    const updatedSubscriptionCurrencyResponse = await API.subscription.SubscriptionDetails({
      clientId: subscriptionPageParams?.clientId,
      currency: selectedCurrency as currencyType,
    });

    // @ts-ignore
    if (updatedSubscriptionCurrencyResponse?.data && updatedSubscriptionCurrencyResponse?.data.length > 0) {
      // @ts-ignore
      const updatedSubscription = updatedSubscriptionCurrencyResponse?.data?.find(
        (sub: any) => sub._id === selectedSubscription._id,
      );
      const updatedTier = updatedSubscription?.tiers?.find((tier: any) => tier._id === selectedTier._id);
      setSelectedTier(updatedTier);
      setFreeTrialDuration(updatedSubscription?.freeTrial?.duration);
    }
  };

  const setDetails = async () => {
    console.log('kljnfhjk');
    const updateUserResponse = await API.user.setDetails({
      name: fieldValues.name,
      ...(!userDetails.phoneNumber && fieldValues.phone && { secondaryPhoneNumber: fieldValues.phone }),
      ...(!userDetails.email && fieldValues.email && { secondaryEmail: fieldValues.email }),
    });
    if (fieldValues.email) setUserDetails({ ...userDetails, secondaryEmail: fieldValues.email });
    if (!API.validators.checkSuccessCode(updateUserResponse)) {
      setLoading(false);
      setFieldValuesError(
        updateUserResponse.data?.message[0] ||
          updateUserResponse.data?.message ||
          'Failed to update details, please try again later',
      );
      logError({ error: 'Failed to update details', values: fieldValues });
    }
  };

  const addMoneyFunction = async ({ consumeFreeTrial }: { consumeFreeTrial?: boolean }) => {
    if (!selectedSubscription.physical) {
      if (fieldValues.name?.length < 4) return message.error('Please Enter a valid name');
      if (fieldValues.name?.length > 40) return message.error('Please limit your name to maximum 40 characters');
      if ((!userDetails.email && !validateEmail(fieldValues.email)) || !fieldValues.email)
        return message.error('Please Enter a valid Email');
      if (!isValidSecondaryPhone(fieldValues.phone) || fieldValues.phone?.length < 10)
        return message.error('Please Enter a valid Phone');
      setDetails();
    }
    P2PClick();

    const contentDetails = {
      successFrontendUrl:
        window.location.href.split('/subscription')[0].toString() +
        `/subscription?purchaseSubscription=true&transactionId={TRANSACTION_ID}&viewId=${viewId}`,
      failureFrontendUrl: window.location.href.split('&reviewSubscription')[0].toString() + `&error=true`,
      subscriptionId: selectedSubscription._id,
      tierId: selectedTier._id,
      amount:
        selectedTier.price >= minAmountForCurrencies[selectedCurrency as currencyType]
          ? selectedTier.price
          : minAmountForCurrencies[selectedCurrency as currencyType],
      currency: selectedTier.currency,
      type: 'SUBSCRIPTION',
      clientId: subscriptionPageParams.clientId,
      clientContentId: subscriptionPageParams.clientContentId,
      renewSubscription: renewSubscription,
      offerIds: subscriptionPageParams.offerIds,
      ...(consumeFreeTrial && { consumeFreeTrial }),
      utmParameters: getUtmParameters(),
      viewId,
      popupId: subscriptionPageParams.popupId,
      eventLocation: eventLocation.SRP,
      anonId: analyticsStore.anonId,
      paywallId: paywallId,
      landingPageId: landingPageId,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
    };

    if (renewSubscription) {
      updateView({ action: 'AUTO-RENEW-SUB' });
      if (subscriptionPageParams.couponCode) {
        setError('Cannot renew a subscription purchased via coupon');
        return;
      }
      const authorizeSubscriptionRenewalResponse = await API.user.AuthorizeRenewal({
        price: Math.ceil(selectedTier.price * 100) / 100,
        currency: selectedTier.currency,
        tierId: selectedTier._id,
        subscriptionId: selectedSubscription._id,
        duration: selectedTier.duration,
        contentDetails: contentDetails,
      });
      if (!API.validators.checkSuccessCode(authorizeSubscriptionRenewalResponse)) {
        setLoading(false);
        setError('Failed to authorize razorpay renewal');
        return;
      }
      const { rzpSubscriptionId, primaryColor, brandLogoUrl } = authorizeSubscriptionRenewalResponse.data;
      const options = {
        key: process.env.RAZORPAY_KEY,
        subscription_id: rzpSubscriptionId,
        name: 'Transaction Value',
        // description: 'Purchase Subscription',
        image: brandLogoUrl || 'https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png',
        prefill: {
          contact: userDetails.phoneNumber || fieldValues.phone,
          email: userDetails.email || fieldValues.email,
        },
        redirect: true,
        modal: {
          confirm_close: false,
          ondismiss: function () {
            rzrPayCrossBtn();
          },
        },
        callback_url: `${API_URL}/payment/rzp-subscription-renewal?transactionId=${authorizeSubscriptionRenewalResponse.data.transactionId}`,
        theme: {
          color: primaryColor || '#1a92b4',
        },
      };
      // @ts-ignore
      const rzp = new window.Razorpay(options);
      setLoading?.(false);
      rzp.open();
    } else if (
      subscriptionPageParams.couponCode ||
      (userDetails.wallet.balance && parseFloat(userDetails.wallet.balance['$numberDecimal']) >= selectedTier.price)
    ) {
      if (subscriptionPageParams.couponCode) {
        updateView({ action: 'REDEEM-COUPON' });
      }
      await payForSubscription({
        setLoading,
        setError,
        renewSubscription: renewSubscription,
        subscriptionDetails: {
          _id: selectedSubscription._id,
          amount: selectedTier.price,
          currency: selectedTier.currency,
          tierId: selectedTier._id,
          offerIds: subscriptionPageParams.offerIds,
        },
        utmParameters: getUtmParameters(),
      });
    } else {
      updateView({ action: 'ADD-MONEY' });
      setLoading(true);
      makePayment(
        userDetails.wallet.balance
          ? selectedTier.price - parseFloat(userDetails.wallet.balance['$numberDecimal'])
          : selectedTier.price,
        selectedTier.currency as currencyType,
        contentDetails,
        {
          email: userDetails.email || userDetails.secondaryEmail,
          phoneNumber: userDetails.phoneNumber,
        },
        detectedCountry,
        setLoading,
        rzrPayCrossBtn,
      );
    }
  };

  useEffect(() => {
    const SRPviewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SRP,
      subId: selectedSubscription._id,
      tierId: selectedTier._id,
      amount: selectedTier.price,
      currency: selectedTier.currency,
      clientId: subscriptionPageParams?.clientId,
      contentId: subscriptionPageParams?.clientContentId,
      landingPageId,
      landingPageTitle,
      userId: userDetails._id,
      anonId: analyticsStore.anonId,
      paywallId: paywallId,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
    };
    API.analyst.eventPost({ ...SRPviewObject });
  }, []);

  const rzrPayCrossBtn = () => {
    const rzrPayCrossBtnObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SUBS_PAYMENT_GATEWAY,
      clickAction: clickAction.PAYMENT_GATEWAY_CROSS_BTN,
      // clickType: 'RZP_CROSS_BTN',
      subId: selectedSubscription._id,
      tierId: selectedTier._id,
      amount: selectedTier.price,
      currency: selectedTier.currency,
      clientId: subscriptionPageParams?.clientId,
      contentId: subscriptionPageParams?.clientContentId,
      landingPageId,
      landingPageTitle,
      userId: userDetails._id,
      anonId: analyticsStore.anonId,
      paywallId: analyticsStore.paywallId,
    };
    API.analyst.eventPost({ ...rzrPayCrossBtnObject });
  };
  const srpEdit = () => {
    const srpEdit = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SRP,
      clickAction: clickAction.EDIT,
      // clickType: 'SRP',
      subsId: selectedSubscription._id,
      tierId: selectedTier._id,
      landingPageId,
      landingPageTitle,
      price: selectedTier.price,
      Currency: selectedTier.currency,
      clientId: subscriptionPageParams?.clientId,
      contentId: subscriptionPageParams?.clientContentId,
      userId: userDetails._id,
      anonId: analyticsStore.anonId,
      paywallId: analyticsStore.paywallId,
    };
  };
  const P2PClick = () => {
    const P2POBJECT = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SRP,
      clickAction: clickAction.P2P,
      subId: selectedSubscription._id,
      tierId: selectedTier._id,
      landingPageId,
      landingPageTitle,
      amount: selectedTier.price,
      currency: selectedTier.currency,
      clientId: subscriptionPageParams?.clientId,
      contentId: subscriptionPageParams?.clientContentId,
      userId: userDetails._id,
      anonId: analyticsStore.anonId,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
      paywallId: paywallId,
    };
    API.analyst.eventPost({ ...P2POBJECT });
  };
  const renewsBtnClick = () => {
    const renewsBtnObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SRP,
      clickAction: clickAction.RENEW_BTN,
      clientId: subscriptionPageParams?.clientId,
      contentId: subscriptionPageParams?.clientContentId,
      userId: userDetails._id,
      subId: selectedSubscription._id,
      landingPageId,
      landingPageTitle,
      tierId: selectedTier._id,
      amount: selectedTier.price,
      currency: selectedTier.currency,
      anonId: analyticsStore.anonId,
      paywallId: analyticsStore.paywallId,
    };
    API.analyst.eventPost({ ...renewsBtnObject });
  };
  const changePlanBtnClick = () => {
    const changePlanObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SRP,
      clickAction: clickAction.CHANGE_PLAN,
      clientId: subscriptionPageParams?.clientId,
      landingPageId,
      landingPageTitle,
      contentId: subscriptionPageParams?.clientContentId,
      userId: userDetails._id,
      anonId: analyticsStore.anonId,
      tierId: selectedTier._id,
      subId: selectedSubscription._id,
      paywallId: analyticsStore.paywallId,
    };
    API.analyst.eventPost({ ...changePlanObject });
  };

  if (!selectedTier) return <> </>;

  let yearText = '';
  if (selectedTier.duration >= 12)
    yearText =
      Math.floor(selectedTier.duration / 12) === 1 ? '1 year' : `${Math.floor(selectedTier.duration / 12)} years`;

  const monthCount = selectedTier.duration % 12;
  let monthText = '';
  if (monthCount) {
    monthText = yearText ? ' & ' : '';
    monthText += `${monthCount} month${monthCount > 1 ? 's' : ''}`;
  }
  const durationText = `${yearText}${monthText}`;

  const freeTrialDurationText = freeTrialDuration + ` day${freeTrialDuration !== 1 ? 's' : ''}`;
  useEffect(() => {
    if (subscriptionPageParams.couponCode) setRenewSubscription(!renewSubscription);
  }, [subscriptionPageParams.couponCode]);

  const OnInput = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'pincode') {
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setFieldValues({ ...fieldValues, [name]: value });
      }
    } else {
      setFieldValues({ ...fieldValues, [name]: value });
    }

    if (name === 'phone' && detectedCurrency === 'INR') {
      if (
        (value.length && !validate(value, { pattern: /^\d+$/ })) ||
        (value.length === 10 && !validate(value, { pattern: /^[6-9]\d{9}$/ }))
      )
        setPhoneError('Invalid phone number entered');
      else if (phoneError) setPhoneError('');
    }
  };

  const dropDownStyles = {
    inpCustomStyle: {
      border: 'solid 1px rgba(0, 0, 0, 0.12)',
    },
    labelCustomStyle: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    parentDivCustomStyle: {
      marginTop: '32px',
    },
  };
  return (
    <div className={style['subscription-main-container']}>
      <div className={style['description']}>
        {!selectedSubscription.physical && (
          <div
            style={{
              borderRadius: '10px',
              border: 'solid 1px #e6eff8',
              padding: '18px 20px',
              marginBottom: '20px',
            }}
          >
            <div className={style['wrap-input']}>
              <p className={style['main-header']}>Add Details</p>
              <FloatInputBox
                Label={'Full Name'}
                type={'text'}
                fieldName="name"
                value={fieldValues.name}
                OnInput={OnInput}
                inpCustomStyle={dropDownStyles.inpCustomStyle}
                labelCustomStyle={dropDownStyles.labelCustomStyle}
                parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                compulsoryField={true}
                autoFocus={fieldAutoFocus.name}
                maxLength={40}
              />
              <FloatInputBox
                Label={'Email'}
                type={'text'}
                fieldName="email"
                disabled={!!userDetails.email}
                value={fieldValues.email}
                OnInput={OnInput}
                inpCustomStyle={{ border: 'solid 1px rgba(0, 0, 0, 0.12)' }}
                labelCustomStyle={{ color: 'rgba(0, 0, 0, 0.6)' }}
                parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                compulsoryField={true}
                autoFocus={fieldAutoFocus.email}
              />
              {!userDetails?.email ? (
                <p>Give your email to complete your profile and get personalised offers</p>
              ) : (
                <p> Use this email to log in</p>
              )}

              <FloatInputBox
                maxLength={15}
                Label={phoneError ? phoneError : 'Phone Number'}
                type={'text'}
                fieldName="phone"
                disabled={!!userDetails.phoneNumber}
                value={fieldValues.phone}
                OnInput={OnInput}
                inpCustomStyle={{ border: 'solid 1px rgba(0, 0, 0, 0.12)' }}
                labelCustomStyle={phoneError ? { color: 'red' } : dropDownStyles.labelCustomStyle}
                parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                autoFocus={fieldAutoFocus.phone}
                compulsoryField={true}
              />
            </div>
            {!userDetails?.phoneNumber ? (
              <p>Give your phone number to complete your profile and get personalised offers</p>
            ) : (
              <p> Use this phone number to log in</p>
            )}
            {fieldValuesError && <span style={{ color: 'red', display: 'flex' }}>{fieldValuesError}</span>}
          </div>
        )}
        <p className={style['main-header']}>Review Your Subscription</p>
      </div>

      <div className={style['review']}>
        <div className={style['header']}>{selectedSubscription.title} </div>

        <div className={style['wrap-benefit-and-img']}>
          <div className={style['benefit-container']}>
            {selectedSubscription.benefits.split(',').map((benefit, i) => (
              <div key={i} className={style['benefit-text']}>
                <img src={bullet} alt={'bullet'} />
                <div>{benefit}</div>
              </div>
            ))}

            <div className={style['offer-container']}>
              {selectedOffers?.length ? <p className={style['offer-text']}> Offer Includes</p> : <></>}

              {selectedOffers?.map((offer, i) => (
                <div key={i} className={style['offer-card']}>
                  <img className={style['offer-img']} src={offer.iconUrl} alt="offer-img" width={30} height={30} />
                  <p className={style['offer-text']}>{offer.benefits}</p>
                </div>
              ))}
              {selectedSubscription.physical && (
                <div className={style['address-container']}>
                  <div className={style['address-title']}>
                    <p>Shipping address</p>
                    <div
                      className={style['address-title-edit']}
                      onClick={() => {
                        srpEdit();
                        setRoute(SUBSCRIPTION_ROUTES.ADDRESS);
                      }}
                    >
                      Edit
                    </div>
                  </div>

                  <p className={style['user-name']}>{userDetails?.name}</p>
                  <p>{userDetails?.address?.apartment}</p>
                  <p>{userDetails?.address?.area}</p>
                  {userDetails?.address?.landmark && <p>Landmark: {userDetails?.address?.landmark}</p>}
                  <p>
                    {userDetails?.address?.city},{userDetails?.address?.state}
                  </p>
                  <p>
                    {getCountryNameFromCountryCode(userDetails.address.country)}-{userDetails?.address?.pincode}
                  </p>
                  {userDetails.phoneNumber ? (
                    <p>Phone No: {userDetails.phoneNumber}</p>
                  ) : (
                    <p>Email: {userDetails.email}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={style['image-container']}>
            <img
              src={selectedSubscription.iconUrl}
              alt="cover-photo"
              className={style['cover-photo']}
              width={200}
              style={{ maxHeight: 244, maxWidth: 235, objectFit: 'contain' }}
            />
          </div>
        </div>

        <div
          className={
            availFreeTrial && subscriptionPageParams.freeTrial
              ? style['wrap-change-plane-free']
              : style['wrap-change-plane']
          }
        >
          <div className={style['wrap-change-plane-left']}>
            {availFreeTrial && subscriptionPageParams.freeTrial ? (
              <span className={style['sub-text']}>
                Auto-renews for
                <span className={style['avail-free-trial-text']}>
                  {getCurrencySymbol(selectedCurrency)}
                  {Math.ceil(selectedTier.price * 100) / 100} every
                  {` ` + durationText + ` `}
                </span>
                after <span className={style['free-trial-text']}>{freeTrialDurationText} of FREE TRIAL</span>
              </span>
            ) : (
              <p className={clsx(style['checkbox'], style['checkbox-mobile'])}>
                <Checkbox
                  defaultChecked={renewSubscription}
                  onClick={() => {
                    setRenewSubscription(!renewSubscription);
                    // renewSubscription && console.log(renewsBtnClick());
                  }}
                  className={style['review-text']}
                >
                  Renews automatically after {durationText}
                </Checkbox>
              </p>
            )}
            <div className={style['wrap-select-edit']}>
              {showCurrencySelect && (
                <>
                  Currency&nbsp;:
                  <div className={style['wrap-currencies-select']}>
                    <select
                      onChange={(e) => {
                        setSelectedCurrency(e.target.value);
                      }}
                      className={style['currencies-select']}
                      value={selectedCurrency}
                    >
                      {Currencies.map((e, key) => {
                        return (
                          <option key={key} value={e.code}>
                            {e.code}
                          </option>
                        );
                      })}
                    </select>
                    <div className={style['select-layer']}></div>
                    <img src={edit_black} alt="edit-icon" className={style['edit-icon']} />
                  </div>
                </>
              )}
            </div>
            {availFreeTrial && subscriptionPageParams.freeTrial && (
              <div className={style['main-text']} style={{ display: 'flex' }}>
                FREE
                <span className={style['star-text']}>*</span>
                <span className={style['free-text']}>Trial Version</span>
              </div>
            )}

            <div className={style['total-amount']}>
              {subscriptionPageParams.couponCode ? (
                <div className={style['validatedCouponCode']}>
                  <span className={style['message']}>
                    <img src={verifiedIcon} alt="rightArrow" className={style['verifiedIcon']} />
                    Coupon applied{' '}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {error && (
                <div>
                  <span
                    style={{
                      color: 'red',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                  >
                    {error}
                  </span>
                </div>
              )}

              {subscriptionPageParams.couponCode && (
                <>
                  <span
                    style={{
                      fontSize: 22,
                      color: 'gray',
                      textDecoration: 'line-through',
                    }}
                  >
                    {getCurrencySymbol(selectedCurrency)}
                    {Math.ceil(selectedTier.price * 100) / 100}
                  </span>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </>
              )}
              {!(availFreeTrial && subscriptionPageParams.freeTrial) && (
                <>
                  <div className={style['price-text-container']}>
                    <span className={style['main-text']}>
                      <span>
                        {getCurrencySymbol(selectedCurrency)}
                        {subscriptionPageParams.couponCode ? 0 : Math.ceil(selectedTier.price * 100) / 100}
                      </span>
                    </span>
                    <span className={style['sub-text']} style={{ marginLeft: '4px' }}>
                      for {durationText}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={style['wrap-change-plane-right']}>
            <div
              className={style['changed-text']}
              onClick={() => {
                console.log('kjbdsjkbsd', changePlanBtnClick());
                changePlanRedirectionForMobile();

                const redirectUrl = addQueryParamToUrl(
                  { key: 'csc-tierId', value: selectedTier._id },
                  subscriptionPageParams.siteUrl,
                );

                window.location.href = redirectUrl;
              }}
            >
              Change Plan
            </div>
            {detectedCurrency === 'INR' ? (
              <button
                className={clsx(
                  style['pay'],

                  loading && style['disabled'],
                )}
                disabled={loading}
                onClick={() => {
                  availFreeTrial
                    ? addMoneyFunction({
                        consumeFreeTrial: subscriptionPageParams.freeTrial,
                      })
                    : addMoneyFunction({});
                }}
              >
                {subscriptionPageParams.couponCode ? (
                  'GET IT FOR FREE'
                ) : (
                  <>{availFreeTrial && subscriptionPageParams.freeTrial ? 'Get free trial ' : 'Confirm & Pay'}</>
                )}
              </button>
            ) : (
              <button
                className={clsx(style['pay'], loading && style['disabled'])}
                disabled={loading}
                onClick={() => {
                  availFreeTrial
                    ? addMoneyFunction({
                        consumeFreeTrial: subscriptionPageParams.freeTrial,
                      })
                    : addMoneyFunction({});
                }}
              >
                {subscriptionPageParams.couponCode ? (
                  'GET IT FOR FREE'
                ) : (
                  <>{availFreeTrial && subscriptionPageParams.freeTrial ? 'Get free trial ' : 'Confirm & Pay'}</>
                )}
              </button>
            )}

            {mobileModel === 'iPhone' && isMobile && (
              <button
                className={clsx(style['pay'], loading && style['disabled'])}
                style={{ marginTop: '24px' }}
                disabled={loading}
                onClick={() => {
                  paymentHandlerForApp({
                    subscriptionId: selectedSubscription._id,
                    tierId: selectedTier._id,
                    amount:
                      selectedTier.price >= minAmountForCurrencies[selectedCurrency as currencyType]
                        ? selectedTier.price
                        : minAmountForCurrencies[selectedCurrency as currencyType],
                    currency: selectedTier.currency,
                    clientId: subscriptionPageParams?.clientId,
                    contentId: subscriptionPageParams?.clientContentId,
                    userId: userDetails._id,
                  });
                }}
              >
                Pay With Apple
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
