import API from 'api';
import { RootState } from 'app/rootReducer';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { postMessage, shouldRedirect } from 'views/UserOverlay/paywallUtils';
function UserStatus() {
  const authStore = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    (async () => {
      let sessionExists = !!authStore.sessionId;

      const validateSessionResponse = await API.user.validateSession(authStore.sessionId || '');
      if (!API.validators.checkSuccessCode(validateSessionResponse)) {
        sessionExists = false;
      } else {
        sessionExists = !!validateSessionResponse.data.sessionExists;
      }

      if (sessionExists) {
        postMessage.LOGGEDIN(true, authStore.sessionId, authStore.userId);
      } else {
        if (!shouldRedirect()) postMessage.CLEAR_SESSION_DETAILS();
      }
    })();
  }, []);

  return <></>;
}

export default UserStatus;
