import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CSS from 'csstype';
import clsx from 'clsx';
import style from './wrapper.module.scss';
import { Error } from './Error';
import { OverlayContext, ROUTES } from '../OverlayContext';
import Footer from './Footer';
import * as paywallUtils from '../paywallUtils';
import { CloseOutlined } from '@ant-design/icons';
import { getDefaultTrackingValues, eventType, eventLocation, clickAction } from '../../../utils/Tracker/track';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import API from 'api';
import { postMessage } from '../../UserOverlay/paywallUtils';

const Wrapper = React.forwardRef(
  (
    props: {
      children?: any;
      hideCross?: boolean;
      hideFooter?: boolean;
      embedded?: boolean;
      noPadding?: boolean;
      transition?: boolean;
      transparentBackground?: boolean;
      crossClickFunction?: () => void;
      hasBorder?: boolean;
      customWidth?: string;
      crossBtnPosition?: CSS.Properties;
      crossBtnStyle?: CSS.Properties;
      fullPageBlock?: boolean;
      displayType?: string;
      bgColor?: string;
    },
    ref,
  ) => {
    const {
      globalError,
      setGlobalError,
      setRoute,
      standaloneMode,
      redirectMode,
      fullscreenMode,
      userDetails,
      route,
    } = useContext(OverlayContext);
    const [error, setError] = useState('');
    const [fadeOutTransition, setFadeOutTransition] = useState(false);
    const history = useHistory();
    useEffect(() => {
      (async () => {
        if (globalError?.length) {
          setGlobalError('');
          setError(globalError);
        }
      })();
    }, []);

    useEffect(() => {
      if (props.displayType === 'FULLPAGE' || props.displayType === 'POPUP') {
        postMessage.FULLPAGE();
        postMessage.POPUP();
      }
    }, [props.displayType]);

    const searchParams = new URLSearchParams(location.search);

    let rootTransitionClass = style.root;
    let backdropTransitionClass = style.backdrop;
    if (props.transition) {
      rootTransitionClass = style.rootTransition;
      backdropTransitionClass = style.backdropTransition;
    }
    let pageBlockStyle = '';
    if (props.displayType === 'FULLPAGE') {
      rootTransitionClass = style.rootFullPageBlock;
      pageBlockStyle = style.fullPageWapper;
    }
    if (props.displayType === 'POPUP') {
      rootTransitionClass = style.rootPopUpPageBlock;
      pageBlockStyle = style.popUpWapper;
    }
    if (props.displayType === 'INARTICLE') {
      rootTransitionClass = '';
      pageBlockStyle = '';
      backdropTransitionClass = style.backdropForInarticle;
    }

    if (fadeOutTransition) {
      rootTransitionClass = style.rootTransitionFade;
      backdropTransitionClass = style.backdropTransitionFade;
    }
    const analyticsStore = useSelector((store: RootState) => store.analytics);
    const { clientDetails } = useContext(OverlayContext);

    const loginPageClose = () => {
      const loginPageCloseObject = {
        ...getDefaultTrackingValues(),
        eventType: eventType.CLICK,
        eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
        clickAction: clickAction.LOGIN_FLOW_CROSSBTN,
        clientId: clientDetails.clientId,
        contentId: clientDetails.clientContentId,
        anonId: analyticsStore.anonId,
        url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
      };
      API.analyst.eventPost({ ...loginPageCloseObject });
    };
    //

    const hideCrossForFullScreenOnWelcomePage =
      route === ROUTES.WELCOME && !userDetails.crossButtonClickUrl && fullscreenMode;
    return (
      <div className={style['main-container']}>
        {!props.embedded && <div className={backdropTransitionClass} />}
        <div className={pageBlockStyle} style={{ backgroundColor: props.bgColor }}>
          <div
            className={clsx(rootTransitionClass, {
              [style.embedded]: props.embedded,
              [style['no-padding']]: props.noPadding,
            })}
            style={{
              ...(props.customWidth && { width: props.customWidth }),
              ...(props.transparentBackground && { background: 'transparent' }),
              ...(props.hasBorder && { border: '1px solid #000' }),
              ...(props.displayType === 'FULLPAGE' && { backgroundColor: props.bgColor }),
            }}
            // @ts-ignore
            ref={ref}
          >
            {!props.hideCross && !hideCrossForFullScreenOnWelcomePage && (
              <div className={style['cross-icon-container']} style={props.crossBtnPosition}>
                <span
                  onClick={() => {
                    if (redirectMode)
                      return window.location.replace(decodeURIComponent(searchParams.get('pageUrl') as string));

                    if (standaloneMode) return setRoute(ROUTES.WELCOME);
                    if (props.crossClickFunction) return props.crossClickFunction();

                    setFadeOutTransition(true);

                    searchParams.delete('status');
                    history.replace({
                      search: searchParams.toString(),
                    });
                    setTimeout(() => paywallUtils.postMessage.WELCOME_PAGE_REACHED(), 300);
                    setTimeout(() => setRoute(ROUTES.WELCOME), 300);
                    loginPageClose();
                  }}
                >
                  <CloseOutlined id="sel-wrapper-cancel" style={props.crossBtnStyle} className={style['cross-icon']} />
                </span>
              </div>
            )}

            <div>{props.children}</div>
            {error.length ? (
              <div>
                <Error>{error}</Error>
              </div>
            ) : null}
            {!props.hideFooter && <Footer monochromeLogo />}
          </div>
        </div>
      </div>
    );
  },
);

Wrapper.displayName = 'Wrapper';

export default Wrapper;
