import React, { useState } from 'react';
import { Form, Typography, Button, Input } from 'antd';
import style from './login.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from 'views/Auth/authSlice';
import API from 'api';
import { consts } from 'utils';

export default function EmailLogin(props: { loginType: 'CLIENT' | 'ADMIN' }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isClient = props.loginType === 'CLIENT';
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const nextUrl = searchParams.get('nextUrl');

  const onFinish = async ({ email, password }: { email: string; password: string }) => {
    setError('');
    setLoading(true);
    const loginResponse = isClient
      ? await API.auth.ClientLogin({ email, password })
      : await API.auth.AdminLogin({ email, password });
    setLoading(false);

    if (!API.validators.checkSuccessCode(loginResponse)) return setError(loginResponse.data.message);
    const loginResponseData = loginResponse.data as {
      token: string;
      clientId: string;
      expiresAt: string;
      version: number;
    };
    if (isClient) {
      dispatch(setAuthDetails({ ...loginResponseData, email, userType: 'CLIENT' }));
      return history.push(consts.CLIENT_HOME_PATH + '/' + nextUrl);
    } else {
      dispatch(setAuthDetails({ ...loginResponseData, email, userType: 'ADMIN' }));
      return history.push(consts.ADMIN_HOME_PATH);
    }
  };

  return (
    <div className={style['form-item']}>
      <p className={style['title']}>
        {isClient && 'Client'}
        {!isClient && 'Admin'} Login
      </p>
      <div style={{ marginBottom: '12px' }} />
      <Form className="login-form" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="email" type="email" style={{ padding: '0px 12px' }} className={style['form-item-shadow']} />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'Password is required' }]} label="Password" name="password">
          <Input.Password name="password" type="password" className={style['form-item-shadow']} />
        </Form.Item>
        <div className={style['with-password']}></div>

        <Form.Item className={style['override-form-item']}>
          <Button loading={isLoading} block htmlType="submit" type="primary" size="large">
            LOG IN
          </Button>
        </Form.Item>
        <Typography.Text type="danger">{error}</Typography.Text>
      </Form>
    </div>
  );
}
