import React, { useContext } from 'react';
import SubscriptionLogin from './views/SubscriptionLogin';
import Address from './views/Address';
import ThankYou from './views/ThankYou';
import ReviewSubscription from './views/ReviewSubscription';
import EmbeddedSubscription from './views/EmbeddedSubscription';
import { AlreadyPurchased } from './views/AlreadyPurchased';
import { SubscriptionContext, SUBSCRIPTION_ROUTES } from './SubscriptionContext';

const SubscriptionRouter = () => {
  const { route } = useContext(SubscriptionContext);

  const RouteMap = {
    [SUBSCRIPTION_ROUTES.EMBEDDED_SUBSCRIPTION]: <EmbeddedSubscription />,
    [SUBSCRIPTION_ROUTES.LOGIN]: <SubscriptionLogin />,
    [SUBSCRIPTION_ROUTES.ADDRESS]: <Address />,
    [SUBSCRIPTION_ROUTES.ALREADY_PURCHASED]: <AlreadyPurchased />,
    [SUBSCRIPTION_ROUTES.REVIEW]: <ReviewSubscription />,
    [SUBSCRIPTION_ROUTES.THANKYOU]: <ThankYou />,
    [SUBSCRIPTION_ROUTES.DEFAULT_BLANK]: <></>,
  } as Record<SUBSCRIPTION_ROUTES, JSX.Element>;

  return <>{RouteMap[route]}</>;
};

export default SubscriptionRouter;
