import React from 'react';
import SubscriptionRouter from './SubscriptionRouter';
import { ContextWrapper } from './SubscriptionContext';

const SubscriptionMain = () => {
  return (
    <>
      <ContextWrapper>
        <SubscriptionRouter />
      </ContextWrapper>
    </>
  );
};

export default SubscriptionMain;
