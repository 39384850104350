import { validateEmail, ValidateMobileNumber } from './validators';
import { GetLoginCustomizationdata } from 'views/ClientDashboard/login/loginUtils';
import { Value } from 'sass';
export enum BUTTON_TYPES {
  INDIVIDUAL = 'individual',
  RADIO = 'radio',
  DIV = 'div',
  REGWALL_ONLY_TEXTBOX = 'regWall_only_textBox',
  REGWALL_ONLY_SSO = 'regWall_only_sso',
  REGWALL_TEXTBOX_SSO = 'regWall_textbox_sso',
}

export enum BUTTON_ATTRIBUTES {
  CONTENT = 'content',
  PASS = 'pass',
  SUBSCRIPTION = 'subscription',
  EMAILLOGIN = 'emailLogin',
  MOBILELOGIN = 'mobileLogin',
  NAMEINPUT = 'nameInput',
  EMAILMOBILELOGIN = 'emailMobileLogin',
  GMAILLOGIN = 'gmailLogin',
}

export const getButtonType = (templateName: string, cta: number) => {
  if (templateName === '8A' && cta === 1) return BUTTON_TYPES.INDIVIDUAL;
  console.log(templateName, cta, 'templateName,cta');

  if (['7A', '1FB', '15A', '13A'].includes(templateName)) return BUTTON_TYPES.INDIVIDUAL;
  if (['8A', '2FB', '5FB'].includes(templateName)) return BUTTON_TYPES.RADIO;
  if (['3A', '5A', '7FB', '4B', '5B'].includes(templateName)) return BUTTON_TYPES.DIV;
  if (['RG2', 'RG5'].includes(templateName)) return BUTTON_TYPES.REGWALL_ONLY_TEXTBOX;
  if (['RG1', 'RG4'].includes(templateName)) return BUTTON_TYPES.REGWALL_ONLY_SSO;
  if (['RG3', 'RG6'].includes(templateName)) return BUTTON_TYPES.REGWALL_TEXTBOX_SSO;
  else {
    console.error('template not found');
    return BUTTON_TYPES.DIV;
  }
};

export const checkForValidation = (inputBoxAttribute: string, value: string) => {
  if (inputBoxAttribute === 'emailLogin') {
    if (validateEmail(value)) return true;
    else return false;
  }
  if (inputBoxAttribute === 'mobileLogin') {
    if (ValidateMobileNumber(value)) return true;
    else return false;
  }
  if (inputBoxAttribute === 'emailMobileLogin') {
    if (validateEmail(value) || ValidateMobileNumber(value)) return true;
    else return false;
  }
  if (inputBoxAttribute === 'nameInput') {
    const regexp = /^[a-zA-Z,\s]{3,}$/;
    if (regexp.test(value)) {
      return true;
    } else {
      return false;
    }
  }
};
