import React, { createContext, useState, useEffect } from 'react';
import API from 'api';
import { v4 as uuid } from 'uuid';
import { logError } from 'utils';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { currencyType } from 'data/currencies';
import { AxiosResponse } from 'axios';
import { userActions } from '../../consts';
import { postMessage } from '../UserOverlay/paywallUtils';
import { eventLocation, eventType, getDefaultTrackingValues } from 'utils/Tracker/track';
import { setAnalyticsData } from 'app/analyticsSlice';

export const defaultUserDetails = {
  wallet: {
    balance: {
      $numberDecimal: '0',
    },
    currency: '' as currencyType,
  },
  phoneNumber: '',
  email: '',
  secondaryEmail: '',
  secondaryPhoneNumber: '',
  _id: '',
  name: '',
  address: {
    apartment: '',
    area: '',
    city: '',
    state: '',
    country: '',
    landmark: '',
    pincode: '',
  },
  defaultEmail: '',
  defaultPhone: '',
  defaultName: '',
  userDetailsSet: false,
};

export enum userConsumptionAction {
  PREMIUM_CONTENT = 'reading',
  VIDEO = 'watching',
  STORY = 'reading',
  SONG = 'listening',
  PODCAST = 'listening',
}

export enum SUBSCRIPTION_ROUTES {
  EMBEDDED_SUBSCRIPTION = 'embeddedSubscription',
  LOGIN = 'login',
  ADDRESS = 'address',
  REVIEW = 'reviewSubscription',
  PURCHASE = 'purchaseSubscription',
  THANKYOU = 'thankYou',
  ALREADY_PURCHASED = 'alreadyPurchased',
  DEFAULT_BLANK = '',
}

export interface Tier {
  price: number;
  duration: number;
  currency: string;
  _id: string;
  offers: { _id: string; title: string; benefits: string; iconUrl: string }[];
  clientTierId?: string;
}

export interface Subscription {
  _id: string;
  title: string;
  recommended: boolean;
  couponsEnabled: boolean;
  digital: boolean;
  physical: boolean;
  iconUrl: string;
  benefits: string;
  offers: { enabled: boolean; _id: string; title: string; benefits: string; iconUrl: string }[];
  tiers: Tier[];
  freeTrial: { enabled: boolean; duration: number };
}

export interface SubscriptionPageDetails {
  clientId: string;
  clientContentId: string;
  offerIds: string[];
  couponCode: string;
  siteUrl: string;
}

const defaultSubscriptionDetails = [] as Subscription[];
const defaultSubscriptionPageParams = {
  clientId: '',
  clientContentId: undefined as string | undefined,
  offerIds: [] as string[],
  couponCode: '',
  siteUrl: '',
  freeTrial: false,
  popupId: '',
};
const defaultPurchasedSubscriptionDetails = {
  purchasedDate: '',
  validity: '',
  offerDetails: [{ title: '', benefits: '', iconUrl: '' }],
  priceDetails: {
    amount: 0,
    currency: '',
  },
  subscription: {
    tierId: '',
    digital: false,
    physical: false,
    title: '',
    renew: false,
    duration: 0,
    durationText: '',
  },
  subscriptionDetails: {} as Record<string, any>,
};

export const SubscriptionContext = createContext({
  userDetails: defaultUserDetails,
  ampRid: '',
  subscriptionDetails: defaultSubscriptionDetails,
  subscriptionPageParams: defaultSubscriptionPageParams,
  internalUser: false,
  purchasedSubscriptionDetails: defaultPurchasedSubscriptionDetails,
  setSubscriptionPageParams: (d: any) => undefined as any,
  setSubscriptionDetails: (d: any) => undefined as any,
  setUserDetails: (o: any) => undefined as any,
  loading: true as boolean,
  clientId: '',
  clientContentId: '',
  offerIds: [] as string[],
  clientName: '',
  selectedTierId: '',
  embeddedSubscriptionsTitle: '',
  selectedSubscription: (null as unknown) as Subscription,
  setSelectedSubscription: (d: any) => undefined as any,
  selectedTier: (null as unknown) as Tier,
  setSelectedTier: (d: any) => undefined as any,
  initializeSubscriptions: () => undefined as any,
  detectedCountry: 'IN',
  detectedCurrency: 'INR',
  defaultContentAction: 'reading',
  route: SUBSCRIPTION_ROUTES.LOGIN as SUBSCRIPTION_ROUTES,
  setRoute: (o: SUBSCRIPTION_ROUTES) => undefined as any,
  viewId: '',
  availFreeTrial: false,
  setViewId: (o: string) => undefined as any,
  updateView: (o: { action: userActions; userId?: string; subscriptionDetails?: Record<string, any> }) =>
    undefined as any,
  transactionDetails: {} as Record<string, any>,
  subscriptionSettings: {} as {
    successIconUrl?: string;
    successMessage?: string;
    successTitle?: string;
    redirectUrl: string;
    brandLogoUrl: string;
    thankYouPageElements: any[];
    physicalSubscriptionCountries: string[];
  },
  embeddedSubscriptions: [] as Subscription[],
  allowRenewal: false,
  getUtmParameters: () => undefined as any,
  payForSubscription: async ({
    setError,
    setLoading,
    couponCode,
    subscriptionDetails,
    renewSubscription,
    consumeFreeTrial,
    utmParameters,
    rzpSubscriptionId,
  }: {
    setError?: (errorMsg: string) => any;
    setLoading?: (state: boolean) => any;
    couponCode?: string;
    renewSubscription?: boolean;
    subscriptionDetails: {
      clientId?: string;
      clientContentId?: string;
      _id: string;
      amount: number;
      currency: string;
      tierId: string;
      offerIds: string[];
      popupId?: string;
    };
    transactionInfo?: string;
    consumeFreeTrial?: boolean;
    utmParameters?: Record<string, any>;
    rzpSubscriptionId?: string;
  }) => undefined as any,
});

interface Props {
  children: JSX.Element;
}

export const ContextWrapper = ({ children }: Props) => {
  const [userDetails, setUserDetails] = useState(defaultUserDetails);
  const authDetails = useSelector((store: RootState) => store.auth);
  const [subscriptionDetails, setSubscriptionDetails] = useState(defaultSubscriptionDetails);
  const [subscriptionPageParams, setSubscriptionPageParams] = useState(defaultSubscriptionPageParams);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>((null as unknown) as Subscription);
  const [selectedTier, setSelectedTier] = useState<Tier>((null as unknown) as Tier);
  const [purchasedSubscriptionDetails, setPurchasedSubscriptionDetails] = useState(defaultPurchasedSubscriptionDetails);
  const [internalUser, setInternalUser] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const authStore = useSelector((store: RootState) => store.auth);
  const [viewId, setViewId] = useState(searchParams.get('viewId') || '');
  const [detectedCountry, setDetectedCountry] = useState('IN');
  const [detectedCurrency, setDetectedCurrency] = useState('INR');
  const [defaultContentAction, setDefaultContentAction] = useState('reading');
  const [loading, setLoading] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState<Record<string, any>>({});
  const [clientName, setClientName] = useState('');
  const [embeddedSubscriptionsTitle, setEmbeddedSubscriptionsTitle] = useState<string>('');
  const [allowRenewal, setAllowRenewal] = useState(false);
  const [subscriptionSettings, setSubscriptionSettings] = useState(
    {} as {
      successIconUrl?: string;
      successMessage?: string;
      successTitle?: string;
      redirectUrl: string;
      brandLogoUrl: string;
      thankYouPageElements: any[];
      physicalSubscriptionCountries: string[];
    },
  );

  const [embeddedSubscriptions, setEmbeddedSubscriptions] = useState([] as Subscription[]);
  const [route, setRoute] = useState<SUBSCRIPTION_ROUTES>(SUBSCRIPTION_ROUTES.DEFAULT_BLANK);
  const sessionId = authDetails.sessionId || searchParams.get('sessionId') || '';
  const [clientId, setClientId] = useState(searchParams.get('clientId') || '');
  const dispatch = useDispatch();
  const [clientContentId, setClientContentId] = useState(searchParams.get('clientContentId') || '');
  const [offerIds, setOfferIds] = useState(searchParams.get('offers')?.split(',') || []);
  const couponCode = searchParams.get('couponCode');
  const selectedTierId = searchParams.get('tierId');
  const siteUrl = searchParams.get('siteUrl') || '';
  const availFreeTrial = searchParams.get('availFreeTrial') ? true : false;
  const ampRid = searchParams.get('rid') || '';
  const analyticsStore = useSelector((store: RootState) => store.analytics);

  const getUtmParameters = () => {
    try {
      const siteUrlString = new URLSearchParams(location.search).get('siteUrl');
      const siteUrl = siteUrlString ? new URL(decodeURIComponent(siteUrlString)) : undefined;
      const searchParams = siteUrl?.searchParams;
      const utmParams: Record<string, any> = {};
      const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
      utmKeys.map((utmKey) => {
        if (searchParams?.get(utmKey)) {
          utmParams[utmKey] = searchParams.get(utmKey);
        }
      });
      return utmParams;
    } catch (e) {
      return {};
    }
  };
  const anonId = new URLSearchParams(location.search).get('anonId') as string;
  const params = new URLSearchParams(window.location.search);

  const landingPageId = params.get('landingPageId') || '';
  const tierId = params.get('tierId') || '';
  dispatch(setAnalyticsData({ anonId }));
  useEffect(() => {
    if (authStore.token && authStore.userType === 'USER') {
      dispatch(setAnalyticsData({ alreadyRegistered: true }));

      const subLoginViewObject = {
        ...getDefaultTrackingValues(),
        eventType: eventType.CLICK,
        eventLocation: eventLocation.SUBS_LOGIN,
        firstTimeLogin: false,
        clientId: clientId,
        anonId: anonId,
        landingPageId,
        tierId,
        paywallId: analyticsStore.paywallId,
      };
      API.analyst.eventPost({ ...subLoginViewObject });
      const subOTPregisteration = {
        ...getDefaultTrackingValues(),
        anonId: anonId,
        userId: authStore.userId,
        userAgent: navigator.userAgent,
        eventType: 'VALIDATION',
        eventLocation: eventLocation.SUBS_OTP,
        validationType: 'OTP',
        firstTimeLogin: false,
        landingPageId,
        tierId,
        otpVerificationStatus: 'SUCCESS',
        clientId: clientId,
      };
      API.analyst.eventPost({ ...subOTPregisteration });
    }
  }, []);

  const initializeSubscriptions = async () => {
    const transactionId = searchParams.get('transactionId');

    let transactionClientId;
    if (transactionId) {
      const transactionResponse = await API.user.payment.getTransaction({ transactionId });
      const transactionResponseData = transactionResponse.data;
      setTransactionDetails(transactionResponseData.transaction);
      transactionClientId = transactionResponseData.transaction.contentDetails.clientId;
      const transactionClientContentId = transactionResponseData.transaction.contentDetails.clientContentId;
      setClientId(transactionClientId);
      if (transactionClientContentId) setClientContentId(transactionClientContentId);
      const transactionOfferIds = transactionResponseData.transaction.contentDetails.offerIds;
      if (transactionOfferIds) setOfferIds(transactionOfferIds);
    }

    const [subscriptionSettingsResponse, embeddedSubscriptions] = await Promise.all([
      API.client.GetSubscriptionSettings({
        clientId: clientId || transactionClientId,
      }),
      API.client.GetEmbeddedSubs({
        clientId: (searchParams.get('clientId') as string) as string,
      }),
    ]);

    if (!API.validators.checkSuccessCode(embeddedSubscriptions)) console.error('Failed to fetch embedded Subs');
    else {
      setEmbeddedSubscriptions(embeddedSubscriptions.data.subscriptions);
      setEmbeddedSubscriptionsTitle(embeddedSubscriptions.data.title);
    }

    if (!API.validators.checkSuccessCode(subscriptionSettingsResponse)) console.error('Failed to load redirect URL');
    else {
      setSubscriptionSettings({
        ...subscriptionSettingsResponse.data?.subscriptionDetails,
        brandLogoUrl: subscriptionSettingsResponse.data?.paywallCustomization?.brandLogoUrl,
      });
      setDefaultContentAction(
        //@ts-ignore
        userConsumptionAction[
          subscriptionSettingsResponse.data.clientDefaultContentType === 'PREMIUM CONTENT'
            ? 'PREMIUM_CONTENT'
            : subscriptionSettingsResponse.data.clientDefaultContentType || 'STORY'
        ],
      );
      setClientName(subscriptionSettingsResponse.data?.clientName);
    }

    if (searchParams.get('embedded') === 'true') {
      return setRoute(SUBSCRIPTION_ROUTES.EMBEDDED_SUBSCRIPTION);
    }

    if (!authStore.token || authStore.userType !== 'USER') {
      return setRoute(SUBSCRIPTION_ROUTES.LOGIN);
    }

    const userDetailsResponse = await API.user.Details();
    if (!API.validators.checkSuccessCode(userDetailsResponse)) {
      await logError({ error: 'Failed to get user details for user id: ' + authStore.userId });
      throw { message: 'Unable to fetch user details' };
    }

    const userDetailsData = userDetailsResponse.data as typeof defaultUserDetails;
    setUserDetails(userDetailsData);

    // associate rid with user if it exists
    if (ampRid) {
      await API.user.AssociateAmpReaderId({ rid: ampRid });
    }

    if (searchParams.get('purchaseSubscription') === 'true') {
      return setRoute(SUBSCRIPTION_ROUTES.THANKYOU);
    }

    if (searchParams.get('reviewSubscription') === 'true') {
      return setRoute(SUBSCRIPTION_ROUTES.REVIEW);
    }

    const SubscriptionAccessCheckResponse = await API.subscription.SubscriptionAccessCheck({
      clientId: clientId as string,
      sessionId,
    });
    // if (!API.validators.checkSuccessCode(SubscriptionAccessCheckResponse)) {
    //   await logError({ error: 'Failed to get subscription access check details' });
    //   throw new Error('failed to check for subscription access');
    // }

    const currencyAndCountryResponse = await API.auth.DetectCountry();
    if (!API.validators.checkSuccessCode(currencyAndCountryResponse)) {
      logError({ error: 'Unable to detect Country' });
    } else {
      setDetectedCountry(currencyAndCountryResponse.data.countryCode);
      setDetectedCurrency(currencyAndCountryResponse.data.currency);
    }
    const subscriptionDetails = await API.subscription.SubscriptionDetails({
      clientId: clientId as string,
      currency: userDetailsResponse.data.wallet?.currency || currencyAndCountryResponse.data.currency,
    });

    if (!API.validators.checkSuccessCode(subscriptionDetails)) {
      await logError({ error: 'Failed to get subscription details for client: ' + clientId });
      throw new Error('failed to get subscription details');
    }
    const subscriptions = subscriptionDetails.data as Subscription[];
    setSubscriptionDetails(subscriptions);

    let selectedSubscription: Subscription, selectedTier: Tier;
    for (const subscription of subscriptions) {
      const tiers = subscription.tiers;
      for (const tier of tiers)
        if (tier._id === selectedTierId) {
          selectedTier = tier;
          selectedSubscription = subscription;
        }
    }
    // @ts-ignore
    if (!selectedSubscription || !selectedTier) throw new Error('Invalid selected Tier');

    let popupId = '';
    const siteUrlString = new URLSearchParams(location.search).get('siteUrl');
    const siteUrlValue = siteUrlString ? new URL(decodeURIComponent(siteUrlString)) : undefined;
    const siteUrlSearchParams = siteUrlValue?.searchParams;
    if (siteUrlSearchParams?.get('popupId')) popupId = siteUrlSearchParams?.get('popupId') as string;
    const currentPageSearchParams = new URL(window.location.href).searchParams;
    if (currentPageSearchParams.get('popupId')) popupId = currentPageSearchParams.get('popupId') as string;

    setSubscriptionPageParams({
      clientId: clientId as string,
      clientContentId: clientContentId as string,
      offerIds,
      couponCode: couponCode as string,
      siteUrl,
      freeTrial: selectedSubscription?.freeTrial?.enabled && !SubscriptionAccessCheckResponse?.data?.freeTrialConsumed,
      popupId,
    });
    setSelectedSubscription(selectedSubscription);
    setSelectedTier(selectedTier);

    if (SubscriptionAccessCheckResponse?.data?.allowRenewal) setAllowRenewal(true);
    if (SubscriptionAccessCheckResponse?.data?.subscriptionAlreadyPurchased) {
      const alreadyPurchased = SubscriptionAccessCheckResponse?.data?.purchasedSubscriptions?.find(
        (purchasedSubscriptionDetails: any) =>
          purchasedSubscriptionDetails?.subscriptionDetails?._id == selectedSubscription._id,
      );
      if (alreadyPurchased) {
        updateView({ action: 'ACCESS' });
        setPurchasedSubscriptionDetails(alreadyPurchased);
        return setRoute(SUBSCRIPTION_ROUTES.ALREADY_PURCHASED);
      }
    }

    if (SubscriptionAccessCheckResponse?.data?.internalUser) {
      updateView({ action: 'ACCESS' });
      setInternalUser(true);
      return setRoute(SUBSCRIPTION_ROUTES.ALREADY_PURCHASED);
    }

    // Routing begins
    if (window.location.href.includes('purchase-subscription')) {
      return;
    }

    if (selectedSubscription.physical) {
      // refresh case after
      if (sessionStorage.getItem('address-confirmed') === authStore.sessionId)
        return setRoute(SUBSCRIPTION_ROUTES.REVIEW);
      return setRoute(SUBSCRIPTION_ROUTES.ADDRESS);
    } else {
      return setRoute(SUBSCRIPTION_ROUTES.REVIEW);
    }
  };

  useEffect(() => {
    (async () => {
      await initializeSubscriptions();
      setLoading(false);

      if (!viewId) {
        let visitId;
        let visitorId;
        try {
          visitId = sessionStorage.getItem('visitId');
          if (!visitId) {
            visitId = uuid();
            sessionStorage.setItem('visitId', visitId as string);
          }
          visitorId = window.localStorage.getItem('visitorId');
          if (!visitorId) {
            visitorId = uuid();
            window.localStorage.setItem('visitorId', visitorId as string);
          }
        } catch (e) {
          console.error('Unable to record viewId because cookies are blocked');
        }

        const createViewResponse = await API.content.CreateView({
          clientId: searchParams.get('clientId') as string,
          clientContentId: searchParams.get('clientContentId') as string,
          subscription: true,
          sessionId: authDetails.sessionId,
          utmParameters: getUtmParameters(),
          ...(visitId && { visitId }),
          ...(visitorId && { visitorId }),
        });
        postMessage.CREATE_VIEW(createViewResponse.data.viewId);
        window.dispatchEvent(new CustomEvent('view-id-created', { detail: createViewResponse.data.viewId }));
        setViewId(createViewResponse.data.viewId);

        try {
          sessionStorage.setItem('viewId', createViewResponse.data?.viewId);
        } catch (error) {
          console.error('Unable to record viewId because cookies are blocked');
        }
      }
    })();
  }, []);

  const updateView = async ({
    action,
    userId,
    subscriptionDetails,
  }: {
    action: userActions;
    userId?: string;
    subscriptionDetails?: Record<string, any>;
  }) => {
    let newViewId = '';
    if (!viewId) {
      newViewId = await new Promise((res) => {
        window.addEventListener('view-id-created', (e) => {
          //@ts-ignore
          res(e.detail);
        });
      });
    }
    const updateViewResponse = await API.content.UpdateView({
      viewId: viewId || newViewId,
      action,
      userId: authDetails.userId || userId,
      sessionId: authDetails.sessionId,
      ...(availFreeTrial && { freeTrial: availFreeTrial }),
      subscriptionDetails,
    });
    const userData = updateViewResponse?.data?.view?.userData;
    setUserDetails({
      ...userDetails,
      defaultEmail: userData?.defaultEmail,
      defaultPhone: userData?.defaultPhone,
      defaultName: userData?.defaultName,
      userDetailsSet: true,
    });
  };

  async function payForSubscription({
    // state functions from components that call this function
    setError,
    setLoading,
    couponCode,
    subscriptionDetails,
    renewSubscription,
    transactionInfo,
    consumeFreeTrial,
    utmParameters,
    rzpSubscriptionId,
  }: {
    setError?: (errorMsg: string) => any;
    setLoading?: (state: boolean) => any;
    couponCode?: string;
    renewSubscription?: boolean;
    subscriptionDetails: {
      clientId?: string;
      clientContentId?: string;
      _id: string;
      amount?: number;
      currency?: string;
      tierId: string;
      offerIds?: string[];
      popupId?: string;
    };
    transactionInfo?: string;
    consumeFreeTrial?: boolean;
    utmParameters?: Record<string, any>;
    rzpSubscriptionId?: string;
  }) {
    setError?.('');
    setLoading?.(true);
    const clientContentId = subscriptionDetails?.clientContentId || subscriptionPageParams.clientContentId;
    const purchaseSubscription = (await API.user.PurchaseSubscription({
      ...(clientContentId && { clientContentId }),
      clientId: subscriptionDetails?.clientId || subscriptionPageParams.clientId,
      price: subscriptionDetails.amount,
      currency: subscriptionDetails.currency,
      couponCode: couponCode || subscriptionPageParams.couponCode,
      offerIds: subscriptionDetails?.offerIds || subscriptionPageParams.offerIds,
      tierId: subscriptionDetails.tierId,
      subscriptionId: subscriptionDetails._id,
      renew: renewSubscription,
      transactionInfo,
      consumeFreeTrial,
      utmParameters,
      popupId: subscriptionPageParams.popupId || subscriptionDetails.popupId,
      rzpSubscriptionId,
      viewId,
    })) as AxiosResponse<any>;
    setLoading?.(false);

    if (!API.validators.checkSuccessCode(purchaseSubscription)) {
      // @ts-ignore
      if (purchaseSubscription.data.message === 'Content Price Mismatch!') {
        setError?.('Price of the subscription updated, please refresh the page');
        // @ts-ignore
      } else if (
        purchaseSubscription.data.message === 'Insufficient balance. Please load wallet to purchase this content'
      ) {
        setError?.('Insufficient balance to purchase subscription');
        return setRoute(SUBSCRIPTION_ROUTES.REVIEW);
        // @ts-ignore
      } else if (purchaseSubscription.data.message === 'User already has an active subscription for this client') {
        setError?.('You have already purchased this subscription, please refresh');
        return setRoute(SUBSCRIPTION_ROUTES.THANKYOU);
      } else if (purchaseSubscription.status === 401) {
        setError?.('You have been logged out, please refresh the page');
        // @ts-ignore
      } else {
        // @ts-ignore
        setError?.(purchaseSubscription.data.message);
      }
    }

    if (purchaseSubscription.status === 201) {
      updateView({
        action: 'PURCHASE',
      });
      const purchaseSubscriptionData = purchaseSubscription.data as any & {
        message?: string;
        purchaseId: string;
      };
      delete purchaseSubscriptionData.message;
      setTimeout(() => {
        return setRoute(SUBSCRIPTION_ROUTES.THANKYOU);
      }, 2000);
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        userDetails,
        setUserDetails,
        subscriptionDetails,
        setSubscriptionDetails,
        purchasedSubscriptionDetails,
        internalUser,
        clientId: clientId as string,
        clientContentId: clientContentId as string,
        offerIds,
        selectedTierId: selectedTierId as string,
        initializeSubscriptions,
        selectedSubscription,
        setSelectedSubscription,
        selectedTier,
        detectedCountry,
        detectedCurrency,
        payForSubscription,
        loading,
        subscriptionPageParams,
        setSubscriptionPageParams,
        setSelectedTier,
        route,
        setRoute,
        subscriptionSettings,
        viewId: viewId,
        availFreeTrial: availFreeTrial,
        setViewId,
        updateView,
        getUtmParameters,
        transactionDetails,
        defaultContentAction,
        clientName,
        ampRid,
        allowRenewal,
        embeddedSubscriptionsTitle,
        embeddedSubscriptions,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
