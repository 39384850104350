import React, { useState, useContext, useEffect } from 'react';
import { Alert, Checkbox } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import style from './embeddedSubscription.module.scss';
import { postMessage } from '../../UserOverlay/paywallUtils';
import { SubscriptionContext } from '../SubscriptionContext';
import Button from 'components/CustomButton';
import { PayWithConscentBlack } from 'views/UserOverlay/components/ButtonWithFooter';
import getWordsFromMonths from 'utils/getWordsFromMonths';
import { getCurrencySymbol } from 'data/getCurrencySymbol';
import { calculateDiscountPercentage } from 'utils/calculateDiscountPercentage';
import SmallLoadingCircle from 'views/UserOverlay/components/loaders/SmallLoadingCircle';
import clsx from 'clsx';

export default function EmbeddedSubscription() {
  const {
    setSelectedTier,
    setSelectedSubscription,
    setSubscriptionPageParams,
    viewId,
    selectedTier,
    embeddedSubscriptionsTitle,
    embeddedSubscriptions,
  } = useContext(SubscriptionContext);
  const [error, setError] = useState('');
  const [viewMore, setViewMore] = useState(false);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [displaySubIndex, setDisplaySubIndex] = useState(0);
  const [freeTrial, setFreeTrial] = useState(true);
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const [embeddedtitleval, setEmbeddedTitleVal] = useState<string>('');
  useEffect(() => {
    if (!(subscriptions?.length > 0)) return;
    else {
      const previouslySelectedTierId = searchParams.get('tierId');

      if (previouslySelectedTierId) {
        subscriptions.forEach((sub: any, index: number) => {
          sub.tiers?.forEach((tier: any) => {
            if (tier?._id === previouslySelectedTierId) {
              setDisplaySubIndex(index);
              setSelectedTier(previouslySelectedTierId);
            }
          });
        });
      } else {
        setSelectedTier(subscriptions?.[0].tiers[0]._id);
      }

      setLoading(false);
    }
  }, [subscriptions]);
  useEffect(() => {
    setEmbeddedTitleVal(embeddedSubscriptionsTitle);
    setSubscriptions(embeddedSubscriptions);
  }, []);

  const next = () => {
    if (displaySubIndex < subscriptions.length - 1) {
      setDisplaySubIndex(displaySubIndex + 1);
      setSelectedTier(subscriptions[displaySubIndex + 1].tiers[0]._id);
    }
  };
  const prev = () => {
    if (displaySubIndex > 0) {
      setDisplaySubIndex(displaySubIndex - 1);
      setSelectedTier(subscriptions[displaySubIndex - 1].tiers[0]._id);
    }
  };

  const onSubscribe = () => {
    if (!selectedTier) {
      setError('Please choose a tier to proceed');
      return;
    }
    let popupId;
    const siteUrlString = searchParams.get('siteUrl');
    const siteUrlValue = siteUrlString ? new URL(decodeURIComponent(siteUrlString)) : undefined;
    const siteUrlSearchParams = siteUrlValue?.searchParams;
    if (siteUrlSearchParams?.get('popupId')) popupId = siteUrlSearchParams?.get('popupId') as string;
    setSubscriptionPageParams({
      clientId: (searchParams.get('clientId') as string) as string,
      offerIds: subscriptions[displaySubIndex].offers,
      siteUrl: searchParams.get('siteUrl'),
      freeTrial: subscriptions[displaySubIndex].freeTrial,
      popupId,
    });
    setSelectedSubscription(subscriptions[displaySubIndex]);
    postMessage.REDIRECT_TO_URL(
      window.location.origin +
        `/subscription?clientId=${searchParams.get('clientId')}${
          searchParams.get('clientContentId') ? `&clientContentId=${searchParams.get('clientContentId')}` : ''
        }&tierId=${selectedTier}${
          subscriptions[displaySubIndex]?.offers?.length > 0
            ? `&offers=${subscriptions[displaySubIndex].offers.join(',')}`
            : ''
        }&viewId=${viewId}&siteUrl=${searchParams.get('siteUrl')}${freeTrial ? '&availFreeTrial=true' : ''}`,
    );

    try {
      sessionStorage.setItem(
        'selectedSubscription',
        JSON.stringify({ tier: selectedTier, displaySubIndex: displaySubIndex }),
      );
    } catch (error) {
      console.error('cant able to access');
    }
  };

  const getBenefits = () => {
    if (viewMore) {
      return subscriptions[displaySubIndex]?.benefits?.split(',');
    }
    return subscriptions[displaySubIndex]?.benefits?.split(',').slice(0, 3);
  };

  return (
    <>
      <div className={style['container']}>
        <div className={style['header']}>{embeddedtitleval}</div>

        {!loading ? (
          <div className={style['body-container']}>
            <div className={style['item-heading']}>
              <div style={{ padding: '8px 0px' }}> Choose Plan</div>
              <div className={style['nav-buttons']}>
                {subscriptions.length > 1 && (
                  <>
                    <div style={{ position: 'absolute' }}>
                      <CaretLeftOutlined
                        onClick={() => prev()}
                        className={style['pre-button']}
                        style={displaySubIndex > 0 ? { color: '#b10f15' } : { color: 'lightgray', opacity: 1 }}
                      />
                    </div>
                    <div style={{ position: 'absolute', left: '336px' }}>
                      <CaretRightOutlined
                        onClick={() => next()}
                        className={style['next-button']}
                        style={
                          displaySubIndex < subscriptions.length - 1
                            ? { color: '#b10f15' }
                            : { color: 'lightgray', opacity: 1 }
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={style['wrap-offers-and-benefits']}>
              <div className={style['button-container']}>
                {subscriptions[displaySubIndex]?.tiers?.map((key: any, i: number) => (
                  <button
                    key={`button_` + i}
                    className={style['subscription-button']}
                    onClick={() => setSelectedTier(key._id)}
                    style={selectedTier === key._id ? { border: '1px solid #b10f15' } : { border: '1px solid #dddddd' }}
                  >
                    <div>
                      {key.basePrice > 0 && (
                        <>
                          <div style={{ position: 'absolute', top: '-9px' }}>
                            <span
                              style={{ fontSize: '12px', textDecoration: 'line-through', opacity: 0.7 }}
                            >{`${getCurrencySymbol(key.currency)}${key.basePrice.toFixed(0)}`}</span>

                            <span style={{ fontSize: '12px', color: '#b10f15' }}>
                              {' '}
                              {calculateDiscountPercentage(key.basePrice, key.price)}% off
                            </span>
                          </div>
                        </>
                      )}
                      <div className={style['button-price']}>
                        <span>{`${getCurrencySymbol(key.currency)}${key.price.toFixed(2)}`}</span>
                      </div>
                      <p className={style['duration-text']}>{getWordsFromMonths(key.duration)}</p>
                    </div>
                  </button>
                ))}
              </div>

              <div className={style['item-container']}>
                <div className={style['item-heading']}>Benefits: You will get</div>
                <div className={style['wrap-items']}>
                  {getBenefits().map((benefit: string, id: number) => (
                    <div className={style['items']} key={id}>
                      <div className={style['item-bullet']}></div>
                      {benefit}
                    </div>
                  ))}
                </div>

                {subscriptions[displaySubIndex]?.freeTrial.enabled && (
                  <Checkbox
                    className={style['check-box']}
                    checked={freeTrial}
                    onChange={() => {
                      setFreeTrial(!freeTrial);
                    }}
                  >
                    Start your{' '}
                    {`${subscriptions[displaySubIndex].freeTrial.duration}-day${
                      subscriptions[displaySubIndex].freeTrial.duration > 1 ? 's ' : ' '
                    }`}
                    <span style={{ color: '#B00000' }}>FREE </span>
                    Trial
                  </Checkbox>
                )}
              </div>
            </div>

            {error && <Alert message={error} type="error" showIcon style={{ margin: 8 }} />}
            <div className={style['subscribe-button-wrap']}>
              <Button
                customStyle={{ width: '280px', borderRadius: '30px', height: '48px', marginRight: '0px' }}
                onClick={() => onSubscribe()}
              >
                Subscribe
              </Button>
              <PayWithConscentBlack customTextStyle={{ margin: '4px 0px 8px 0px' }} />
            </div>
          </div>
        ) : (
          <div className={clsx(style['body-container'], style['loading-container'])}>
            <SmallLoadingCircle />
          </div>
        )}
      </div>
    </>
  );
}
