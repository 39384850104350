import React from 'react';
import SubscriptionRouter from './BundledSubscriptionRouter';
import { ContextWrapper } from './BundledSubscriptionsContext';

const BundledSubscriptionMain = () => {
  return (
    <>
      <ContextWrapper>
        <SubscriptionRouter />
      </ContextWrapper>
    </>
  );
};

export default BundledSubscriptionMain;
