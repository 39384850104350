import React from 'react';
import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import API from 'api';
import { getCurrencySymbol } from '../data/currencies';
import { Countries } from 'data/countries';
import { countryWisePhoneCodes } from '../data/phoneCodes';
import {
  BundleSubscriptionContext,
  SUBSCRIPTION_BUNDLE_ROUTES,
} from '../../src/views/BundledSubscriptions/BundledSubscriptionsContext';
import styles from '../components/Edit.module.scss';
interface selectedSubscriptionsInterface {
  clientId: string;
  clientName: string;
  currency: string;
  duration: number;
  price: number;
  tierId: string;
  title: string;
  _id: string;
}

const SubsEditPage = (props: { phonenumber: string; email: string }) => {
  const { setRoute, bundledSubscriptionDetails, userDetails } = useContext(BundleSubscriptionContext);
  const [name, setname] = useState('');
  const [tempName, setTempName] = useState('');
  const [email, setEmail] = useState(userDetails.email || props.email || userDetails.secondaryEmail);
  const [phonenumber, setPhoneNumber] = useState(
    userDetails.phoneNumber || props.phonenumber || userDetails.secondaryPhoneNumber,
  );
  const [tempEmail, setTempEmail] = useState('');
  const [tempPhoneNumber, setTempPhoneNumber] = useState('');
  const [ISD, setISD] = useState('');

  const [showPop, setShowPop] = useState(false);
  const [addressInfo, setAddressInfo] = useState({
    apartment: '',
    pincode: '',
    city: '',
    country: '',
    state: '',
  });
  const [tempaddressInfo, setTempAddressInfo] = useState({
    apartment: '',
    pincode: '',
    city: '',
    country: bundledSubscriptionDetails.address?.country || '',
    state: '',
  });
  const detectcountry = async () => {
    const currencyAndCountryResponse = await API.auth.DetectCountry();
    const currencycode: any = currencyAndCountryResponse?.data?.countryCode;
    const currencyNumber = countryWisePhoneCodes[currencycode][0];
    setISD(currencyNumber);
  };
  useEffect(() => {
    detectcountry();
  }, [ISD]);
  const submitData = async () => {
    const postbody = {
      referenceId: bundledSubscriptionDetails._id,
      name: tempName || bundledSubscriptionDetails.name,
      address: {
        apartment: tempaddressInfo.apartment || bundledSubscriptionDetails.address?.apartment,
        pincode: tempaddressInfo.pincode || bundledSubscriptionDetails.address?.pincode,
        city: tempaddressInfo.city || bundledSubscriptionDetails.address?.city,
        country: tempaddressInfo.country || bundledSubscriptionDetails.address?.country,
        state: tempaddressInfo.state || bundledSubscriptionDetails.address?.state,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL || 'http://localhost:3001/api/v1'}/user/temporary-user`,
      postbody,
    );
    if (response.status === 201) {
      alert('updated succesfull');
    } else {
      alert('Could not updated');
    }
  };
  const submitEmailPhone = async () => {
    const postbody = {
      referenceId: bundledSubscriptionDetails._id,
      phoneNumber: phonenumber || tempPhoneNumber,
      email: email || tempEmail,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL || 'http://localhost:3001/api/v1'}/user/temporary-user`,
      postbody,
    );
    if (response.status === 201) {
      setRoute(SUBSCRIPTION_BUNDLE_ROUTES.PAYMENT_PAGE);
    } else {
      alert('Could not updated');
    }
  };
  const checkmail = () => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email) {
      return true;
    }

    if (tempEmail.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };
  const checkPhone = () => {
    if (phonenumber) {
      return true;
    }
    if (tempPhoneNumber.length > 9 && tempPhoneNumber.length < 16) {
      return true;
    } else {
      return false;
    }
  };
  const updateData = () => {
    setAddressInfo({ ...tempaddressInfo });
    setname(tempName);
    setShowPop(false);
  };

  const renderSubscribtion: any = (selectedSubscriptions: selectedSubscriptionsInterface[]) => {
    return selectedSubscriptions.map((data: selectedSubscriptionsInterface, index: any) => {
      return (
        <div className={styles['plans']} key={index}>
          <div>
            {data.clientName}
            {' ('}
            {data.title}
            {') '}
          </div>
          <div className={styles['data']}>
            <span style={{ paddingRight: '20px', color: '#c42b22' }}>
              {moment().add(data.duration, 'M').format('MMMM YYYY')}
            </span>
            <span style={{ color: '#c42b22' }} className={styles['price']}>
              {getCurrencySymbol(data.currency)}
              {data?.price?.toFixed(2)}
            </span>
          </div>
        </div>
      );
    });
  };
  const editDetails = () => {
    return (
      <div className={styles['editDetails-container']}>
        <div className={styles['backdrop']} onClick={() => setShowPop(false)} />
        <div className={styles['inner-container']}>
          <div className={styles['cross-icon-container']}>
            <div
              className={styles['cross-icon']}
              onClick={() => {
                setShowPop(false);
              }}
            >
              X
            </div>
          </div>
          <div className={styles['data-container']}>
            <div className={styles['subsheading']} style={{ paddingBottom: '0px' }}>
              Edit Details
            </div>
            <div>Name</div>
            <input
              onChange={(e) => {
                setTempName(e.target.value);
              }}
              className={styles['full-width-input']}
              type="text"
              placeholder="Full name"
            />
            <div>Adresss</div>
            <input
              onChange={(e) => {
                setTempAddressInfo({ ...tempaddressInfo, ['apartment']: e.target.value });
              }}
              className={styles['full-width-input']}
              type="email"
              placeholder="Mailing address"
            />
            <div className={styles['half-width-input-container']}>
              <select
                className={styles['half-width-input']}
                onChange={(e: any) => {
                  setTempAddressInfo({ ...tempaddressInfo, ['country']: e.target.value });
                }}
                defaultValue={tempaddressInfo.country}
              >
                <option value="" disabled selected>
                  Select Country
                </option>
                {Countries.map((country, index) => {
                  return (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
              <input
                onChange={(e: any) => {
                  setTempAddressInfo({ ...tempaddressInfo, ['state']: e.target.value });
                }}
                className={styles['half-width-input']}
                type="text"
                placeholder="Select State"
              />
            </div>
            <div className={styles['half-width-input-container']}>
              <input
                onChange={(e: any) => {
                  setTempAddressInfo({ ...tempaddressInfo, ['city']: e.target.value });
                }}
                className={styles['half-width-input']}
                type="text"
                placeholder="Select City"
              />
              <input
                maxLength={10}
                onChange={(e: any) => {
                  setTempAddressInfo({ ...tempaddressInfo, ['pincode']: e.target.value });
                }}
                className={styles['half-width-input']}
                type="text"
                placeholder="ZIP/PIN Code"
              />
            </div>
            <div className={styles['cancel-save-container']}>
              <span onClick={() => setShowPop(false)} className={styles['cancel-buton']}>
                Cancel
              </span>
              <span
                onClick={() => {
                  submitData();
                  updateData();
                }}
                className={styles['save-buton']}
              >
                SAVE →
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles['container']}>
      <div className={styles['midcontainer']}>
        <span className={styles['subsheading']}>Subscription Review</span>
        <div className={styles['plans']} style={{ color: '#555554', fontWeight: 'normal' }}>
          <div>Subscription type</div>
          <div>
            <span style={{ paddingRight: '20px' }}>Validity</span>
            <span className={styles['amount']}>Amount</span>
          </div>
        </div>
        <div style={{ marginBottom: '30px' }}>
          {renderSubscribtion(bundledSubscriptionDetails.selectedSubscriptions)}
        </div>
        <div>
          <div className={styles['details']} style={{ paddingBottom: '30px' }}>
            <span>Details</span>{' '}
            <span
              onClick={() => setShowPop(!showPop)}
              style={{ fontSize: '14px', cursor: 'pointer', color: '#b10f15', textDecoration: 'underline' }}
            >
              Edit
            </span>
            {showPop && editDetails()}
          </div>
          <div style={{ fontSize: '14px', fontWeight: 500 }}>{name || bundledSubscriptionDetails.name}</div>
          <div className={styles['address']}>
            <span>{addressInfo.apartment || bundledSubscriptionDetails.address?.apartment} </span>
            <span>{addressInfo.city || bundledSubscriptionDetails.address?.city} </span>
            <span>{addressInfo.state || bundledSubscriptionDetails.address?.state} </span>
            <span>{addressInfo.pincode || bundledSubscriptionDetails.address?.pincode}</span>
            <br />
            <span>{addressInfo.country || bundledSubscriptionDetails.address?.country}</span>
          </div>
          {phonenumber ? (
            <div>
              <span style={{ fontWeight: 500 }}>Mobile: </span>
              <span>{phonenumber}</span>
              <span style={{ color: 'green' }}>{!tempPhoneNumber ? '(verified)' : ''}</span>
            </div>
          ) : (
            <div className={styles['mobilenumber']}>
              <span style={{ fontSize: '14px', fontWeight: 500 }}>Mobile * </span>
              <br />
              <div className={styles['code']}>
                <div className={styles['showcode']}>+{ISD} </div>
                <input
                  type="text"
                  maxLength={15}
                  placeholder="Enter your mobile"
                  className={styles['mobileinput']}
                  onChange={(e) => {
                    setTempPhoneNumber(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          {email ? (
            <div>
              <span style={{ fontWeight: 500 }}>Email: </span>
              <span>{email} </span>
              <span style={{ color: 'green' }}>{!tempEmail ? '(verified)' : ''}</span>
            </div>
          ) : (
            <div className={styles['mobilenumber']}>
              <span style={{ fontSize: '14px', fontWeight: 500 }}>Email * </span>
              <span className={styles['smallfont']}>
                (Email will be used to send confirmation and subscription details)
              </span>
              <br />
              <input
                type="email"
                placeholder="Enter your email"
                className={styles['emailInput']}
                onChange={(e) => {
                  setTempEmail(e.target.value);
                }}
              />
            </div>
          )}
        </div>

        <div className={styles['total']}>
          <div className={styles['left']}>
            <div>Total</div>
            <div style={{ fontSize: '37px', fontWeight: 500, color: '#b10f15' }}>
              <span> {getCurrencySymbol(bundledSubscriptionDetails?.currency)}</span>
              {parseFloat(bundledSubscriptionDetails.price.toFixed(2))}
            </div>
          </div>
          <div className={styles['right']}>
            <div
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_SUBS_URL}/bundle-subscriptions?clientId=${bundledSubscriptionDetails.clientId}`;
              }}
              className={styles['changePlan']}
              style={{ color: '#B10F15' }}
            >
              Change plan
            </div>
            <button
              onClick={() => {
                submitEmailPhone();
                setRoute(SUBSCRIPTION_BUNDLE_ROUTES.PAYMENT_PAGE);
              }}
              className={styles[checkmail() && checkPhone() ? 'confirm' : 'not-confirm']}
              disabled={checkmail() && checkPhone() ? false : true}
            >
              Confirm and pay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubsEditPage;
