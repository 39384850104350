import FloatInputBox from 'components/InputBox/FloatInputBox';
import React, { useState, useContext, useEffect } from 'react';
import { Select, Alert } from 'antd';
import clsx from 'clsx';
import API from 'api';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { validate } from 'views/UserOverlay/Validator';
import style from './address.module.scss';
import ProgressBar from './ProgressBar';
import { SubscriptionContext, SUBSCRIPTION_ROUTES } from '../SubscriptionContext';
import { logError } from 'utils';
import SubscriptionFooter from './SubscriptionFooter';
import { States } from '../../../data/states';
import { Countries } from '../../../data/countries';
import { validateEmail } from 'utils/validators';
import axios from 'axios';
import { getDefaultTrackingValues, eventType, clickAction, eventLocation } from '../../../utils/Tracker/track';
import { env } from 'process';

interface FieldValues {
  name: string;
  apartment: string;
  area: string;
  city: string;
  state: string;
  country: string;
  landmark: string;
  pincode: string;
  email: string;
  phone: string;
}

export default function Address() {
  const {
    userDetails,
    setRoute,
    selectedSubscription,
    updateView,
    setUserDetails,
    subscriptionSettings,
    detectedCountry,
    detectedCurrency,
    clientContentId,
    clientId,
  } = useContext(SubscriptionContext);
  const authStore = useSelector((store: RootState) => store.auth);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const [error, setError] = useState('');
  const [phoneError, setPhoneError] = useState('' as string);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState(States['IN']);
  const [countries, setCountries] = useState([{ name: 'India', code: 'IN' }] as { name: string; code: string }[]);
  const [stateDropDownDisabled, setStateDropDownDisabled] = useState(false);
  const [collectAddress, setCollectAddress] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    name: '',
    apartment: '',
    area: '',
    city: '',
    state: '',
    country: 'IN',
    landmark: '',
    pincode: '',
    email: '',
    phone: '',
  } as FieldValues);
  const [isMobile, setIsMobile] = useState(false as boolean);
  const searchParams = new URLSearchParams(location.search);

  const OnInput = (e: any) => {
    setFieldValues({ ...fieldValues, [e.target.name]: e.target.value });

    if (e.target.name === 'phone' && detectedCurrency === 'INR') {
      if (
        (e.target.value.length && !validate(e.target.value, { pattern: /^\d+$/ })) ||
        (e.target.value.length === 10 && !validate(e.target.value, { pattern: /^[6-9]\d{9}$/ }))
      )
        setPhoneError('Invalid phone number entered');
      else if (phoneError) setPhoneError('');
    }
  };

  const OnCountryChange = (v: any) => {
    if (v.toString() === 'IN' || v.toString() === 'US' || v.toString() === 'CA') {
      //@ts-ignore
      setStates(States[v.toString()]);
      setStateDropDownDisabled(false);
      setFieldValues({ ...fieldValues, country: v.toString() });
    } else {
      setFieldValues({ ...fieldValues, country: v.toString(), state: '' });
      setStateDropDownDisabled(true);
    }
  };

  useEffect(() => {
    if (searchParams.get('error') === 'true') {
      setError('Transaction failed. Please try again');
      window.history.replaceState('object or string', 'Title', '' + window.location.search.split('&error=true')[0]);
    }
    updateView({ action: 'ADDRESS' });

    setCountries(
      subscriptionSettings?.physicalSubscriptionCountries.map((c) => Countries.find((el) => el.code === c)) as {
        name: string;
        code: string;
      }[],
    );
    if (!(detectedCountry === 'IN' || detectedCountry === 'US' || detectedCountry === 'CA')) {
      setStateDropDownDisabled(true);
    }
    if (window.innerWidth <= 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  useEffect(() => {
    addressPageView();
  }, []);

  //@ts-ignore
  const params = new URL(document.location).searchParams;
  const anonId = params.get('anonId');
  const siteUrl = new URLSearchParams(decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || ''));
  const paywallId = siteUrl.get('paywallId');
  const landingPageId = params.get('landingPageId');
  const landingPageTitle = params.get('landingPageTitle');
  const tierId = params.get('tierId');
  const addressPageView = () => {
    const addressPageViewObject = {
      ...getDefaultTrackingValues(),
      clientId: clientId,
      contentId: clientContentId,
      userId: userDetails._id,
      eventType: eventType.VIEW,
      paywallId: paywallId || '',
      eventLocation: eventLocation.SAP,
      landingPageId,
      landingPageTitle,
      tierId: tierId || '',
      anonId: anonId,
      firstTimeLogin: !analyticsStore.alreadyRegistered,

      // anonId:
    };
    API.analyst.eventPost({ ...addressPageViewObject });
  };
  const addressClick = () => {
    const addressClickObject = {
      ...getDefaultTrackingValues(),
      clientId: clientId,
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SAP,
      clickAction: clickAction.ADD_ADDRESS,
      paywallId: analyticsStore.paywallId,
      anonId: anonId,
      landingPageId,
      landingPageTitle,
      userId: userDetails._id,
      contentId: clientContentId,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
    };
    API.analyst.eventPost({ ...addressClickObject });
  };

  const dropDownStyles = {
    inpCustomStyle: {
      border: 'solid 1px rgba(0, 0, 0, 0.12)',
    },
    labelCustomStyle: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    parentDivCustomStyle: {
      marginTop: '16px',
    },
  };

  const submitFunction = async (event: any) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    let formKeys = Object.keys(fieldValues) as (keyof typeof fieldValues)[];
    if (!collectAddress) formKeys = ['email', 'name'];
    for (const key of formKeys) {
      if (key !== 'landmark' && key !== 'state' && !fieldValues[key]) {
        setLoading(false);
        return setError(`${key} is mandatory`);
      }
    }
    if (!validateEmail(fieldValues.email)) {
      setLoading(false);
      return setError('Please enter a valid secondary email');
    }
    const updateUserResponse = await API.user.setDetails({
      name: fieldValues.name,
      ...(!userDetails.email && fieldValues.email && { secondaryEmail: fieldValues.email }),
      ...(collectAddress && {
        ...(!userDetails.phoneNumber && fieldValues.phone && { secondaryPhoneNumber: fieldValues.phone }),
        address: {
          city: fieldValues.city,
          apartment: fieldValues.apartment,
          area: fieldValues.area,
          country: fieldValues.country || 'IN',
          landmark: fieldValues.landmark,
          pincode: fieldValues.pincode,
          state: fieldValues.state,
        },
      }),
    });
    if (fieldValues.email) setUserDetails({ ...userDetails, secondaryEmail: fieldValues.email });
    if (fieldValues.phone) setUserDetails({ ...userDetails, secondaryPhoneNumber: fieldValues.phone });
    if (!API.validators.checkSuccessCode(updateUserResponse)) {
      setLoading(false);
      setError(
        updateUserResponse.data?.message[0] ||
          updateUserResponse.data?.message ||
          'Failed to update details, please try again later',
      );
      logError({ error: 'Failed to update details', values: fieldValues });
    } else {
      setUserDetails({ ...userDetails, ...updateUserResponse?.data?.user });
      setLoading(false);
      // to help user stay on review page even if he refreshes page
      sessionStorage.setItem('address-confirmed', authStore.sessionId || '');
      return setRoute(SUBSCRIPTION_ROUTES.REVIEW);
    }
  };

  useEffect(() => {
    //@ts-ignore
    setStates(States[userDetails?.address?.country || detectedCountry || 'IN']);
    setFieldValues({
      name: userDetails.name || userDetails.defaultName,
      apartment: userDetails?.address?.apartment || '',
      area: userDetails?.address?.area || '',
      city: userDetails?.address?.city || '',
      state: userDetails?.address?.state || '',
      country: userDetails?.address?.country || detectedCountry || 'IN',
      landmark: userDetails?.address?.landmark || '',
      pincode: userDetails?.address?.pincode || '',
      email: userDetails?.email || userDetails?.secondaryEmail || userDetails?.defaultEmail || '',
      phone: userDetails?.phoneNumber || userDetails?.secondaryPhoneNumber || userDetails?.defaultPhone || '',
    });
  }, [userDetails._id, userDetails.userDetailsSet]);

  useEffect(() => {
    if (selectedSubscription?.physical) setCollectAddress(true);
  }, [selectedSubscription]);

  return (
    <>
      <div>
        <ProgressBar activeTab="address" />
      </div>

      <form onSubmit={submitFunction}>
        <div className={style['address-container']}>
          <div className={style['main-contianer']}>
            <p className={style['main-header']}>{collectAddress ? 'Add billing Address' : 'Add Details'}</p>
            {collectAddress && <p className={style['sub-header']}>Billing address must be within India</p>}
            <div className={style['fields']}>
              <FloatInputBox
                Label={'Full Name'}
                type={'text'}
                fieldName="name"
                value={fieldValues.name}
                OnInput={OnInput}
                inpCustomStyle={dropDownStyles.inpCustomStyle}
                labelCustomStyle={dropDownStyles.labelCustomStyle}
                parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                compulsoryField={true}
              />

              <FloatInputBox
                Label={'Email'}
                type={'text'}
                fieldName="email"
                disabled={!!userDetails.email}
                value={fieldValues.email}
                OnInput={OnInput}
                inpCustomStyle={{ border: 'solid 1px rgba(0, 0, 0, 0.12)' }}
                labelCustomStyle={{ color: 'rgba(0, 0, 0, 0.6)' }}
                parentDivCustomStyle={{ ...dropDownStyles.parentDivCustomStyle }}
                compulsoryField={true}
              />
              {!userDetails?.email ? (
                <p>Give your email to complete your profile and get personalised offers</p>
              ) : (
                <p> Use this email to log in</p>
              )}

              {collectAddress ? (
                <>
                  <FloatInputBox
                    Label={phoneError ? phoneError : 'Phone Number'}
                    type="text"
                    maxLength={15}
                    fieldName="phone"
                    value={fieldValues.phone}
                    OnInput={OnInput}
                    inpCustomStyle={{ ...dropDownStyles.inpCustomStyle }}
                    labelCustomStyle={phoneError ? { color: 'red' } : dropDownStyles.labelCustomStyle}
                    parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                    compulsoryField={true}
                    disabled={!!userDetails.phoneNumber}
                  />
                  {!userDetails?.phoneNumber ? (
                    <p>Give your phone number to complete your profile and get personalised offers</p>
                  ) : (
                    <p> Use this phone number to log in</p>
                  )}
                  <FloatInputBox
                    Label={'House / Flat / Apartment'}
                    type={'text'}
                    fieldName="apartment"
                    value={fieldValues.apartment}
                    OnInput={OnInput}
                    inpCustomStyle={dropDownStyles.inpCustomStyle}
                    labelCustomStyle={dropDownStyles.labelCustomStyle}
                    parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                    compulsoryField={true}
                  />
                  <FloatInputBox
                    Label={'Street / Block / Area'}
                    type={'text'}
                    fieldName="area"
                    value={fieldValues.area}
                    OnInput={OnInput}
                    inpCustomStyle={dropDownStyles.inpCustomStyle}
                    labelCustomStyle={dropDownStyles.labelCustomStyle}
                    parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                    compulsoryField={true}
                  />
                  <FloatInputBox
                    Label={'Landmark'}
                    type={'text'}
                    fieldName="landmark"
                    value={fieldValues.landmark}
                    OnInput={OnInput}
                    inpCustomStyle={dropDownStyles.inpCustomStyle}
                    labelCustomStyle={dropDownStyles.labelCustomStyle}
                    parentDivCustomStyle={dropDownStyles.parentDivCustomStyle}
                  />
                  <div>
                    <div className={clsx(style['item'], style['pl-10'])}>
                      <div className={style['select-label']}>
                        Country <span style={{ color: '#b10f15' }}>*</span>
                      </div>
                      <Select
                        className={style['dropdown']}
                        dropdownClassName={style['custom-dropdown']}
                        onChange={OnCountryChange}
                        value={fieldValues.country || 'Select Country'}
                        showSearch={true}
                        showArrow
                        style={{ backgroundColor: '#606060' }}
                        filterOption={(input, option: any) =>
                          option.title.toLowerCase().indexOf(input.toLowerCase()) == 0
                        }
                      >
                        {countries.map((item: any) => (
                          <Select.Option value={item.code} key={item} title={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div>
                    <div className={clsx(style['item'], style['pl-10'])}>
                      {stateDropDownDisabled ? (
                        <div style={{ marginTop: '10px' }}>
                          <FloatInputBox
                            Label={'State/Province'}
                            type={'text'}
                            fieldName="state"
                            value={fieldValues.state}
                            OnInput={OnInput}
                            inpCustomStyle={dropDownStyles.inpCustomStyle}
                            labelCustomStyle={dropDownStyles.labelCustomStyle}
                          />
                        </div>
                      ) : (
                        <>
                          <div className={style['select-label']}>
                            Select State <span style={{ color: '#b10f15' }}>*</span>
                          </div>
                          <Select
                            className={style['dropdown']}
                            dropdownClassName={style['custom-dropdown']}
                            onChange={(v) => setFieldValues({ ...fieldValues, state: v.toString() })}
                            value={fieldValues.state || 'Select State/Province'}
                          >
                            {states?.map((item: any) => (
                              <Select.Option value={item} key={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={style['split-half']}>
                    <div className={clsx(style['item'], style['pr-10'])}>
                      <FloatInputBox
                        Label={'City'}
                        type={'text'}
                        fieldName="city"
                        value={fieldValues.city}
                        OnInput={OnInput}
                        inpCustomStyle={dropDownStyles.inpCustomStyle}
                        labelCustomStyle={dropDownStyles.labelCustomStyle}
                        compulsoryField={true}
                      />
                    </div>
                    <div className={clsx(style['item'], style['pl-10'])}>
                      <FloatInputBox
                        Label={'Postal Code / Zip Code'}
                        type={'text'}
                        fieldName="pincode"
                        value={fieldValues.pincode}
                        OnInput={OnInput}
                        maxLength={6}
                        inpCustomStyle={dropDownStyles.inpCustomStyle}
                        labelCustomStyle={dropDownStyles.labelCustomStyle}
                        compulsoryField={true}
                      />
                    </div>
                  </div>
                  <p className={style['checkbox']} style={{ marginRight: 8, marginBottom: 0 }}>
                    <span>Item(s) will be shipped to your Billing address</span>
                  </p>
                </>
              ) : (
                <> </>
              )}
              {error && <Alert message={error} type="error" showIcon style={{ margin: 8 }} />}
              <div className={style['button-container']} style={{ marginTop: 22 }}>
                {detectedCurrency === 'INR' ? (
                  <button
                    className={clsx(style['add-button'], loading && style['disabled'])}
                    type="submit"
                    disabled={loading}
                  >
                    Confirm {collectAddress ? 'address' : ''}
                  </button>
                ) : (
                  <button
                    className={clsx(style['add-button'], loading && style['disabled'])}
                    type="submit"
                    disabled={loading}
                  >
                    Confirm {collectAddress ? 'address' : ''}
                  </button>
                )}
              </div>
            </div>
          </div>

          <SubscriptionFooter />
        </div>
      </form>
    </>
  );
}
