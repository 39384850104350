// regex for matches: ^.+?\s([A-Z]{2})\s.+?\s([\d,\s]+)$
// data source: https://www.att.com/support_media/images/pdf/Country_Code_List.pdf

export const phoneCodes = [
  '93',
  '355',
  '213',
  '1684',
  '376',
  '244',
  '1264',
  '672',
  '64',
  '1268',
  '54',
  '374',
  '297',
  '247',
  '61',
  '43',
  '994',
  '1242',
  '973',
  '880',
  '1246',
  '375',
  '32',
  '501',
  '229',
  '1441',
  '975',
  '591',
  '387',
  '267',
  '55',
  '1284',
  '673',
  '359',
  '226',
  '95',
  '257',
  '855',
  '237',
  '1',
  '238',
  '1345',
  '236',
  '235',
  '56',
  '86',
  '61',
  '61',
  '57',
  '269',
  '242',
  '682',
  '506',
  '385',
  '53',
  '357',
  '420',
  '243',
  '45',
  '246',
  '253',
  '1767',
  '1809',
  '1829',
  '1849',
  '593',
  '20',
  '503',
  '240',
  '291',
  '372',
  '251',
  '500',
  '298',
  '679',
  '358',
  '33',
  '594',
  '689',
  '241',
  '220',
  '995',
  '49',
  '233',
  '350',
  '30',
  '299',
  '1473',
  '590',
  '1671',
  '502',
  '224',
  '245',
  '592',
  '509',
  '39',
  '504',
  '852',
  '36',
  '354',
  '91',
  '62',
  '98',
  '964',
  '353',
  '44',
  '972',
  '39',
  '225',
  '1876',
  '81',
  '44',
  '962',
  '7',
  '254',
  '686',
  '965',
  '996',
  '856',
  '371',
  '961',
  '266',
  '231',
  '218',
  '423',
  '370',
  '352',
  '853',
  '389',
  '261',
  '265',
  '60',
  '960',
  '223',
  '356',
  '692',
  '596',
  '222',
  '230',
  '262',
  '52',
  '691',
  '373',
  '377',
  '976',
  '382',
  '1664',
  '212',
  '258',
  '264',
  '674',
  '977',
  '31',
  '599',
  '687',
  '64',
  '505',
  '227',
  '234',
  '683',
  '672',
  '850',
  '1670',
  '47',
  '968',
  '92',
  '680',
  '970',
  '507',
  '675',
  '595',
  '51',
  '63',
  '870',
  '48',
  '351',
  '1787',
  '1939',
  '974',
  '242',
  '262',
  '40',
  '7',
  '250',
  '590',
  '290',
  '1869',
  '1758',
  '590',
  '508',
  '1784',
  '685',
  '378',
  '239',
  '966',
  '221',
  '381',
  '248',
  '232',
  '65',
  '1721',
  '421',
  '386',
  '677',
  '252',
  '27',
  '82',
  '211',
  '34',
  '94',
  '211',
  '597',
  '47',
  '268',
  '46',
  '41',
  '963',
  '886',
  '992',
  '255',
  '66',
  '670',
  '228',
  '690',
  '676',
  '1868',
  '216',
  '90',
  '993',
  '1649',
  '688',
  '256',
  '380',
  '971',
  '44',
  '1',
  '598',
  '1340',
  '998',
  '678',
  '58',
  '84',
  '681',
  '212',
  '967',
  '260',
  '263',
];

export const countryWisePhoneCodes: any = {
  AF: ['93'],
  AL: ['355'],
  DZ: ['213'],
  AS: ['1684'],
  AD: ['376'],
  AO: ['244'],
  AI: ['1264'],
  AQ: ['672', '64'],
  AG: ['1268'],
  AR: ['54'],
  AM: ['374'],
  AW: ['297'],
  AC: ['247'],
  AU: ['61'],
  AT: ['43'],
  AZ: ['994'],
  BS: ['1242'],
  BH: ['973'],
  BD: ['880'],
  BB: ['1246'],
  BY: ['375'],
  BE: ['32'],
  BZ: ['501'],
  BJ: ['229'],
  BM: ['1441'],
  BT: ['975'],
  BO: ['591'],
  BA: ['387'],
  BW: ['267'],
  BR: ['55'],
  VG: ['1284'],
  BN: ['673'],
  BG: ['359'],
  BF: ['226'],
  MM: ['95'],
  BI: ['257'],
  KH: ['855'],
  CM: ['237'],
  CA: ['1'],
  CV: ['238'],
  KY: ['1345'],
  CF: ['236'],
  TD: ['235'],
  CL: ['56'],
  CN: ['86'],
  CX: ['61'],
  CC: ['61'],
  CO: ['57'],
  KM: ['269'],
  CG: ['242'],
  CK: ['682'],
  CR: ['506'],
  HR: ['385'],
  CU: ['53'],
  CY: ['357'],
  CZ: ['420'],
  CD: ['243'],
  DK: ['45'],
  DG: ['246'],
  DJ: ['253'],
  DM: ['1767'],
  DO: ['1809', '1829', '1849'],
  EC: ['593'],
  EG: ['20'],
  SV: ['503'],
  GQ: ['240'],
  ER: ['291'],
  EE: ['372'],
  ET: ['251'],
  FK: ['500'],
  FO: ['298'],
  FJ: ['679'],
  FI: ['358'],
  FR: ['33'],
  GF: ['594'],
  PF: ['689'],
  GA: ['241'],
  GM: ['220'],
  GE: ['995'],
  DE: ['49'],
  GH: ['233'],
  GI: ['350'],
  GR: ['30'],
  GL: ['299'],
  GD: ['1473'],
  GP: ['590'],
  GU: ['1671'],
  GT: ['502'],
  GN: ['224'],
  GW: ['245'],
  GY: ['592'],
  HT: ['509'],
  VA: ['39'],
  HN: ['504'],
  HK: ['852'],
  HU: ['36'],
  IS: ['354'],
  IN: ['91'],
  ID: ['62'],
  IR: ['98'],
  IQ: ['964'],
  IE: ['353'],
  IL: ['972'],
  IT: ['39'],
  CI: ['225'],
  JM: ['1876'],
  JP: ['81'],
  JE: ['44'],
  JO: ['962'],
  KZ: ['7'],
  KE: ['254'],
  KI: ['686'],
  KW: ['965'],
  KG: ['996'],
  LA: ['856'],
  LV: ['371'],
  LB: ['961'],
  LS: ['266'],
  LR: ['231'],
  LY: ['218'],
  LI: ['423'],
  LT: ['370'],
  LU: ['352'],
  MO: ['853'],
  MK: ['389'],
  MG: ['261'],
  MW: ['265'],
  MY: ['60'],
  MV: ['960'],
  ML: ['223'],
  MT: ['356'],
  MH: ['692'],
  MQ: ['596'],
  MR: ['222'],
  MU: ['230'],
  YT: ['262'],
  MX: ['52'],
  FM: ['691'],
  MD: ['373'],
  MC: ['377'],
  MN: ['976'],
  ME: ['382'],
  MS: ['1664'],
  MA: ['212'],
  MZ: ['258'],
  NA: ['264'],
  NR: ['674'],
  NP: ['977'],
  NL: ['31'],
  AN: ['599'],
  NC: ['687'],
  NZ: ['64'],
  NI: ['505'],
  NE: ['227'],
  NG: ['234'],
  NU: ['683'],
  NF: ['672'],
  KP: ['850'],
  MP: ['1670'],
  NO: ['47'],
  OM: ['968'],
  PK: ['92'],
  PW: ['680'],
  PS: ['970'],
  PA: ['507'],
  PG: ['675'],
  PY: ['595'],
  PE: ['51'],
  PH: ['63'],
  PN: ['870'],
  PL: ['48'],
  PT: ['351'],
  PR: ['1787', '1939'],
  QA: ['974'],
  RE: ['262'],
  RO: ['40'],
  RU: ['7'],
  RW: ['250'],
  BL: ['590'],
  SH: ['290'],
  KN: ['1869'],
  LC: ['1758'],
  MF: ['590'],
  PM: ['508'],
  VC: ['1784'],
  WS: ['685'],
  SM: ['378'],
  ST: ['239'],
  SA: ['966'],
  SN: ['221'],
  RS: ['381'],
  SC: ['248'],
  SL: ['232'],
  SG: ['65'],
  SX: ['1721'],
  SK: ['421'],
  SI: ['386'],
  SB: ['677'],
  SO: ['252'],
  ZA: ['27'],
  KR: ['82'],
  SS: ['211'],
  ES: ['34'],
  LK: ['94'],
  SD: ['211'],
  SR: ['597'],
  SJ: ['47'],
  SZ: ['268'],
  SE: ['46'],
  CH: ['41'],
  SY: ['963'],
  TW: ['886'],
  TJ: ['992'],
  TZ: ['255'],
  TH: ['66'],
  TL: ['670'],
  TG: ['228'],
  TK: ['690'],
  TO: ['676'],
  TT: ['1868'],
  TN: ['216'],
  TR: ['90'],
  TM: ['993'],
  TC: ['1649'],
  TV: ['688'],
  UG: ['256'],
  UA: ['380'],
  AE: ['971'],
  GB: ['44'],
  US: ['1'],
  UY: ['598'],
  VI: ['1340'],
  UZ: ['998'],
  VU: ['678'],
  VE: ['58'],
  VN: ['84'],
  WF: ['681'],
  EH: ['212'],
  YE: ['967'],
  ZM: ['260'],
  ZW: ['263'],
};
