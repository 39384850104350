import React from 'react';
import style from './SmallLoadingCircle.module.scss';

function SmallLoadingCircle() {
  return (
    <div className={style['lds-ring']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default SmallLoadingCircle;
