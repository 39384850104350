import React, { useEffect, useState } from 'react';
import style from './e-Readerlogin.module.scss';
import EmailIcon from '../../assets/login.svg';
import PasswordIcon from '../../assets/password.svg';
import { ReactComponent as ConscentLogo } from '../../assets/conscentLogoSmall.svg';
import { Input, Form, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from 'views/Auth/authSlice';
import API from 'api';
import { consts } from 'utils';
import GenericErrorPage from '../../components/GenericErrorPage';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const Login = ({ clientId }: { clientId?: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [email, setEmail] = useState('');

  const getClientLogos = async () => {
    const getClientLogosResponse = await API.client.EReader.GetClientLogos();
    if (API.validators.checkSuccessCode(getClientLogosResponse)) {
      const id = clientId?.split('id=')[1];
      const clientLogo = getClientLogosResponse.data.filter((item: any) => item._id === id);
      if (clientLogo[0]?.iconUrl) setLogoUrl(clientLogo[0].iconUrl);
    }
  };

  useEffect(() => {
    getClientLogos();
  }, []);

  const onFinish = async ({ email, password }: { email: string; password: string }) => {
    const loginResponse = await API.client.EReader.Login({
      email,
      password,
      clientId: clientId?.split('id=')[1] as string,
    });
    if (!API.validators.checkSuccessCode(loginResponse)) return setError(loginResponse.data.message);
    const loginResponseData = {
      ereader_email: email,
      ereader_clientId: loginResponse.data.clientId,
      ereader_name: loginResponse.data.name,
      ereader_status: loginResponse.data.status,
      ereader_token: loginResponse.data.token,
      ereader_memberId: loginResponse.data.id,
      ereader_password: password,
      ereader_isVerified: loginResponse.data.isVerified,
      ereader_isAdmin: loginResponse.data.role === 'admin' ? true : false,
      ereader_clientName: loginResponse.data.clientName,
      ereader_logo: loginResponse.data.logo,
      ereader_domain: loginResponse.data.domain,
    };

    dispatch(
      setAuthDetails({
        ...loginResponseData,
        token: '',
        userType: 'EREADER',
        expiresAt: '',
        version: 0,
      }),
    );
    return history.push(consts.EREADER_HOME_PATH);
  };

  const forgotPassword = async () => {
    if (email !== '') {
      const forgotPasswordResponse = await API.client.EReader.Login({
        email,
        password: '',
        type: 'forgot',
        clientId: clientId?.split('id=')[1] as string,
      });
      if (API.validators.checkSuccessCode(forgotPasswordResponse)) {
        message.success({ content: 'Email Sent!' });
      } else {
        message.error({ content: forgotPasswordResponse?.data?.message });
      }
    } else {
      message.error({ content: 'Please enter email!' });
    }
  };

  return (
    <>
      {logoUrl !== '' ? (
        <div className={style['main-container']}>
          <Form onFinish={onFinish}>
            <div className={style['login-div']}>
              <div className={style['header-logo']}>
                <img src={logoUrl} alt="LOGO" />
              </div>
              <div className={style['main-inputs']}>
                <div className={style['login-text']}>Login</div>
                <div className={style['email-input']}>
                  <div className={style['email-input-icon']}>
                    <img src={EmailIcon} />
                  </div>
                  <div className={style['email-input-field']}>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: 'Please enter the email' },
                        {
                          type: 'email',
                          message: 'Invalid email!',
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Email*" required onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                  </div>
                </div>
                <div className={style['password-input']}>
                  <div className={style['password-input-icon']}>
                    <img src={PasswordIcon} />
                  </div>
                  <div className={style['password-input-field']}>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please enter the password' }]}>
                      <Input.Password
                        type="password"
                        placeholder="Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div>
                <span onClick={forgotPassword} className={style['forgot-password']}>
                  Forgot password?
                </span>
              </div>
              <button className={style['login-button']}>Login</button>
              <p className={style['login-error']}>{error}</p>
              <div className={style['footer']}>
                <span style={{ marginRight: '3.4px' }}>Access with</span>
                <div>
                  <ConscentLogo />
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <GenericErrorPage />
      )}
    </>
  );
};

export default Login;
