const getWordsFromMonths = (months: number) => {
  let yearText = '';
  if (months >= 12) yearText = Math.floor(months / 12) === 1 ? '1 year' : `${Math.floor(months / 12)} years`;

  const monthCount = months % 12;
  let monthText = '';
  if (monthCount) {
    monthText = yearText ? ' & ' : '';
    monthText += `${monthCount} month${monthCount > 1 ? 's' : ''}`;
  }
  const durationText = `${yearText}${monthText}`;

  return durationText;
};

export default getWordsFromMonths;
