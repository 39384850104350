import React, { useContext, useState, useEffect } from 'react';
import { includeRazorpay, deinitializePayments, makePayment } from 'utils/paymentUtility';
import { BundleSubscriptionContext } from '../BundledSubscriptionsContext';
import { currencyType } from 'data/currencies';

const PaymentPage = () => {
  const {
    bundledSubscriptionDetails,
    userDetails,
    payForSubscriptionBundle,
    detectedCountry,
    referenceId,
  } = useContext(BundleSubscriptionContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const addMoneyFunction = async () => {
    const contentDetails = {
      successFrontendUrl:
        window.location.href.split('/bundle-subscriptions')[0].toString() +
        `/bundle-subscriptions?purchaseSubscriptionBundle=true&transactionId={TRANSACTION_ID}`,
      failureFrontendUrl: window.location.href.split('&reviewSubscription')[0].toString() + `&error=true`,
      type: 'SUBSCRIPTION',
      bundle: true,
      referenceId,
      clientId: bundledSubscriptionDetails.clientId,
      selectedSubscriptions: bundledSubscriptionDetails.selectedSubscriptions,
    };

    const checkForWalletBalance = parseFloat(userDetails?.wallet?.balance?.['$numberDecimal']);

    if (checkForWalletBalance && checkForWalletBalance >= bundledSubscriptionDetails.price) {
      await payForSubscriptionBundle({ setLoading, setError, referenceId: referenceId });
    } else {
      setLoading(true);
      makePayment(
        userDetails.wallet.balance
          ? bundledSubscriptionDetails.price - parseFloat(userDetails.wallet.balance['$numberDecimal'])
          : bundledSubscriptionDetails.price,
        bundledSubscriptionDetails.currency as currencyType,
        contentDetails,
        {
          email: userDetails.email || userDetails.secondaryEmail,
          phoneNumber: userDetails.phoneNumber,
        },
        detectedCountry,
        setLoading,
      );
    }
  };
  useEffect(() => {
    if (bundledSubscriptionDetails?.price) {
      includeRazorpay();
      addMoneyFunction();
    }
    return () => {
      deinitializePayments();
    };
  }, [bundledSubscriptionDetails]);

  return (
    <>
      <div>{loading}</div>
      <p style={{ fontSize: '12px', color: 'red' }}>{error}</p>
    </>
  );
};

export default PaymentPage;
