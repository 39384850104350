import { Button } from 'views/UserOverlay/components';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import style from '../login/LoginCustomisation.module.scss';
import { RootState } from 'app/rootReducer';
import API from 'api';
import clsx from 'clsx';
const VerifyOTP = ({
  clientId,
  type,
  data,
  userPhoneNumber,
  userEmail,
  loginType,
  otpConfig,
  submitOTP,
  setError,
  error,
  isNotBorder,
}: {
  clientId: string;
  type: string;
  data: any;
  userPhoneNumber: string;
  userEmail: string;
  loginType: string;
  otpConfig: any;
  submitOTP: any;
  setError: any;
  error: string;
  isNotBorder?: boolean;
}) => {
  const [showResendCode, setShowResendCode] = useState(true);
  const inputRef = useRef(null);
  const [submitOtpBtn, setSubmitOtpBtn] = useState(false);
  const [otp, setOTP] = useState('');
  const [counter, setCounter] = useState(59);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const resendOtp = async () => {
    if (loginType === 'PHONE') {
      const resendOtp = await API.auth.SendOtp({
        phoneNumber: userPhoneNumber as string,
        clientId: clientId as string,
        eventLocation: type + '_OTP',
        anonId: analyticsStore.anonId as string,
      });
      if (!API.validators.checkSuccessCode(resendOtp)) {
        setError(resendOtp.data.message);
      }
    } else {
      const emailCodeResponse = await API.auth.SendVerificationEmail({
        email: userEmail as string,
        eventLocation: type + '_OTP',
        anonId: analyticsStore.anonId as string,
      });
      if (!API.validators.checkSuccessCode(emailCodeResponse)) {
        return setError(emailCodeResponse.data.message);
      }
    }
    setShowResendCode(false);
    startTimerForResend(60);
  };
  let counting = false;
  const startTimerForResend = (count: number) => {
    if (!counting) {
      counting = true;
      count--;
      const timer = setInterval(function () {
        if (count > 0) {
          setCounter(count);
          count--;
        } else {
          setCounter(60);
          clearInterval(timer);
          counting = false;
          setShowResendCode(true);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (otp.length === 4) {
      submitOTP(otp);
      setSubmitOtpBtn(true);
    } else {
      setSubmitOtpBtn(false);
    }
  }, [otp]);
  const onChangeOTP = (e: any) => {
    const { value } = e.target;
    setOTP(value);
    if (value === '') {
      setError('');
    }
  };
  useEffect(() => {
    // @ts-ignore
    inputRef?.current?.focus();
  }, []);

  const submitBtnCustomStyle = {
    width: '272px',
    height: '48px',
    font: 'normal normal 500 16px/33px Montserrat',
  };
  return (
    <>
      <div className={style['OTPContainer']} style={isNotBorder ? { background: 'transparent', border: 'none' } : {}}>
        <div className={style['verifyOTP']} style={{ color: otpConfig?.formTitle?.color }}>
          {otpConfig?.formTitle?.text || 'Verify OTP'}
        </div>
        <div className={style['userMobile']} style={{ color: otpConfig?.subTitle?.color }}>
          {otpConfig?.subTitle?.text || 'A code has been sent to'}{' '}
          {userEmail
            ? userEmail.slice(0, 3) + '******' + userEmail.slice(-3, userEmail.length)
            : '' || userPhoneNumber
            ? userPhoneNumber.slice(0, 2) + '******' + userPhoneNumber.slice(-2, userPhoneNumber.length)
            : ''}
        </div>
        <div className={style['inputOTP']}>
          <input
            ref={inputRef}
            id="1"
            style={{ width: '100px' }}
            type="text"
            maxLength={4}
            onChange={(e) => onChangeOTP(e)}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{error && <>{error}</>}</div>

        {
          <div className={style['OtpTimer']}>
            <div className={style['request-text']}>
              {showResendCode ? (
                <div className={style['otp-request']}>
                  Didn’t receive OTP?
                  <br />{' '}
                  <span
                    className={style['cursor-blue-link']}
                    style={{ color: data.buttonEnableColor }}
                    onClick={resendOtp}
                    id="sel-get-OTP"
                  >
                    <b>{otpConfig?.timerText?.text ? otpConfig?.timerText?.text : 'Resent OTP'}</b>
                  </span>
                </div>
              ) : (
                <div id="sel-otp-count">You can request another OTP in {counter} seconds</div>
              )}
            </div>
          </div>
        }
        {
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className={clsx(style[submitOtpBtn && !error ? 'submit-bar-animated' : ''])}>
              <Button
                customStyle={{
                  ...submitBtnCustomStyle,
                  backgroundColor: otpConfig?.button?.buttonColor,
                  color: otpConfig?.button?.color,
                }}
                customMobileStyle={{
                  width: '300px',
                }}
              >
                {otpConfig?.button?.text || 'Submit'}
              </Button>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default VerifyOTP;
