import { antdMessageError } from './antdMessageComponent';
export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const ValidateMobileNumber = (phoneNubmer: string) => {
  const re = /^[6-9][0-9]{9}$/;
  return re.test(phoneNubmer);
};

export const redirectUrlValidator = (redirectUrl: string) => {
  try {
    new URL(redirectUrl);
    return true;
  } catch (_) {
    antdMessageError('Invalid redirect URL');
    return false;
  }
};
