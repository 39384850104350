import API from 'api';
import { logError } from '.';
import { currencyType } from '../data/currencies';
import { minAmountForCurrencies } from '../data/minimumAmountForCurrencies';
import { getCurrencySymbol } from '../data/getCurrencySymbol';

const API_URL = process.env.REACT_APP_API_URL;

let paytmScript: HTMLScriptElement;
let razorpayScript: HTMLScriptElement;

export function includePaytm() {
  function onPaytmScriptLoad() {
    // @ts-ignore
    if (window.Paytm && window.Paytm.CheckoutJS) {
      // @ts-ignore
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        console.log('Paytm Window Invoked');
      });
    }
  }

  const paytmScript = document.createElement('script');
  paytmScript.src = process.env.REACT_APP_PAYTM_URL || '';
  paytmScript.crossOrigin = 'anonymous';
  paytmScript.type = 'application/javascript';
  paytmScript.onload = onPaytmScriptLoad;
  paytmScript.async = true;
  document.body.appendChild(paytmScript);
  return paytmScript;
}

export function includeRazorpay() {
  const rzpScript = document.createElement('script');
  rzpScript.src = 'https://checkout.razorpay.com/v1/checkout.js';
  rzpScript.crossOrigin = 'anonymous';
  rzpScript.type = 'application/javascript';
  rzpScript.async = true;
  document.body.appendChild(rzpScript);
  return rzpScript;
}

export const initializePayments = async (currency: currencyType, detectedCountry?: string, autoRenew = false) => {
  if (!detectedCountry) {
    const currencyAndCountryResponse = await API.auth.DetectCountry();
    if (!API.validators.checkSuccessCode(currencyAndCountryResponse)) {
      logError({ error: 'Unable to detect Country' });
      // if unable to detect country, continue with Rzp as it works everywhere
    } else detectedCountry = currencyAndCountryResponse.data.countryCode;
  }

  if (currency === 'INR' && detectedCountry === 'IN' && !autoRenew) {
    paytmScript = includePaytm();
  } else {
    razorpayScript = includeRazorpay();
  }
};

export const deinitializePayments = async () => {
  if (paytmScript) document.body.removeChild(paytmScript);
  if (razorpayScript) document.body.removeChild(razorpayScript);
};

export const makePaytmPayment = async (
  orderId: string,
  txnToken: string,
  amount: number,
  setLoading?: (b: boolean) => any,
) => {
  //@ts-ignore
  const Paytm = window.Paytm;
  const config = {
    root: '',
    flow: 'DEFAULT',
    data: {
      orderId: orderId,
      token: txnToken,
      tokenType: 'TXN_TOKEN',
      amount: amount,
    },
    merchant: {
      name: 'Your Gateway to Premium Content',
    },
    handler: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      notifyMerchant: function (eventName: any, data: any) {
        // console.log('notifyMerchant handler function called');
        // console.log('eventName => ', eventName);
        // console.log('data => ', data);
      },
    },
  };
  Paytm.CheckoutJS.init(config)
    .then(function onSuccess() {
      setLoading?.(false);
      // after successfully updating configuration, invoke JS Checkout
      Paytm.CheckoutJS.invoke();
    })
    .catch(function onError(error: any) {
      console.log('error => ', error);
    });
};

export const makeRzpPayment = async (
  orderId: string,
  primaryColor?: string,
  brandLogoUrl?: string,
  phoneNumber?: string,
  email?: string,
  rzrPayCrossBtn?: any,
  setLoading?: (b: boolean) => any,
) => {
  const options = {
    key: process.env.RAZORPAY_KEY,
    name: 'Transaction Value',
    // description: 'Add money to your ConsCent wallet',
    image: brandLogoUrl || 'https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png',
    order_id: orderId,
    prefill: {
      contact: phoneNumber,
      email: email,
    },
    redirect: true,
    callback_url: `${API_URL}/payment/rzp-complete-redirect`,
    theme: {
      color: primaryColor || '#1a92b4',
    },
    modal: {
      confirm_close: false,
      ondismiss: function () {
        rzrPayCrossBtn();
      },
    },
  };

  // @ts-ignore
  const rzp = new window.Razorpay(options);
  setLoading?.(false);
  rzp.open();
};

export const makePayment = async (
  amount: number,
  currency: currencyType,
  contentDetails: { successFrontendUrl: string; failureFrontendUrl: string } & Record<string, any>,
  userDetails: { phoneNumber: string; email: string },
  detectedCountry: string,
  setLoading?: (b: boolean) => any,
  rzrPayCrossBtn?: any,
) => {
  amount = amount >= minAmountForCurrencies[currency] ? amount : minAmountForCurrencies[currency];
  // if (detectedCountry === 'IN' && currency === 'INR') {
  //   // TODO: add fb pixel event
  //   const createPaymentResponse = await API.user.payment.createPayment({
  //     amount: Math.ceil(amount * 100) / 100,
  //     contentDetails,
  //     currency: currency,
  //   });

  //   const createPaymentData = createPaymentResponse.data as { orderId: string; txnToken: string; amount: number };
  //   if (!createPaymentData.orderId) {
  //     setLoading?.(false);
  //     throw 'Failed to place order, try again later';
  //   }

  //   makePaytmPayment(
  //     createPaymentData.orderId,
  //     createPaymentData.txnToken as string,
  //     createPaymentData.amount as number,
  //     setLoading,
  //   );
  // } else {
  // TODO: add fb pixel event
  const createRzpPaymentResponse = await API.user.payment.createRzpPayment({
    amount: Math.ceil(amount * 100) / 100,
    contentDetails: contentDetails,
    currency: currency,
  });
  if (!API.validators.checkSuccessCode(createRzpPaymentResponse)) {
    setLoading?.(false);
    throw createRzpPaymentResponse.data.message;
  }

  const createRzpPaymentData = createRzpPaymentResponse.data as {
    orderId: string;
    primaryColor?: string;
    brandLogoUrl?: string;
  };

  if (!createRzpPaymentData.orderId) {
    setLoading?.(false);
    throw 'Failed to place order, try again later';
  }
  makeRzpPayment(
    createRzpPaymentData.orderId,
    createRzpPaymentData.primaryColor,
    createRzpPaymentData.brandLogoUrl,
    userDetails.phoneNumber as string,
    userDetails.email as string,
    rzrPayCrossBtn,
    setLoading,
  );
  // }
};

export function validateCurrencyAmount({ amount, currency }: { amount: number; currency: string }) {
  let error = '';
  const currencySymbol = getCurrencySymbol(currency);
  //@ts-ignore
  const minAmount = minAmountForCurrencies[currency];
  if (amount < minAmount) {
    error = `Minimum amount must be atleast ${currencySymbol} ${minAmount}`;
  }
  const maxAmount = minAmount > 1 ? minAmount * 25000 : minAmount * 10000;
  if (amount > maxAmount) {
    error = `Maximum amount must not be greater than ${currencySymbol} ${maxAmount}`;
  }
  return error;
}
