import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface AuthDetails {
  phoneNumber?: string; // phone number is used by the user
  userId?: string;
  token: string;
  email?: string;
  userType: 'USER' | 'CLIENT' | 'ADMIN' | 'EREADER';
  clientId?: string;
  clientName?: string;
  sessionId?: string; // sessionId is used by the user
  expiresAt: string;
  version: number;
  clientDetails?: { clientId: string; token: string; name: string; image: string }[];
  ereader_email?: string;
  ereader_token?: string;
  ereader_clientId?: string;
  ereader_status?: string;
  ereader_name?: string;
  ereader_memberId?: string;
  ereader_password?: string;
  ereader_isVerified?: boolean;
  ereader_isAdmin?: boolean;
  ereader_clientName?: string;
  ereader_logo?: string;
  ereader_domain?: string;
}

const initialState: AuthDetails = {
  phoneNumber: '',
  userId: '',
  token: '',
  userType: 'USER',
  email: '',
  clientId: '',
  expiresAt: '',
  version: 0,
  ereader_email: '',
  ereader_token: '',
  ereader_clientId: '',
  ereader_status: '',
  ereader_name: '',
  ereader_memberId: '',
  ereader_password: '',
  ereader_isVerified: false,
  ereader_isAdmin: false,
  ereader_clientName: '',
  ereader_logo: '',
  ereader_domain: '',
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthDetails(state, action: PayloadAction<AuthDetails>) {
      state.phoneNumber = action.payload.phoneNumber;
      state.userId = action.payload.userId;
      state.token = action.payload.token;
      state.userType = action.payload.userType;
      state.clientId = action.payload.clientId;
      state.clientName = action.payload.clientName;
      state.email = action.payload.email;
      state.expiresAt = action.payload.expiresAt;
      state.sessionId = action.payload.sessionId || '';
      state.version = action.payload.version;
      state.clientDetails = action.payload.clientDetails;
      state.ereader_clientId = action.payload.ereader_clientId;
      state.ereader_email = action.payload.ereader_email;
      state.ereader_status = action.payload.ereader_status;
      state.ereader_token = action.payload.ereader_token;
      state.ereader_name = action.payload.ereader_name;
      state.ereader_memberId = action.payload.ereader_memberId;
      state.ereader_password = action.payload.ereader_password;
      state.ereader_isVerified = action.payload.ereader_isVerified;
      state.ereader_isAdmin = action.payload.ereader_isAdmin;
      state.ereader_clientName = action.payload.ereader_clientName;
      state.ereader_logo = action.payload.ereader_logo;
      state.ereader_domain = action.payload.ereader_domain;
    },
    clearAuthDetails(state) {
      state.phoneNumber = '';
      state.token = '';
      state.userId = '';
      state.userType = 'USER';
      state.clientId = '';
      state.clientName = '';
      state.email = '';
      state.sessionId = '';
      state.expiresAt = '';
      state.version = 0;
      state.clientDetails = [];
      state.ereader_clientId = '';
      state.ereader_email = '';
      state.ereader_status = '';
      state.ereader_token = '';
      state.ereader_name = '';
      state.ereader_memberId = '';
      state.ereader_password = '';
      state.ereader_isVerified = false;
      state.ereader_isAdmin = false;
      state.ereader_clientName = '';
      state.ereader_logo = '';
      state.ereader_domain = '';
    },
  },
});

export const { setAuthDetails, clearAuthDetails } = auth.actions;

export default auth.reducer;
