import API from 'api';
import { RootState } from 'app/rootReducer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { autoLoginMobileMessageHandler } from 'utils/notifyAppToRedirect';
import { setAuthDetails } from 'views/Auth/authSlice';
import { postMessage } from 'views/UserOverlay/paywallUtils';
import LoadingCircle from 'views/UserOverlay/components/loaders/LoadingCircle';
function AutoLogin() {
  const authStore = useSelector((store: RootState) => store.auth);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('id') || '';
  const phoneNumber = searchParams.get('phone') || '';
  const email = searchParams.get('email') || '';
  const clientId = searchParams.get('clientId') || '';
  const loginChallengeId = searchParams.get('loginChallengeId') || '';
  const dispatch = useDispatch();

  const inititateUserLogin = async () => {
    const loginDetails = await API.auth.AutoLogin({ token, phoneNumber, email, clientId, loginChallengeId });

    if (!API.validators.checkSuccessCode(loginDetails)) {
      postMessage.AutoLoginError();
      autoLoginMobileMessageHandler(JSON.stringify({ status: false, message: 'login failed' }));
    } else {
      const { token, email, userId, sessionId, expiresAt, version } = loginDetails.data as any;
      dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, userType: 'USER', version }));
      postMessage.LOGGEDIN(true, sessionId);
      autoLoginMobileMessageHandler(
        JSON.stringify({ status: true, message: 'login successfully', token, email, userId, sessionId }),
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (!authStore.token) {
        return inititateUserLogin();
      }

      let sessionExists = !!authStore.sessionId;

      const validateSessionResponse = await API.user.validateSession(authStore.sessionId || '');
      if (!API.validators.checkSuccessCode(validateSessionResponse)) {
        sessionExists = false;
      } else {
        sessionExists = !!validateSessionResponse.data.sessionExists;
      }

      if (!sessionExists) {
        return inititateUserLogin();
      } else {
        postMessage.LOGGEDIN(true, authStore.sessionId);
        autoLoginMobileMessageHandler(
          JSON.stringify({
            status: true,
            message: 'login successfully',
            token,
            email: authStore.email,
            userId: authStore.userId,
            sessionId: authStore.sessionId,
          }),
        );
      }
    })();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingCircle />;
    </div>
  );
}

export default AutoLogin;
