import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppConfig {
  isMobile: boolean;
  isTabletOrMobile: boolean;
  subdomainType: 'CLIENT' | 'ADMIN' | 'USER' | 'EREADER' | '';
}

const initialState: AppConfig = {
  isMobile: false,
  isTabletOrMobile: false,
  subdomainType: '',
};

const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigDetails(
      state,
      action: PayloadAction<{
        isMobile?: boolean;
        isTabletOrMobile?: boolean;
        subdomainType?: AppConfig['subdomainType'];
      }>,
    ) {
      if (_.includes(Object.keys(action.payload), 'isMobile')) {
        state.isMobile = !!action.payload.isMobile;
      }
      if (_.includes(Object.keys(action.payload), 'isTabletOrMobile')) {
        state.isTabletOrMobile = !!action.payload.isTabletOrMobile;
      }
      if (action.payload.subdomainType) {
        state.subdomainType = action.payload.subdomainType;
      }
    },
  },
});

export const { setConfigDetails } = config.actions;

export default config.reducer;
