import React, { useContext, useEffect, useState } from 'react';
import style from './alreadyPurchased.module.scss';
import { Checkbox, message } from 'antd';
import API from 'api';
import { getCurrencySymbol } from '../../../data/getCurrencySymbol';
import { initializePayments, makePayment } from 'utils/paymentUtility';
import Button from 'components/CustomButton';
import SubscriptionFooter from './SubscriptionFooter';
import { SubscriptionContext } from '../SubscriptionContext';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { currencyType } from 'data/currencies';
import { loginChallengeCheck, redirectToContent } from './SubsUtils';
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

export const AlreadyPurchased = () => {
  const {
    purchasedSubscriptionDetails,
    subscriptionPageParams,
    viewId,
    getUtmParameters,
    userDetails,
    detectedCountry,
    defaultContentAction,
    selectedTier,
    allowRenewal,
    internalUser,
  } = useContext(SubscriptionContext);
  const [redirectUrl, setRedirectUrl] = useState('');
  const authStore = useSelector((store: RootState) => store.auth);
  const [renewalTier, setRenewalTier] = useState({ price: 0, currency: 'INR', duration: 0, offers: [], _id: '' });
  const [purchasedTier, setPurchasedTier] = useState({ currency: 'INR', price: 0 });
  const [autoRenew, setAutoRenew] = useState(true);
  useEffect(() => {
    (async function () {
      loginChallengeCheck(authStore.sessionId || '');
      const clientDetails = await API.client.GetSubscriptionSettings({ clientId: subscriptionPageParams.clientId });
      if (clientDetails.status === 200) {
        setRedirectUrl(clientDetails.data?.subscriptionDetails?.redirectUrl);
      }
      if (purchasedSubscriptionDetails?.subscription?.tierId && purchasedSubscriptionDetails.subscriptionDetails) {
        const selectedTier = purchasedSubscriptionDetails?.subscriptionDetails?.tiers?.find(
          (el: any) => el._id.toString() === purchasedSubscriptionDetails?.subscription?.tierId.toString(),
        );
        setRenewalTier(selectedTier);
        setPurchasedTier(selectedTier);
      }
    })();
  }, []);

  useEffect(() => {
    initializePayments(userDetails.wallet.currency as currencyType, detectedCountry, autoRenew);
  }, [autoRenew]);

  const _redirectToContent = async () => {
    return redirectToContent({
      clientId: subscriptionPageParams.clientId,
      redirectUrl,
      sessionId: authStore.sessionId || '',
      clientContentId: subscriptionPageParams.clientContentId,
    });
  };

  const renewSubscription = async () => {
    const contentDetails = {
      successFrontendUrl:
        window.location.href.split('/subscription')[0].toString() +
        `/subscription?purchaseSubscription=true&transactionId={TRANSACTION_ID}&viewId=${viewId}`,
      failureFrontendUrl: window.location.href.split('&reviewSubscription')[0].toString() + `&error=true`,
      subscriptionId: purchasedSubscriptionDetails.subscriptionDetails?.['_id'],
      tierId: purchasedSubscriptionDetails.subscription.tierId,
      amount: renewalTier.price,
      currency: userDetails.wallet.currency,
      type: 'SUBSCRIPTION',
      clientId: purchasedSubscriptionDetails?.subscriptionDetails?.clientId,
      offerIds: renewalTier?.offers,
      utmParameters: getUtmParameters(),
      renewSubscription: autoRenew,
      manuallyRenewed: true,
    };

    if (autoRenew) {
      const authorizeSubscriptionRenewalResponse = await API.user.AuthorizeRenewal({
        price: Math.ceil(renewalTier.price * 100) / 100,
        currency: renewalTier.currency,
        tierId: renewalTier._id,
        subscriptionId: purchasedSubscriptionDetails.subscriptionDetails?.['_id'],
        duration: renewalTier.duration,
        contentDetails: contentDetails,
      });
      if (!API.validators.checkSuccessCode(authorizeSubscriptionRenewalResponse)) {
        return message.error({
          content: 'Failed to authorize razorpay renewal',
          style: {
            marginTop: '130px',
          },
        });
      }
      const rzpSubscriptionId = authorizeSubscriptionRenewalResponse.data.rzpSubscriptionId;
      const options = {
        key: process.env.RAZORPAY_KEY,
        subscription_id: rzpSubscriptionId,
        name: 'Transaction Value',
        // description: 'Purchase Subscription',
        image: 'https://conscent-static-assets.s3.ap-south-1.amazonaws.com/assets/conscent+logo.png',
        prefill: {
          contact: userDetails.phoneNumber,
          email: userDetails.email || userDetails.secondaryEmail,
        },
        redirect: true,
        callback_url: `${API_URL}/payment/rzp-subscription-renewal?transactionId=${authorizeSubscriptionRenewalResponse.data.transactionId}`,
        theme: {
          color: '#1a92b4',
        },
      };
      // @ts-ignore
      const rzp = new window.Razorpay(options);
      rzp.open();
    } else if (
      userDetails.wallet.balance &&
      parseFloat(userDetails.wallet.balance['$numberDecimal']) >= renewalTier.price
    ) {
      await API.user.PurchaseSubscription({
        clientId: purchasedSubscriptionDetails?.subscriptionDetails?.clientId,
        price: renewalTier.price,
        currency: userDetails.wallet.currency,
        offerIds: renewalTier.offers,
        tierId: renewalTier._id,
        subscriptionId: purchasedSubscriptionDetails.subscriptionDetails?.['_id'],
        utmParameters: getUtmParameters(),
        manuallyRenewed: true,
        viewId,
      });
    } else {
      makePayment(
        userDetails.wallet.balance
          ? renewalTier.price - parseFloat(userDetails.wallet.balance['$numberDecimal'])
          : renewalTier.price,
        userDetails.wallet.currency as currencyType,
        contentDetails,
        {
          email: userDetails.email || userDetails.secondaryEmail,
          phoneNumber: userDetails.phoneNumber,
        },
        detectedCountry,
      );
    }
  };

  if (internalUser)
    return (
      <div className={style['container']}>
        <div className={style['container-wrapper']}>
          <p className={style['heading']}>
            You are an internal user, you have free access to digital content and cannot purchase subscriptions{' '}
          </p>
        </div>
        <div>
          <Button
            customStyle={{ width: '227px', height: '46px', background: '#b10f15', marginRight: '0px' }}
            onClick={() => _redirectToContent()}
          >
            Continue {defaultContentAction}
          </Button>
        </div>
        <SubscriptionFooter />
      </div>
    );

  return (
    <div className={style['container']}>
      <div className={style['container-wrapper']}>
        <p className={style['heading']}>Your subscription is already active. Here are the details: </p>
        <div className={style['middle-text']}>
          <p>Purchased on:</p>
          <span className={style['date']}>{purchasedSubscriptionDetails.purchasedDate}</span>
        </div>

        <div className={style['middle-text']}>
          <p>Valid Till:</p>
          <span className={style['date']}>{purchasedSubscriptionDetails.validity}</span>
        </div>
        <div className={style['color-box']}>
          <p className={style['color-box-heading']}>
            {purchasedSubscriptionDetails.subscription.digital
              ? purchasedSubscriptionDetails.subscription.physical
                ? 'Digital + Physical '
                : 'Digital '
              : purchasedSubscriptionDetails.subscription.physical
              ? 'Physical '
              : ''}{' '}
            {purchasedSubscriptionDetails.subscription.title}
          </p>
          <p className={style['small-text']}>
            {purchasedSubscriptionDetails.subscription.durationText}
            {purchasedSubscriptionDetails.subscription.digital
              ? purchasedSubscriptionDetails.subscription.physical
                ? 'digital and physical '
                : 'digital '
              : purchasedSubscriptionDetails.subscription.physical
              ? 'physical '
              : ' '}
            access
            {purchasedSubscriptionDetails.priceDetails.amount > 0
              ? ` for ${getCurrencySymbol(purchasedSubscriptionDetails.priceDetails.currency) as string} ${
                  purchasedSubscriptionDetails.priceDetails.amount
                }`
              : ' as Free Trail'}
          </p>
          {purchasedSubscriptionDetails.subscription.renew && (
            <div className={style['inline-item-check']}>
              &#10004;
              <p>
                Renews automatically every{' '}
                {purchasedSubscriptionDetails.subscription.duration +
                  ` month${purchasedSubscriptionDetails.subscription.duration > 1 ? 's ' : ' '} `}{' '}
                for {(getCurrencySymbol(purchasedTier?.currency) as string) + purchasedTier?.price}
              </p>
            </div>
          )}

          <p className={style['offer-text']}>
            {purchasedSubscriptionDetails.offerDetails.length > 0 && 'Offers included'}
          </p>
          {purchasedSubscriptionDetails.offerDetails.map((offer, id) => {
            return (
              <div key={id} className={style['inline-item']}>
                <img src={offer.iconUrl} alt="offer" />
                <h3 style={{ marginTop: '12px', marginLeft: '22px', marginRight: '10px' }}>{offer.title}</h3>
                <p>{offer.benefits}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Link to={`/consumption?subscription=true`} style={{ padding: '10px', background: '#b10f15', color: 'white' }}>
          Renew Subscription
        </Link>

        <div onClick={() => _redirectToContent()} className={style['continue-text']}>
          Continue {defaultContentAction}
        </div>
      </div>
      {allowRenewal && (
        <div>
          <Button
            customStyle={{ width: '227px', height: '46px', background: '#b10f15', marginRight: '0px' }}
            onClick={() => renewSubscription()}
          >
            Renew Subscription
          </Button>
          <div>
            <Checkbox
              style={{ marginTop: '10px' }}
              checked={autoRenew}
              onChange={() => {
                setAutoRenew(!autoRenew);
              }}
            >
              Auto Renew Subscription
            </Checkbox>
          </div>
        </div>
      )}
      <SubscriptionFooter />
    </div>
  );
};
