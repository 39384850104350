export const hotJar = () => {
  (function (h, o, t, j, a, r) {
    const argument = { '0': h, '1': o, '2': t, '3': j, '4': a, '5': r };
    //@ts-ignore
    h.hj =h.hj ||function () {(h.hj.q = h.hj.q || []).push(argument);};
    //@ts-ignore
    h._hjSettings = { hjid: 3085305, hjsv: 6 };
    //@ts-ignore
    a = o.getElementsByTagName('head')[0];
    //@ts-ignore
    r = o.createElement('script');r.async = 1;
    //@ts-ignore
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    //@ts-ignore
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};
