import React, { useEffect, useContext } from 'react';
import clsx from 'clsx';
import style from './transactionCheck.module.scss';
import { OverlayContext } from '../../OverlayContext';
import { fbPixelConfig } from '../../../../utils/fbPixelConfig';
import payment_success from 'assets/payment_success.svg';

export default function TransactionCheck(props: {
  title?: string;
  bottomText?: string;
  midText?: string;
  progressBar?: boolean;
  useFixedHeight?: boolean;
}): JSX.Element {
  const { clientDetails, userDetails, purchaseMode } = useContext(OverlayContext);
  useEffect(() => {
    fbPixelConfig('Purchase', {
      currency: `INR`,
      value: userDetails.contentPrice,
      status: true,
      content_ids: clientDetails.clientContentId,
    });
  }, []);
  return (
    <div
      className={style['container']}
      style={{ height: props.useFixedHeight ? '540px' : '', padding: props.useFixedHeight ? '100px 32px' : '' }}
    >
      <img src={payment_success} alt={payment_success} />
      <p className={style['large-text']}>{props.title || 'Transaction Complete'}</p>
      <p className={style['small-text']}>
        {props.midText ||
          `${{ PASS: 'Pass', SUBSCRIPTION: 'Subscription', CONTENT: 'Premium Content' }[purchaseMode]} Purchased`}
      </p>
      {props.progressBar && (
        <div className={style['progress-container']}>
          <div className={clsx(style['progress'], style['progress-striped'])}>
            <div className={style['progress-bar']}></div>
          </div>
        </div>
      )}

      <p className={style['middle-text']}>{props.bottomText || 'Enjoy!'}</p>
    </div>
  );
}
