import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from 'views/Auth/authSlice';
import API from 'api';
import { phoneLogin, emailLogin } from 'views/Auth/authUtils';
import { BlueButton } from 'components/Button/BlueButton';
import { validate } from 'views/UserOverlay/Validator';
import FloatInputBox from 'components/InputBox/FloatInputBox';
import style from 'views/Donation/views/login.module.scss';
import loginStyle from 'views/UserOverlay/views/loginAndVerify.module.scss';
import subscriptionStyle from '../../Subscription/views/subscriptionLogin.module.scss';
import { TandCComponent } from 'components/user/LoginUI';
import emailIcon from 'assets/email_icon.svg';
import phoneIcon from 'assets/phone_icon.svg';
import { Footer } from 'views/UserOverlay/components';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { BundleSubscriptionContext, SUBSCRIPTION_BUNDLE_ROUTES } from '../BundledSubscriptionsContext';

const commonBlueBtnStyle = {
  borderRadius: '6px',
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
  width: '100%',
  height: '46px',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '15px',
  border: 'none',
};

const BundledSubscriptionLogin = () => {
  const dispatch = useDispatch();
  const [acceptTOS, setAcceptTOS] = useState(true);
  const [showVerification, setShowVerification] = useState(false);
  const [counter, setCounter] = useState(60);
  const [showResend, setShowResend] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [otp, setOTP] = useState('');
  const [promotionalChecked, setPromotionalChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    bundledSubscriptionDetails,
    initializeSubscriptionsBundle,
    setRoute,
    viewId,
    updateView,
    tempPhoneNumber,
    tempEmailId,
    countryCode,
    detectedCountry,
  } = useContext(BundleSubscriptionContext);
  const authStore = useSelector((store: RootState) => store.auth);
  const [loginType, setLoginType] = useState<'PHONE' | 'EMAIL'>(
    countryCode || detectedCountry !== 'IN' ? 'EMAIL' : 'PHONE',
  );
  useEffect(() => {
    (async () => {
      const viewDetailsResponse = await API.content.GetViewDetails({ viewId: viewId as string });
      const defaultEmail = viewDetailsResponse?.data?.view?.userData?.defaultEmail;
      const defaultPhoneNumber = viewDetailsResponse?.data?.view?.userData?.defaultPhone;
      if (defaultEmail) {
        setEmail(defaultEmail);
        sendOtp({ defaultEmail: defaultEmail, loginType: 'EMAIL' });
      }
      if (defaultPhoneNumber) {
        if (!defaultEmail) setLoginType('PHONE');
        setPhoneNumber(defaultPhoneNumber);
        sendOtp({ defaultPhone: defaultPhoneNumber, loginType: 'PHONE' });
      }

      if (tempPhoneNumber) {
        if (!defaultEmail) setLoginType('PHONE');
        setPhoneNumber(tempPhoneNumber);
      }
      if (countryCode || detectedCountry !== 'IN') setLoginType('EMAIL');
      if (tempEmailId) {
        setEmail(tempEmailId);
      }
    })();
  }, []);

  useEffect(() => {
    if (authStore.token) {
      if (authStore.userType === 'USER') initializeSubscriptionsBundle();
    }
  }, [authStore.token]);

  const sendOtp = async ({
    loginType,
    defaultPhone,
    defaultEmail,
  }: {
    loginType: string;
    defaultPhone?: string;
    defaultEmail?: string;
  }) => {
    try {
      if (
        defaultEmail ||
        defaultPhone ||
        (loginType === 'PHONE' && phoneNumberValid) ||
        (loginType === 'EMAIL' && emailValid)
      ) {
        setLoading(true);
        let userAccountId;
        if (loginType === 'PHONE') {
          const { userId } = await phoneLogin({
            phoneNumber: defaultPhone || phoneNumber,
            clientId: bundledSubscriptionDetails.clientId,
          });
          userAccountId = userId;
          updateView({ action: 'LOGIN', userId: userAccountId });
        } else {
          const { userId } = await emailLogin({
            email: defaultEmail || email,
            clientId: bundledSubscriptionDetails._id,
          });
          userAccountId = userId;
          updateView({ action: 'LOGIN', userId: userAccountId });
        }
        updateView({ action: 'OTP', userId: userAccountId });
        setLoading(false);
        setShowVerification(true);
      }
    } catch (err: any) {
      setLoading(false);
      setError(err.message || 'Please try again later');
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    sendOtp({ loginType });
  };

  const OnInput = (e: any) => {
    let { value } = e.target;
    setError('');
    if (loginType === 'PHONE') {
      if (!value.includes('-')) value = `+91-${value.slice(4)}`;
      const parsedPhoneNumber = value.split('-')?.[1]?.slice(0, 10) || '';
      setPhoneNumber(parsedPhoneNumber || '');
      if (value.length && !validate(parsedPhoneNumber, { pattern: /^\d+$/ })) setError('Invalid phone number entered');
      else if (parsedPhoneNumber.length === 10 && !validate(parsedPhoneNumber, { pattern: /^[6-9]\d{9}$/ }))
        setError('Invalid phone number entered');
    } else {
      setEmail(value);
    }
  };

  const phoneNumberValid = validate(phoneNumber, { pattern: /^\d{10}$/ }) && !error;
  const emailRegex = /\S+@\S+\.\S+/;
  const emailValid = validate(email, { pattern: emailRegex }) && !error;

  const onChangeOTP = (e: any) => {
    const { value } = e.target;
    if (value.length && !validate(value, { pattern: /^\d+$/ })) setError('OTP must be 4 digits');
    else if (error) setError('');
    setOTP(value);
  };

  let counting = false;
  const startTimerForResend = (count: number) => {
    if (!counting) {
      counting = true;
      count--;
      const timer = setInterval(function () {
        if (count > 0) {
          setCounter(count);
          count--;
        } else {
          setCounter(60);
          clearInterval(timer);
          counting = false;
          setShowResend(true);
        }
      }, 1000);
    }
  };

  const resetLogin = () => {
    setShowVerification(false);
  };

  async function resendOtpSubmit() {
    setError('');
    let resendOtp;
    if (loginType === 'PHONE') {
      resendOtp = await API.auth.SendOtp({
        phoneNumber,
        clientId: bundledSubscriptionDetails._id,
      });
      if (!API.validators.checkSuccessCode(resendOtp)) {
        setError(resendOtp.data.message);
      }
    } else if (loginType === 'EMAIL') {
      const emailCodeResponse = await API.auth.SendVerificationEmail({
        email,
        clientId: bundledSubscriptionDetails._id,
      });
      if (!API.validators.checkSuccessCode(emailCodeResponse)) {
        return setError(emailCodeResponse.data.message);
      }
    }

    const resendOtpData = resendOtp?.data as { message: string };
    if (resendOtpData.message === 'otp sent') {
      setShowResend(false);
      startTimerForResend(60);
    }
  }

  const submitOTP = async () => {
    setLoading(true);
    setError('');
    const loginResponse = await API.auth.LoginOtp({
      email: loginType === 'EMAIL' ? email : undefined,
      phoneNumber: loginType === 'PHONE' ? phoneNumber : undefined,
      OTP: otp,
      promotionalOptIn: promotionalChecked,
      clientId: bundledSubscriptionDetails._id,
    });
    if (API.validators.checkSuccessCode(loginResponse)) {
      const { token, email, userId, sessionId, expiresAt, version, phoneNumber } = loginResponse.data as any;
      dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, phoneNumber, userType: 'USER', version }));
      return setRoute(SUBSCRIPTION_BUNDLE_ROUTES.REVIEW_SUBSCRIPTION);
    } else {
      setError(loginResponse.data.message);
    }
    setLoading(false);
  };

  const otpValid = otp.length === 4;

  useEffect(() => {
    if (otpValid && !error) {
      submitOTP();
    }
  }, [otp]);

  return (
    <div className={subscriptionStyle['container']}>
      <div className={subscriptionStyle['content-section']}>
        <form onSubmit={onSubmit}>
          <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
            {!showVerification && (
              <>
                <div className={subscriptionStyle['inp-wrap']}>
                  <FloatInputBox
                    Label={loginType === 'EMAIL' ? 'Email Address' : 'Phone Number'}
                    type={loginType === 'PHONE' ? 'tel' : 'text'}
                    value={loginType === 'PHONE' ? `+91-${phoneNumber}` : email}
                    OnInput={(e) => OnInput(e)}
                    autoFocus={true}
                  />
                  <p className={loginStyle['inp-below-text']}>
                    ConsCent user? Use {loginType === 'PHONE' ? 'same phone number' : 'same email'}
                  </p>
                </div>

                <div style={{ fontSize: 14, marginTop: '16px' }}>
                  <TandCComponent setChecked={setAcceptTOS} checked={acceptTOS} />
                </div>

                <BlueButton
                  customStyle={commonBlueBtnStyle}
                  disabled={!acceptTOS || (loginType === 'PHONE' ? !phoneNumberValid : !emailValid)}
                >
                  Verify
                </BlueButton>

                {!countryCode && detectedCountry === 'IN' && (
                  <>
                    <div className={subscriptionStyle['vertical-line']}>
                      <div className={subscriptionStyle['or-write']}>OR</div>
                    </div>

                    <p style={{ color: '#ef2222' }}>{error}</p>
                    <BlueButton
                      type="button"
                      onClick={() => {
                        setLoginType(loginType === 'PHONE' ? 'EMAIL' : 'PHONE');
                      }}
                      customStyle={commonBlueBtnStyle}
                    >
                      <img
                        src={loginType === 'PHONE' ? emailIcon : phoneIcon}
                        alt="emailIcon"
                        style={{ marginRight: '16px' }}
                      />
                      Login with {loginType === 'PHONE' ? 'email' : 'phone'}
                    </BlueButton>
                  </>
                )}
              </>
            )}

            {showVerification && (
              <>
                <div className={style['heading']}>Enter OTP to Verify</div>
                <div className={style['sub-heading']} style={{ marginTop: 12 }}>
                  An OTP has been sent to{' '}
                  <span style={{ opacity: '1', color: '#000', fontWeight: 'bold' }}>
                    {loginType === 'PHONE' && phoneNumber}
                  </span>
                </div>
                <div className={style['sub-heading']} style={{ opacity: '1', fontWeight: 'bold' }}>
                  {loginType === 'EMAIL' && email}
                </div>
                <div style={{ marginTop: 5, fontSize: '12px', color: '#b10f15' }}>
                  Not you?{' '}
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => resetLogin()}>
                    Click here
                  </span>
                </div>
                <div className={subscriptionStyle['inp-wrap']}>
                  <FloatInputBox
                    Label="Enter OTP"
                    type="text"
                    value={otp}
                    OnInput={(e) => onChangeOTP(e)}
                    maxLength={4}
                    autoFocus={true}
                  />
                </div>
                <div style={{ fontSize: 13, marginBottom: 80 }}>
                  {showResend ? (
                    <div className={subscriptionStyle['otp-request']}>
                      Didn’t receive OTP
                      <span
                        className={subscriptionStyle['cursor-blue-link']}
                        onClick={resendOtpSubmit}
                        id="sel-get-OTP"
                      >
                        {` Request Again`}
                      </span>
                    </div>
                  ) : (
                    <div id="sel-otp-count">You can request another OTP in {counter} seconds</div>
                  )}
                </div>
                <p
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    position: 'absolute',
                    marginTop: error === 'Invalid OTP' ? '270px' : '245px',
                  }}
                >
                  {error}
                </p>

                <div
                  className={style['terms-condition']}
                  style={{ marginTop: 4, width: '100%', justifyContent: 'center' }}
                >
                  <input
                    id="sel-promotional-check"
                    type="checkbox"
                    checked={promotionalChecked}
                    onChange={() => {
                      setPromotionalChecked(!promotionalChecked);
                    }}
                  />
                  <p style={{ fontSize: '14px' }}>Receive updates and special offers</p>
                </div>
                <BlueButton
                  onClick={() => {
                    submitOTP();
                  }}
                  customStyle={commonBlueBtnStyle}
                  disabled={(!otpValid && !error) || loading}
                >
                  Verify
                </BlueButton>
              </>
            )}
            <br />
            <Footer />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BundledSubscriptionLogin;
