import React, { useEffect, useRef } from 'react';
import CSS from 'csstype';
import style from './floatInputBox.module.scss';

const FloatInputBox = ({
  Label,
  type,
  value,
  fieldName,
  maxLength,
  OnInput = () => null,
  parentDivCustomStyle,
  inpCustomStyle,
  labelCustomStyle,
  disabled,
  autoFocus,
  compulsoryField,
}: {
  Label?: string;
  type?: string;
  value?: string;
  fieldName?: string;
  maxLength?: number;
  OnInput?: (e: any) => unknown;
  parentDivCustomStyle?: CSS.Properties;
  inpCustomStyle?: CSS.Properties;
  labelCustomStyle?: CSS.Properties;
  disabled?: boolean;
  autoFocus?: boolean;
  phoneError?: string;
  compulsoryField?: boolean;
  isVerified?: {
    email: boolean;
    phoneNumber: boolean;
  };
  setIsVerified?: React.Dispatch<
    React.SetStateAction<{
      email: boolean;
      phoneNumber: boolean;
    }>
  >;
}) => {
  const inputElement = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      // @ts-ignore
      inputElement?.current?.focus();
    }
  }, [autoFocus]);

  return (
    <div className={style['subscription-container']} style={parentDivCustomStyle}>
      <div className={style['input-field']}>
        <input
          ref={inputElement}
          disabled={disabled}
          placeholder=" "
          onChange={(e) => {
            OnInput(e);
          }}
          value={value}
          type={type}
          name={fieldName}
          maxLength={maxLength}
          style={inpCustomStyle}
        />
        <label style={labelCustomStyle}>
          {Label}
          {compulsoryField && <span className={style['asterisk']}> * </span>}{' '}
        </label>
      </div>
    </div>
  );
};

export default FloatInputBox;
