import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import textStyle from 'views/UserOverlay/views/loginAndVerify.module.scss';
import style from './coupon.module.scss';
import commonInputStyle from 'styles/commonInputStyle.module.scss';
import { Wrapper, Button, Error } from '../components';
import { OverlayContext, PURCHASE_MODE, ROUTES } from '../OverlayContext';
import { LeftOutlined } from '@ant-design/icons';

export default function Coupon(): JSX.Element {
  const overlayContext = useContext(OverlayContext);
  const {
    couponDetails,
    couponForPass,
    payForContent,
    routeData,
    setRoute,
    setRouteData,
    updateView,
    purchaseMode,
    userDetails,
    redirectMode,
    standaloneMode,
  } = overlayContext;
  const [couponCode, setCouponCode] = useState(purchaseMode === 'PASS' ? couponForPass?.code : couponDetails?.code);
  const [loading, setLoading] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [useTransition] = useState(routeData?.transition);
  const [hideBackButton] = useState(routeData?.hideBackButton);
  const [returnToBalanceOnCross] = useState(routeData?.returnToBalance);
  const [returnToConfirmPaymentOnCross] = useState(routeData?.returnToConfirmPayment);

  useEffect(() => {
    (async () => {
      // reset route data
      setRouteData({});
      // updating both simultaneously causes an update error
      await updateView({ action: purchaseMode === 'SUBSCRIPTION' ? 'SUB' : purchaseMode });
      updateView({ action: 'COUPON' });
    })();
  }, []);

  const backBtnClickFunction = () => {
    if (returnToBalanceOnCross) return setRoute(ROUTES.BALANCE);
    if (returnToConfirmPaymentOnCross) return setRoute(ROUTES.CONFIRM_PAYMENT);
  };

  const onSubmit = () =>
    payForContent({
      setError: setCouponError,
      setLoading,
      setShowPaidCheck: () => setRoute(ROUTES.PAYMENT_CONFIRMED),
      couponCode,
      updateViewAction: 'REDEEM-COUPON',
      type: purchaseMode,
    });

  const availableCouponCode = purchaseMode === PURCHASE_MODE.CONTENT ? couponDetails.code : couponForPass.code;

  return (
    <Wrapper hideCross={standaloneMode && !redirectMode} transition={useTransition}>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <div className={style['back-btn-container']}>
          {!hideBackButton && <LeftOutlined onClick={backBtnClickFunction} className={style['antd-icon']} />}

          <div className={clsx(textStyle['heading'], style['heading-container'])}>
            USE COUPON TO {userDetails.consumptionVerb} FOR FREE
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              marginTop: 32,
              marginBottom: couponDetails?.code || couponForPass?.code ? '140px' : '220px',
            }}
          >
            <div className={style['input-container']}>
              <div className={commonInputStyle['rounded-input-text']}>
                <input
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                  type="text"
                  placeholder="code"
                  className={commonInputStyle['input-field-common']}
                />

                <Button
                  buttonText="Apply"
                  disabled={loading}
                  onClickFunction={onSubmit}
                  customStyle={{
                    width: '100px',
                    height: '38px',
                    background: 'transparent',
                    color: '#19b4c8',
                  }}
                  customTextStyle={{ fontSize: '16px', fontWeight: 'normal' }}
                />
              </div>
              {(purchaseMode === 'PASS' ? couponForPass?.code : couponDetails?.code) && (
                <p className={style['small-text']}>Available Coupons:</p>
              )}
            </div>
          </div>
        </form>

        <>
          {availableCouponCode && (
            <div className={style['available-coupons-container']}>
              <div className={style['coupon']}>
                {purchaseMode === 'PASS' ? couponForPass?.code : couponDetails?.code}
              </div>
              <Button
                buttonText="Apply"
                disabled={loading}
                onClickFunction={() => setCouponCode(availableCouponCode)}
                customStyle={{
                  width: '45px',
                  height: '38px',
                  background: 'transparent',
                  color: '#19b4c8',
                }}
                customTextStyle={{ fontSize: '14px', fontWeight: 'normal' }}
              />
            </div>
          )}
        </>

        {couponError && <Error>{couponError}</Error>}
      </div>
      <div className={style['footer-space']}></div>
    </Wrapper>
  );
}
