import React from 'react';
import CSS from 'csstype';
import conscentLogo from 'assets/Logo/ConsCent-LogoIcon.svg';

function SubscriptionFooter() {
  const footerContainerStyles: CSS.Properties = {
    textAlign: 'center',
    color: '#15213d',
    fontSize: '16px',
    marginTop: '13.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const logosStyles: CSS.Properties = {
    height: '14px',
    marginLeft: '8px',
  };

  return (
    <p style={footerContainerStyles}>
      Powered by <img src={conscentLogo} style={logosStyles} alt="conscentLogo" />
    </p>
  );
}

export default SubscriptionFooter;
