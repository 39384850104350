import React, { useContext } from 'react';
import { ThankYou } from './views/ThankyouPage';
import PaymentPage from './views/PaymentPage';
import { useSelector } from 'react-redux';
import { BundleSubscriptionContext, SUBSCRIPTION_BUNDLE_ROUTES } from './BundledSubscriptionsContext';
import BundledSubscriptionLogin from './views/BundledSubscriptionLogin';
import SubsEditPage from 'components/SubsEditPage';
import { RootState } from 'app/rootReducer';

const SubscriptionRouter = () => {
  const authStore = useSelector((store: RootState) => store.auth);

  const { route } = useContext(BundleSubscriptionContext);

  const RouteMap = {
    [SUBSCRIPTION_BUNDLE_ROUTES.LOGIN]: <BundledSubscriptionLogin />,
    [SUBSCRIPTION_BUNDLE_ROUTES.REVIEW_SUBSCRIPTION]: (
      <SubsEditPage phonenumber={authStore.phoneNumber || ''} email={authStore.email || ''} />
    ),
    [SUBSCRIPTION_BUNDLE_ROUTES.PAYMENT_PAGE]: <PaymentPage />,
    [SUBSCRIPTION_BUNDLE_ROUTES.THANKYOU]: <ThankYou />,
    [SUBSCRIPTION_BUNDLE_ROUTES.DEFAULT_BLANK]: <></>,
  } as Record<SUBSCRIPTION_BUNDLE_ROUTES, JSX.Element>;

  return <>{RouteMap[route]}</>;
};

export default SubscriptionRouter;
