import React from 'react';
import { Row, Col } from 'antd';
import style from './genericErrorPage.module.scss';
import LogoIcon from '../../src/assets/Logo/conscentFavicon.svg';
import noDataIcon from '../../src/assets/no-data-7724.svg';

const GenericErrorPage = () => {
  return (
    <div className={style.container}>
      <img src={LogoIcon} alt="LogoIcon" className={style.logo} />
      <Row>
        <Col xs={24} md={6} style={{ display: 'flex' }}>
          <img src={noDataIcon} className={style['data-icon']} />
        </Col>
        <Col xs={24} md={18}>
          <div className={style.content}>
            <p className={style['main-heading']}>Oops!</p>
            {/* add here Error */}
            <p className={style.heading}></p>
            <p className={style['sub-heading']}>Something went wrong! Please try again</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GenericErrorPage;
