import { RootState } from 'app/rootReducer';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { postMessage } from '../views/UserOverlay/paywallUtils';

export default function LoginCheck() {
  const authDetails = useSelector((store: RootState) => store.auth);
  useEffect(() => {
    if (authDetails.phoneNumber || authDetails.email) {
      if (authDetails.userType === 'USER') return postMessage.LOGGEDIN(true, authDetails.sessionId);
    }
    postMessage.LOGGEDIN(false);
  }, []);
  return <></>;
}
