import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from 'api';
import { OverlayContext, ROUTES } from '../OverlayContext';
import { Button, ButtonStyles } from '.';
import { RootState } from 'app/rootReducer';
import style from './addMoney.module.scss';
import { fbPixelConfig } from '../../../utils/fbPixelConfig';
import { Currencies } from '../../../data/currencies';
import { getCurrencySymbol } from '../../../data/getCurrencySymbol';
import axios from 'axios';
import { getDefaultTrackingValues, eventType, eventLocation, clickAction } from '../../../utils/Tracker/track';

// import { getClientId } from 'Tracker/CommonFunction';
import {
  validateCurrencyAmount,
  makeRzpPayment,
  makePaytmPayment,
  includeRazorpay,
  includePaytm,
} from '../../../utils/paymentUtility';
import { minAmountForCurrencies } from 'data/minimumAmountForCurrencies';
import LoadingCircle from './loaders/LoadingCircle';
import { toNumber } from 'lodash';

export const AddMoney = ({ setContentPrice }: { setContentPrice: any }) => {
  const overlayContext = useContext(OverlayContext);
  const {
    clientDetails,
    userDetails,
    setRoute,
    setRouteData,
    routeData,
    standaloneMode,
    updateView,
    viewId,
    couponDetails,
    couponForPass,
    purchaseMode,
    contentPurchaseType,
    downloadExists,
  } = overlayContext;

  const authDetails = useSelector((store: RootState) => store.auth);
  const [error, setError] = useState('');
  const [minAmountError, setMinAmountError] = useState('');
  const [transactionError, setTransactionError] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isPresetAmountLoading, setIsPresetAmountLoading] = useState(false);
  const [isAddMoneyToWalletLoading, setIsAddMoneyToWalletLoading] = useState(false);
  const [usePaytm, setUsePaytm] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(userDetails.contentCurrency);
  const [currencyLoading, setCurrencyLoading] = useState(true);
  const [currencyUpdating, setCurrencyUpdating] = useState(false);

  // const rawPrice = purchaseMode === 'CONTENT' ? userDetails.contentPrice : userDetails.passPrice;
  // const contentPrice = Math.ceil(rawPrice * 100) / 100;
  const contentPrice = Math.ceil(userDetails.contentPrice * 100) / 100;
  const [convertedContentPrice, setConvertedContentPrice] = useState(contentPrice);
  const [currency, setCurrency] = useState(userDetails.contentCurrency);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  //@ts-ignore
  const minAmount = minAmountForCurrencies[userDetails.contentCurrency];
  const [balanceDeficit, setBalanceDeficit] = useState(contentPrice - userDetails.walletBalance);
  const [minimumLoadingAmount, setMinimumLoadingAmount] = useState(
    balanceDeficit >= minAmount ? balanceDeficit : minAmount,
  );
  const [paymentAmount, setPaymentAmount] = useState(minimumLoadingAmount.toFixed(2));
  const [presetAmounts, setPresetAmounts] = useState(['50', '100', '500']);
  const [detectedCountry, setDetectedCountry] = useState('');
  const [addMoneyToWallet, setAddMoneyToWallet] = useState(minimumLoadingAmount.toFixed(2));
  const searchParams = new URLSearchParams(location.search);
  const analyticsStore = useSelector((store: RootState) => store.analytics);

  let paytmScript: HTMLScriptElement;
  let razorpayScript: HTMLScriptElement;

  useEffect(() => {
    updateContentPrice(userDetails.contentCurrency);
  }, [purchaseMode]);

  useEffect(() => {
    if (loading === false) {
      setIsAddMoneyToWalletLoading(false);
      setIsPresetAmountLoading(false);
    }
  }, [loading]);
  const paywallId = analyticsStore.paywallId;

  useEffect(() => {
    if (history.action === 'POP' && searchParams.get('status')) {
      searchParams.delete('status');
      history.replace({
        search: searchParams.toString(),
      });
    }

    {
      /* FIXME: Disabled Paytm */
    }
    // if (detectedCountry === 'IN' && currency === 'INR') {
    //   setUsePaytm(true);
    //   if (!paytmScript) paytmScript = includePaytm();
    // } else {
    //   setUsePaytm(false);
    // }

    //@ts-ignore
    const minCurrencyAmount = minAmountForCurrencies[currency];
    setPresetAmounts([
      (minCurrencyAmount * 50).toFixed(0),
      (minCurrencyAmount * 100).toFixed(0),
      (minCurrencyAmount * 500).toFixed(0),
    ]);

    return () => {
      if (paytmScript) document.body.removeChild(paytmScript);
      if (razorpayScript) document.body.removeChild(razorpayScript);
    };
  }, [currency, detectedCountry]);

  useEffect(() => {
    updateView({ action: 'ADD-MONEY' });
    if (!razorpayScript) razorpayScript = includeRazorpay();
  }, []);

  useEffect(() => {
    for (let i = 0; i < presetAmounts.length; i++) {
      if (minimumLoadingAmount < presetAmounts[i]) {
        setAddMoneyToWallet(presetAmounts[i]);
        return;
      }
      setAddMoneyToWallet(minimumLoadingAmount);
    }
  }, [minimumLoadingAmount]);

  useEffect(() => {
    if (!authDetails.userId) {
      return setRoute(ROUTES.WELCOME);
    }

    if (routeData?.message) {
      setTransactionError(routeData.message);
    }

    (async () => {
      const userDetails = await API.user.Details();
      if (userDetails.data.wallet.currency) {
        setCurrency(userDetails.data.wallet.currency);
        await updateContentPrice(userDetails.data.wallet.currency);
        if (userDetails.data.wallet.currency === 'INR') {
          const currencyByCountryReponse = await API.auth.DetectCountry();
          setDetectedCountry(currencyByCountryReponse.data.countryCode);
        }
      } else {
        const currencyByCountryReponse = await API.auth.DetectCountry();
        if (!API.validators.checkSuccessCode(currencyByCountryReponse))
          return setError('Failed to load geo information. Please try again later');
        setCurrency(currencyByCountryReponse.data.currency);
        setDetectedCountry(currencyByCountryReponse.data.countryCode);
        await updateContentPrice(currencyByCountryReponse.data.currency);
        setShowCurrencySelect(true);
      }
      setCurrencyLoading(false);
    })();
  }, []);
  useEffect(() => {
    addMoneyPageView();
  }, []);
  const addMoneyPageView = () => {
    const addMoneyPageViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      userId: userDetails.userId,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
      anonId: analyticsStore.anonId,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
      paywallId,
    };
    API.analyst.eventPost({ ...addMoneyPageViewObject });
  };
  const rzrPayCrossBtn = () => {
    const rzrPayCrossBtnObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
      clickAction: clickAction.PAYMENT_GATEWAY_CROSS_BTN,
      amount: convertedContentPrice,
      currency: currency,
      userId: userDetails.userId,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
      anonId: analyticsStore.anonId,
      paywallId,
    };
    API.analyst.eventPost({ ...rzrPayCrossBtnObject });
  };
  const P2P = (price: number, type: string) => {
    const P2PObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
      clickAction: type,
      amount: price,
      currency: currency,
      userId: userDetails.userId,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
      anonId: analyticsStore.anonId,
      paywallId,
    };
    API.analyst.eventPost({ ...P2PObject });
  };

  const onSubmit = async (e: any, type: 'payAmount' | 'addMoneyToWallet') => {
    if (type === 'payAmount') {
      console.log(P2P(convertedContentPrice, 'P2P'), 'dfhbhjkd');
    } else if (type === 'addMoneyToWallet') {
      P2P(parseInt(addMoneyToWallet), 'ADD_MONEY_TO_WALLET');
    }

    if (loading === true) return;
    e.preventDefault();

    if (type === 'payAmount') {
      setIsPresetAmountLoading(true);
    } else {
      setIsAddMoneyToWalletLoading(true);
    }
    const amount = type === 'payAmount' ? paymentAmount : addMoneyToWallet;

    const validatePaymentError = validateCurrencyAmount({
      amount: parseFloat(amount),
      currency: currency,
    });
    if (validatePaymentError) {
      return setTransactionError(validatePaymentError);
    }

    setLoading(true);
    fbPixelConfig('InitiateCheckout', {
      currency: currency,
      value: amount,
      content_ids: clientDetails.clientContentId,
    });
    const contentDetails = {
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
      anonId: analyticsStore.anonId,
      clientId: clientDetails.clientId,
      clientContentId: clientDetails.clientContentId,
      price: convertedContentPrice,
      currency: currency,
      userId: authDetails.userId,
      paywallId,
      journeyId: localStorage.getItem('journeyId'),
      templateId: localStorage.getItem('templateId') || '',
      successFrontendUrl: `${window.location.href}&transactionId={TRANSACTION_ID}&viewId=${viewId}`,
      type: purchaseMode,
      contentPurchaseType,
      failureFrontendUrl: `${window.location.href}`.includes('&status=FAILURE')
        ? `${window.location.href}`
        : `${window.location.href}&status=FAILURE`,
      popupId: userDetails.popupId,
    } as Record<string, any>;

    if (standaloneMode) {
      contentDetails.successFrontendUrl += '&paymentSuccessful=true';
    }

    if (usePaytm) {
      fbPixelConfig('AddPaymentInfo', {
        currency: currency,
        value: amount,
        content_ids: clientDetails.clientContentId,
        contents: 'paytmPayment',
      });

      const createPaymentResponse = await API.user.payment.createPayment({
        amount: Number.parseFloat(amount),
        contentDetails,
        currency: currency,
      });

      if (!API.validators.checkSuccessCode(createPaymentResponse)) {
        setLoading(false);
        return setTransactionError('Transaction failed');
      }
      const createPaymentData = createPaymentResponse.data as { orderId: string; txnToken: string; amount: number };
      if (!createPaymentData.orderId) {
        setLoading(false);
        return setTransactionError('Failed to place order, try again later');
      }
      makePaytmPayment(
        createPaymentData.orderId,
        createPaymentData.txnToken as string,
        createPaymentData.amount as number,
        setLoading,
      );
    } else {
      fbPixelConfig('AddPaymentInfo', {
        currency: currency,
        value: amount,
        content_ids: clientDetails.clientContentId,
        contents: 'razorpayPayment',
      });
      const createRzpPaymentResponse = await API.user.payment.createRzpPayment({
        amount: Number.parseFloat(amount),
        contentDetails: contentDetails,
        currency: currency,
      });
      if (!API.validators.checkSuccessCode(createRzpPaymentResponse)) {
        setLoading(false);
        return setTransactionError(createRzpPaymentResponse.data.message);
      } else {
        const createPaymentData = createRzpPaymentResponse.data as {
          orderId: string;
          primaryColor?: string;
          brandLogoUrl?: string;
        };
        if (!createPaymentData.orderId) return setError('Failed to place order, try again later');
        makeRzpPayment(
          createPaymentData.orderId,
          createPaymentData.primaryColor,
          createPaymentData.brandLogoUrl,
          authDetails.phoneNumber as string,
          authDetails.email as string,
          rzrPayCrossBtn,
          setLoading,
        );
      }
    }
  };

  const checkAndSetError = (value: string) => {
    const paymentVal = Number.parseFloat(value);
    if (Number.isNaN(paymentVal)) return setMinAmountError('Invalid amount');

    if (paymentVal < minimumLoadingAmount) {
      return setMinAmountError('Minimum addition is ' + currencySymbol + Math.ceil(minimumLoadingAmount * 100) / 100);
    }

    return setMinAmountError('');
  };

  const onInput = (e: any) => {
    let { value } = e.target;
    if (value.includes('.')) {
      value = +Number.parseFloat(value).toFixed(2);
    }
    setAddMoneyToWallet(value);
    checkAndSetError(value);
  };

  const paymentButtonClick = (amount: string) => () => {
    setAddMoneyToWallet(amount);
    checkAndSetError(amount);
  };

  const updateContentPrice = async (currencyISOCode: string) => {
    setCurrencyUpdating(true);
    const currencySymbol = getCurrencySymbol(currencyISOCode) || '₹';
    let updatedContentPrice = purchaseMode === 'PASS' ? userDetails.passPrice : userDetails.contentPrice;
    if (userDetails.contentCurrency !== currencyISOCode) {
      const updatedContentPriceResponse = await API.content.convertCurrency({
        from: userDetails.contentCurrency,
        to: currencyISOCode,
        amount: purchaseMode === 'PASS' ? userDetails.passPrice : userDetails.contentPrice,
      });
      updatedContentPrice = updatedContentPriceResponse.data;
    }

    if (currencyISOCode !== 'INR') setUsePaytm(false);
    const contentPriceDisplay = Math.ceil(updatedContentPrice * 100) / 100;

    //@ts-ignore
    const minAmount = minAmountForCurrencies[currencyISOCode];
    const balanceDeficit = updatedContentPrice - userDetails.walletBalance;
    const minimumLoadingAmount = balanceDeficit >= minAmount ? balanceDeficit : minAmount;
    const paymentAmount = Math.ceil(minimumLoadingAmount * 100) / 100;
    setCurrency(currencyISOCode);
    setCurrencySymbol(currencySymbol);
    setBalanceDeficit(balanceDeficit);
    setContentPrice(currencySymbol + ' ' + parseFloat(contentPriceDisplay.toFixed(2)));
    setConvertedContentPrice(updatedContentPrice);
    setMinimumLoadingAmount(minimumLoadingAmount);
    setPaymentAmount(paymentAmount.toFixed(2));
    setCurrencyUpdating(false);
  };

  if (currencyLoading)
    return (
      <div>
        <LoadingCircle />
      </div>
    );

  const redirectToCouponPage = () => {
    setRoute(ROUTES.COUPON);
    setRouteData({ returnToBalance: true });
  };
  return (
    <div className={style['add-money']}>
      <div className={style['symbol-amount-main']}>
        <div className={style['proceed-to-next']}>
          <div className={style['heading']}>
            {downloadExists ? (
              <span style={{ fontSize: '16px' }}> To Download {userDetails.contentTitle} </span>
            ) : (
              `Continue ${userDetails?.consumptionVerb?.toLocaleLowerCase()}ing...`
            )}
          </div>

          <div className={style['conscent-balance-msg']}>
            ConsCent Balance:
            <span style={{ fontWeight: 'bold' }}> {currencySymbol + ' ' + userDetails.walletBalance.toFixed(2)}</span>
          </div>
          <Button.DarkBlue
            buttonText={isPresetAmountLoading ? 'Processing' : `Pay ${currencySymbol + ' ' + paymentAmount} to Proceed`}
            onClickFunction={(e) => onSubmit(e, 'payAmount')}
            disabled={isPresetAmountLoading || !!error || currencyUpdating}
            customStyle={{ width: '100%', height: '48px' }}
            customMobileStyle={{ height: '40px' }}
            customMobileTextStyle={{ fontSize: '14px' }}
          />
        </div>
        <div className={style['offers-container']}>
          {(purchaseMode === 'CONTENT' || !purchaseMode) && (
            <div>
              {couponDetails.code ? (
                <>
                  <div className={style['coupon-btn']}>
                    <span>
                      Use <span className={style['coupon-text-bold']}>{couponDetails.code}</span>
                      {` to `}
                      {userDetails.consumptionVerb.toLowerCase()}
                      {` for `}
                      <span className={style['coupon-text-bold']}> FREE </span>
                    </span>
                    <span
                      className={style['offers-text']}
                      onClick={() => {
                        setRoute(ROUTES.COUPON);
                        setRouteData({ returnToBalance: true });
                      }}
                    >
                      View Offers
                    </span>
                  </div>
                </>
              ) : (
                <div style={{ color: '#14213d' }}>
                  Have a Coupon Code?
                  <span
                    onClick={() => redirectToCouponPage()}
                    id="sel-coupon-code"
                    style={{
                      color: '#14213d',
                      cursor: 'pointer',
                      fontSize: '12px',
                      marginLeft: '5px',
                      textDecoration: 'underline',
                      fontWeight: 700,
                    }}
                  >
                    Click Here
                  </span>
                </div>
              )}
            </div>
          )}

          {purchaseMode === 'PASS' && (
            <div>
              {couponForPass?.code ? (
                <>
                  <div className={style['coupon-btn']}>
                    <span>
                      Use <span className={style['coupon-text-bold']}>{couponForPass.code}</span>
                      {` to `}
                      {userDetails.consumptionVerb.toLowerCase()}
                      {` for `}
                      <span className={style['coupon-text-bold']}> FREE </span>
                    </span>
                    <span
                      onClick={() => {
                        setRoute(ROUTES.COUPON);
                        setRouteData({ returnToBalance: true });
                      }}
                      className={style['offers-text']}
                    >
                      view offers
                    </span>
                  </div>
                </>
              ) : (
                <>
                  Have a Coupon Code?
                  <span
                    onClick={() => redirectToCouponPage()}
                    id="sel-coupon-code"
                    style={{
                      color: '#14213d',
                      cursor: 'pointer',
                      fontSize: '14px',
                      marginLeft: '5px',
                      textDecoration: 'underline',
                      fontWeight: 700,
                    }}
                  >
                    Click Here
                  </span>
                </>
              )}
            </div>
          )}
        </div>

        <div className={style['promotion-text']}>For one-click, no OTP payment across ConsCent partners</div>
        <div className={style['preset-amount-container']}>
          <span className={style['add-money-heading']}> Load your ConsCent Wallet </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {presetAmounts.map((amt, i) => (
              <Button
                id={`sel-addmoney-${amt}`}
                buttonStyle={ButtonStyles.WHITE}
                onClickFunction={paymentButtonClick(amt)}
                customStyle={{
                  height: addMoneyToWallet === amt ? '45px' : '43px',
                  width: 'fit-content',
                  backgroundColor: addMoneyToWallet === amt ? '#14213D' : '#F7F7F7',
                  color: addMoneyToWallet === amt ? '#fff' : '#14213D',
                  boxShadow: addMoneyToWallet === amt ? '1px 2px 3px #14213D1C' : '1px 1px 0px #1A1A1A43',
                  margin: i === 2 ? '8px 0px 8px 0px' : '8px 16px 8px 0px',
                  padding: '8px 16px',
                }}
                customMobileStyle={{
                  height: addMoneyToWallet === amt ? '40px' : '38px',
                  width: '30%',
                  padding: 0,
                }}
                key={amt}
              >
                <span className={style['btn-text']}>
                  {currencySymbol} {amt}
                </span>
              </Button>
            ))}
          </div>

          <div className={style['wrap-currency-amount']}>
            {showCurrencySelect ? (
              <select
                onChange={(e) => {
                  updateContentPrice(e.target.value);
                  setSelectedCurrency(e.target.value);
                }}
                className={style['currencies-select']}
                value={selectedCurrency}
              >
                {Currencies.map((e, key) => {
                  return (
                    <option key={key} value={e.code}>
                      {e.code}
                    </option>
                  );
                })}
              </select>
            ) : (
              <p className={style['selected-currency']}>{currencySymbol}</p>
            )}

            <input
              id="sel-addmoney-inp"
              onInput={onInput}
              value={addMoneyToWallet}
              className={style['input-field']}
              type="number"
              min={minimumLoadingAmount.toFixed(2)}
            />
          </div>
          {!!error?.length && <div className={style['error-msg']}>{error}</div>}
          {!!minAmountError?.length && <div className={style['error-msg']}>{minAmountError}</div>}
          {!!transactionError?.length && <div className={style['error-msg']}>{transactionError}</div>}

          <Button.DarkBlue
            id="sel-addmoney-submit"
            buttonText={
              isAddMoneyToWalletLoading ? 'Processing' : `ADD ${currencySymbol}  ${addMoneyToWallet} to your wallet`
            }
            onClickFunction={(e) => onSubmit(e, 'addMoneyToWallet')}
            disabled={isAddMoneyToWalletLoading || !!error || !!minAmountError || currencyUpdating}
            customStyle={{ width: '100%', height: '48px', marginTop: '22px' }}
            customMobileStyle={{ height: '40px' }}
            customMobileTextStyle={{ fontSize: '14px' }}
          />
          <div className={style['symbol-amount-footer']}>
            Add minimum {currencySymbol + ' ' + minimumLoadingAmount.toFixed(2)} to continue
          </div>
        </div>
      </div>
    </div>
  );
};
