import React, { useContext, useState, useEffect } from 'react';
import textStyle from '../styles/text.module.scss';
import { Wrapper, Button, Error } from '../components';
import { OverlayContext, PURCHASE_MODE, ROUTES } from '../OverlayContext';
import { getCurrencySymbol } from '../../../data/getCurrencySymbol';
import LoadingCircle from '../components/loaders/LoadingCircle';
import API from 'api';
import clsx from 'clsx';
import WalletIcon from '../../../assets/user/WalletIcon.svg';
import conscentLogoMono from 'assets/conscentLogoMono.png';

export default function ConfirmPayment() {
  const overlayContext = useContext(OverlayContext);
  const searchParams = new URLSearchParams(location.search);
  const {
    payForContent,
    userDetails,
    setRoute,
    couponDetails,
    couponForPass,
    clientDetails,
    loading: overlayContextLoading,
    purchaseMode,
    setPurchaseMode,
    setRouteData,
    downloadExists,
    contentPurchaseType,
  } = overlayContext;
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const transactionId = searchParams.get('transactionId');
    if (transactionId) {
      setInitialLoading(true);
    }

    (async () => {
      if (overlayContextLoading) return;

      if (transactionId) {
        const transactionResponse = await API.user.payment.getTransaction({ transactionId });
        if (!API.validators.checkSuccessCode(transactionResponse)) return setInitialLoading(false);
        const transactionResponseData = transactionResponse.data;
        if (!transactionResponseData.transaction?.contentDetails) return setInitialLoading(false);
        {
          const transactionContentDetails = transactionResponseData.transaction.contentDetails;
          if (
            transactionContentDetails.clientId === clientDetails.clientId &&
            transactionContentDetails.clientContentId === clientDetails.clientContentId
          ) {
            if (transactionContentDetails.type === PURCHASE_MODE.PASS) setPurchaseMode(PURCHASE_MODE.PASS);
            else if (transactionContentDetails.type === PURCHASE_MODE.CONTENT) setPurchaseMode(PURCHASE_MODE.CONTENT);
            else setPurchaseMode(PURCHASE_MODE.SUBSCRIPTION);

            await payForContent({
              setError: (error) => {
                if (transactionContentDetails.type === PURCHASE_MODE.SUBSCRIPTION) {
                  setRouteData({ error });
                  setRoute(ROUTES.BALANCE); // FIXME: not needed
                }
                setError(error);
              },
              setLoading: setInitialLoading,
              setShowPaidCheck: () => setRoute(ROUTES.PAYMENT_CONFIRMED),
              type: transactionContentDetails.type || 'CONTENT',
              subscriptionDetails: {
                _id: transactionContentDetails.subscriptionId,
                amount: transactionContentDetails.price,
                currency: transactionContentDetails.currency,
              },
              popupId: transactionContentDetails.popupId,
              contentPurchaseType: transactionContentDetails.contentPurchaseType,
            });
          }
        }
        setInitialLoading(false);
      }
    })();
  }, [overlayContextLoading]);

  if (initialLoading)
    return (
      <Wrapper hideCross={true}>
        <LoadingCircle />
      </Wrapper>
    );

  return (
    <Wrapper hideFooter={true} hideCross={searchParams.get('pageUrl') ? false : true}>
      {purchaseMode === 'CONTENT' && (
        <>
          <img src={WalletIcon} alt="WalletIcon" />
          <div className={textStyle['heading-small']} style={{ marginBottom: 16, marginTop: 30 }}>
            PAY &nbsp;
            {userDetails.formattedContentPrice} TO {downloadExists ? 'DOWNLOAD' : userDetails.consumptionVerb} THE{' '}
            {downloadExists ? userDetails.contentTitle.toUpperCase() : userDetails.contentType}{' '}
          </div>

          <div style={{ maxWidth: '80%', margin: 'auto', fontSize: '16px' }} className={textStyle['subHeading']}>
            <div>
              {downloadExists ? 'Download' : userDetails.contentType} validity:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {userDetails.contentDuration === 1
                  ? userDetails.contentDuration + ' day'
                  : userDetails.contentDuration + ' days'}
              </span>
            </div>
          </div>
        </>
      )}
      {purchaseMode === 'PASS' && (
        <>
          <img src={WalletIcon} alt="WalletIcon" />
          <div className={textStyle['heading-small']} style={{ marginBottom: 16, marginTop: 30 }}>
            PAY TO BUY THE PASS
          </div>

          <div style={{ maxWidth: '80%', margin: 'auto', fontSize: '16px' }} className={textStyle['subHeading']}>
            <div>
              Pass validity:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {userDetails.passDuration < 72
                  ? `${userDetails.passDuration}-hr${userDetails.passDuration > 1 ? 's' : ''}`
                  : `${Math.floor(userDetails.passDuration / 24)} day${userDetails.passDuration > 47 ? 's' : ''}`}{' '}
              </span>
            </div>
          </div>
        </>
      )}

      <div className={textStyle['bold']} style={{ marginTop: '16px' }}>
        ConsCent Balance:{' '}
        {(getCurrencySymbol(userDetails.contentCurrency) as string) + parseFloat(userDetails.walletBalance.toFixed(2))}
      </div>

      <div
        onClick={() => {
          setRouteData({ returnToConfirmPayment: true });
          setRoute(ROUTES.COUPON);
        }}
        style={{ marginTop: '24px' }}
      >
        {(purchaseMode === 'PASS' ? couponForPass?.code : couponDetails?.code) ? (
          <>
            <div
              style={{
                color: '#15b7ca',
                border: '1px solid rgb(21, 183, 202)',
                background: '#eaf8f9',
                maxWidth: 330,
                margin: 'auto',
                cursor: 'pointer',
              }}
            >
              {`Use code ${
                purchaseMode === PURCHASE_MODE.PASS ? couponForPass.code : couponDetails.code
              } to ${userDetails.consumptionVerb.toLowerCase()} for FREE`}
              <br /> <b>Click here!</b>
            </div>
          </>
        ) : (
          <>
            Have a Coupon Code?{' '}
            <span id="sel-coupon-code" style={{ color: '#15b7ca', cursor: 'pointer', fontSize: 16 }}>
              Click Here
            </span>
          </>
        )}
      </div>

      <Button
        buttonText={`Pay ${
          purchaseMode === 'CONTENT' ? userDetails.formattedContentPrice : userDetails.formattedPassPrice
        }`}
        onClickFunction={() =>
          payForContent({
            setError,
            setLoading,
            setShowPaidCheck: () => setRoute(ROUTES.PAYMENT_CONFIRMED),
            type: purchaseMode,
            contentPurchaseType,
          })
        }
        id={'sel-welcome-payBtn'}
        disabled={loading}
        customStyle={{
          background: 'linear-gradient(118deg, #A8E6ED 0%, #06B8CA 100%)',
          borderRadius: '58px',
          marginTop: '24px',
          height: '44px',
        }}
        customTextStyle={{ fontSize: '18px' }}
        customMobileStyle={{
          width: '240px',
          height: '42px',
        }}
      />
      <div style={{ fontSize: '12px', color: '#15213D', margin: '4px 0px 8px 0px', opacity: '0.6' }}>
        {purchaseMode === 'CONTENT' ? userDetails.formattedContentPrice : userDetails.formattedPassPrice} will be
        deducted from your ConsCent wallet
      </div>
      <div className={clsx(textStyle.text)}>
        Pay with <img src={conscentLogoMono} alt="conscentLogoMono" style={{ height: 10, marginBottom: 2 }} />
      </div>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
}
