export const shouldRedirect = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isIos() || isSafari || !isLocalStorageAvailable()) {
    return true;
  }
  const webviewRegExp = new RegExp('(' + webViewUARegexes.join('|') + ')', 'ig');
  return !!navigator.userAgent.match(webviewRegExp);
};

export const isIos = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isLocalStorageAvailable = () => {
  try {
    window.localStorage.setItem('key-293879234', 'val'); // random key
  } catch (e) {
    return false;
  }
  return true;
};

// copied from here: https://github.com/atomantic/is-ua-webview/blob/master/data/rules.js
const webViewUARegexes = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to lollipop webview will put {version}.0.0.0
  'Android.*(wv|.0.0.0)',
  // old chrome android webview agent
  'Linux; U; Android',
];

export const postMessage = (message: { type: string } & Record<string, any>, origin = '*') => {
  message.source = '_csc-overlay';
  // @ts-ignore
  if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify(message));
  window.parent.postMessage(message, origin);
};

export const addQueryParamToUrl = (param: { key: string; value: string }, url: string) => {
  const siteUrl = new URL(url);
  siteUrl.searchParams.set(param.key, param.value);
  return siteUrl.toString();
};

// known issue: preaccess_expired not work in redirect mode, doesn't matter too much; its a QoL feature for an edge-case
postMessage.SESSION_EXPIRED = () => postMessage({ type: 'sessionExpired' });
postMessage.LOGGEDIN = (loginState: boolean, sessionId?: string, userId?: string) => {
  postMessage({ type: 'loggedin', sessionId, userId });
};
postMessage.AutoLoginError = () => postMessage({ type: 'autoLoginError' });
postMessage.LOGOUT = () => postMessage({ type: 'logout' });
postMessage.CLEAR_SESSION_DETAILS = () => postMessage({ type: 'clearSessionDetails' });
postMessage.WELCOME_PAGE_REACHED = () => postMessage({ type: 'welcomePageReached' });
postMessage.WELCOME_PAGE_LEFT = () => postMessage({ type: 'welcomePageLeft' });
postMessage.REDIRECT_TO_CONSCENT = (url: string) => postMessage({ type: 'redirectToConscent', url });
postMessage.REDIRECT_TO_URL = (url: string) => postMessage({ type: 'redirectToPage', url });
postMessage.SET_IFRAME_DIMENSION = (height: number, width?: number) =>
  postMessage({ type: 'setIframeDimension', height, width });
postMessage.HIDE_IFRAME = () => postMessage({ type: 'hideIframe' });
postMessage.THIRD_PARTY_BLOCKED_STATUS = (blocked: boolean) => postMessage({ type: 'thirdPartyCookieStatus', blocked });
postMessage.CLOSE_DONATION_WINDOW = () => postMessage({ type: 'close' });
postMessage.CREATE_VIEW = (viewId: string) => postMessage({ type: 'createView', viewId });

// Block page scrolling for FB/POPUP Paywalls
postMessage.FULLPAGE = () => postMessage({ type: 'fullpage' });
postMessage.POPUP = () => postMessage({ type: 'popup' });
