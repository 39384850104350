import React, { useEffect, useState, useContext } from 'react';
import { postMessage } from '../UserOverlay/paywallUtils';
import style from './conscentLogin.module.scss';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from 'views/Auth/authSlice';
import { message } from 'antd';
import API from 'api';
import { phoneLogin, emailLogin } from '../Auth/authUtils';
import { ConscentLoginContext } from '../ConscentLogin/ConscentLoginContext';
import { OverlayContext } from 'views/UserOverlay/OverlayContext';
import UserSignUpAndLogin from 'views/ClientDashboard/loginUI/UserSignUpAndLogin';
import { GetLoginCustomizationdata } from '../ClientDashboard/login/loginUtils';
import VerifyOTP from 'views/ClientDashboard/loginUI/VerifyOTP';

import * as queryString from 'query-string';
import { eventType, getDefaultTrackingValues, eventLocation } from 'utils/Tracker/track';

enum SCREENS {
  LOGIN = 'login',
  OTP = 'otp',
}

export const ConscentLogin = () => {
  const dispatch = useDispatch();
  const { clientDetails, postLoginFlow } = useContext(OverlayContext);
  const [loginType, setLoginType] = useState<'PHONE' | 'EMAIL'>('PHONE');
  const [screen, setScreen] = useState(SCREENS.LOGIN as SCREENS);
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState('');

  const [sessionId, setSessionId] = useState('' as string);
  const { setUserDetails: setUserDetailsContext } = useContext(ConscentLoginContext);

  const [clientId, setClientId] = useState('');
  const anonId = new URLSearchParams(location.search).get('anonId') as string;
  const [data, setdata] = useState({
    enableEmailLogin: true,
    enableMobileLogin: true,
    enableFacebookLogin: false,
    enableGoogleLogin: false,
    enableNameInput: 'SKIP',
    enableSecondaryInput: 'SKIP',
    brandLogoUrl: '',
    uiConfig: {},
  });
  const [uiConfig, setUiConfig] = useState({} as any);
  const [otpConfig, setOtpConfig] = useState({} as any);
  const [userDetailConfig, setUserDetailConfig] = useState({} as any);

  const pageUrl = new URLSearchParams(location.search).get('pageUrl') as string;

  const sendOTP = async () => {
    if (loginType === 'PHONE') {
      const { accountDetails } = await phoneLogin({
        phoneNumber: userPhone,
        clientId: clientId,
        isClientLoginFlow: true,
        eventLocation: 'SSO' + '_OTP',
        anonId,
      });
      ssoLoginClick('MOBILE');
      ssoOTPView();
    } else {
      const { accountDetails } = await emailLogin({
        email: userEmail,
        isClientLoginFlow: true,
        eventLocation: 'SSO' + '_OTP',
        anonId,
      });
      ssoLoginClick('EMAIL');
      ssoOTPView();
    }
  };

  const ssoLoginView = (clientId: string) => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_LOGIN,
      clientId: clientId,
      contentId: clientDetails.clientContentId,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const ssoOTPView = () => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_OTP,
      clientId: clientId,
      contentId: clientDetails.clientContentId,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const ssoLoginClick = (loginType: 'EMAIL' | 'MOBILE' | 'GOOGLE') => {
    const ssoLoginClickObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: loginType,
      clientId: clientId,
      contentId: clientDetails.clientContentId,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginClickObject });
  };

  const stringifiedParams = queryString.stringify({
    client_id: '367167408962065',
    redirect_uri: `${process.env.REACT_APP_API_URL}/auth/facebook/redirect`,
    scope: ['email'].join(','), // comma seperated string
    response_type: 'code',
    state: JSON.stringify({ url: decodeURIComponent(pageUrl), clientId }),
    auth_type: 'rerequest',
    display: 'popup',
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const clientId = params.get('clientId');
    if (clientId) setClientId(clientId);
  }, []);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const clientId = params.get('clientId');
      setClientId(clientId || '');

      const { data, error } = await GetLoginCustomizationdata({ clientId: clientId || '' });
      if (error) {
        return message.error('Error while fetching data for customized login view');
      }
      setdata(data);
      const config = JSON.parse(data.uiConfig);
      setUiConfig(config?.signIn);
      setOtpConfig(config?.otp);
      setUserDetailConfig(config?.userDetails);

      ssoLoginView(clientId || '');
    })();
  }, []);

  const submitOTP = async (otp: string) => {
    // setShowResendCode(false);
    const loginResponse = await API.auth.LoginOtp({
      email: loginType === 'EMAIL' ? userEmail : undefined,
      phoneNumber: loginType === 'PHONE' ? userPhone : undefined,
      OTP: otp,
      clientId,
      eventLocation: 'SSO' + '_OTP',
      anonId: anonId,
    });
    if (API.validators.matchStatusCode(loginResponse, 401)) return setError(loginResponse.data.message);
    if (API.validators.checkSuccessCode(loginResponse)) {
      const { token, email, userId, sessionId, expiresAt, version } = loginResponse.data as any;
      setSessionId(sessionId);
      dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, userType: 'USER', version }));
      const userDetailsResponse = await API.user.Details();
      if (!API.validators.checkSuccessCode(userDetailsResponse)) {
        throw { message: 'Unable to fetch user details' };
      }

      const name = userDetailsResponse.data.name;
      setUserDetailsContext({
        loggedIn: true,
        walletBalance: parseFloat(userDetailsResponse.data.wallet['balance']['$numberDecimal']),
        phoneNumber: userDetailsResponse.data.phoneNumber,
        email: userDetailsResponse.data.email,
        name,
        userId: userDetailsResponse.data._id,
        walletCurrency: userDetailsResponse.data.wallet['currency'],
      });

      await postLoginFlow({ userId: userDetailsResponse.data._id, setError, sessionId });
      postMessage.LOGGEDIN(true, sessionId, userId);
    } else {
      setError(loginResponse.data.message);
    }
  };
  const onScreenChange = (screentype: SCREENS) => {
    setScreen(screentype);
    sendOTP();
  };
  return (
    <div className={style['main-container']}>
      {screen === SCREENS.LOGIN && (
        <div style={{ position: 'relative' }}>
          <div className={style['cross-icon-container']}>
            <div
              className={style['cross-icon']}
              onClick={() => {
                postMessage.HIDE_IFRAME();
              }}
            >
              X
            </div>
          </div>
          <UserSignUpAndLogin
            type="SSO"
            clientId={clientId}
            setPhoneNumber={setUserPhone}
            setUserEmail={setUserEmail}
            setLoginType={setLoginType}
            onScreenChange={onScreenChange}
            data={data}
            uiConfig={uiConfig}
            loginClick={ssoLoginClick}
          />
        </div>
      )}
      {screen === SCREENS.OTP && (
        <div style={{ position: 'relative' }}>
          <div style={{ left: '345px', top: '7px' }} className={style['cross-icon-container']}>
            <div
              className={style['cross-icon']}
              onClick={() => {
                postMessage.HIDE_IFRAME();
              }}
            >
              X
            </div>
          </div>
          <VerifyOTP
            clientId={clientId}
            type="SSO"
            data={data}
            userPhoneNumber={userPhone}
            userEmail={userEmail}
            loginType={loginType}
            otpConfig={otpConfig}
            submitOTP={submitOTP}
            setError={setError}
            error={error}
          />
        </div>
      )}
    </div>
  );
};
