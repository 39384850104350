import React, { useEffect } from 'react';
import { postMessage, shouldRedirect } from './UserOverlay/paywallUtils';

export default function CookieCheck() {
  useEffect(() => {
    console.log('posting message', shouldRedirect());
    postMessage.THIRD_PARTY_BLOCKED_STATUS(shouldRedirect());
  }, []);
  return <></>;
}
