import React from 'react';
import style from './loginAndVerify.module.scss';
import Mastercard_logo from '../../../assets/Logo/mastercard_logo.webp';
import paypal_logo from '../../../assets/Logo/paypal_logo.webp';
import paytm_logo from '../../../assets/Logo/paytm_logo.webp';
import razorpay_logo from '../../../assets/Logo/razorpay_logo.webp';
import upi_logo from '../../../assets/Logo/upi_logo.webp';
import americanExpress_logo from '../../../assets/Logo/americanExpress_logo.webp';
import Encryption_logo from '../../../assets/Logo/encryption_logo.webp';
import Visa_logo from '../../../assets/Logo/visa_logo.webp';

function PaymentFooter() {
  return (
    <div>
      <div className={style['secure-transaction']}>
        100% secure transaction
        <span className={style['encryption-text']}>
          <img src={Encryption_logo} /> 256 bit encryption
        </span>
      </div>
      <div className={style['footer-icons']}>
        <img src={Visa_logo} /> <img src={Mastercard_logo} /> <img src={paypal_logo} /> <img src={paytm_logo} />{' '}
        <img src={razorpay_logo} /> <img src={americanExpress_logo} /> <img src={upi_logo} />{' '}
      </div>
    </div>
  );
}

export default PaymentFooter;
