import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import API from 'api';
import { setAuthDetails } from 'views/Auth/authSlice';
import { phoneLogin, emailLogin } from 'views/Auth/authUtils';
import subscriptionStyle from './subscriptionLogin.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { SubscriptionContext } from '../SubscriptionContext';
import UserSignUpAndLogin from '../../../../src/views/ClientDashboard/loginUI/UserSignUpAndLogin';
import VerifyOTP from 'views/ClientDashboard/loginUI/VerifyOTP';
import { GetLoginCustomizationdata } from '../../../views/ClientDashboard/login/loginUtils';
import { getDefaultTrackingValues, eventType, eventLocation } from 'utils/Tracker/track';
import { setAnalyticsData } from 'app/analyticsSlice';
import { message } from 'antd';

enum SCREENS {
  LOGIN = 'login',
  OTP = 'otp',
}

const SubscriptionLogin = () => {
  const [loginType, setLoginType] = useState<'PHONE' | 'EMAIL'>('PHONE');
  const [data, setdata] = useState({
    enableEmailLogin: true,
    enableMobileLogin: true,
    enableFacebookLogin: false,
    enableGoogleLogin: false,
    enableNameInput: 'SKIP',
    enableSecondaryInput: 'SKIP',
    brandLogoUrl: '',
    uiConfig: {},
  });
  const dispatch = useDispatch();
  const { viewId, subscriptionPageParams } = useContext(SubscriptionContext);

  const [uiConfig, setUiConfig] = useState({} as any);
  const [otpConfig, setOtpConfig] = useState({} as any);
  const [clientId, setClientId] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [screen, setScreen] = useState(SCREENS.LOGIN as SCREENS);
  const [error, setError] = useState('');
  const [clientTierId, setClientTierId] = useState('');

  const params = new URLSearchParams(window.location.search);
  // let firstTimeLogin = false;
  const siteUrl = new URLSearchParams(decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || ''));
  const paywallId = siteUrl.get('paywallId');
  const landingPageId = params.get('landingPageId') || '';
  const tierId = params.get('tierId') || '';
  const { initializeSubscriptions } = useContext(SubscriptionContext);
  const [alreadyRegistered, setAlreadyRegistered] = useState(Boolean);

  const authStore = useSelector((store: RootState) => store.auth);
  const anonId = new URLSearchParams(location.search).get('anonId') as string;
  dispatch(setAnalyticsData({ anonId }));
  dispatch(setAnalyticsData({ paywallId: paywallId || undefined }));
  let internalUserId = '';
  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const clientId = params.get('clientId');
      setClientId(clientId || '');
      const viewDetailsResponse = await API.content.GetViewDetails({ viewId: viewId as string });
      const defaultEmail = viewDetailsResponse?.data?.view?.userData?.defaultEmail;
      const defaultPhoneNumber = viewDetailsResponse?.data?.view?.userData?.defaultPhone;
      internalUserId = viewDetailsResponse?.data?.view?.userData?.internalUserId;

      if (defaultEmail) {
        setUserEmail(defaultEmail);
        setLoginType('EMAIL');
        sendOTP({ defaultEmail: defaultEmail, loginType: 'EMAIL' });
        setScreen(SCREENS.OTP);
      } else if (defaultPhoneNumber) {
        if (!defaultEmail) setLoginType('PHONE');
        setUserPhone(defaultPhoneNumber);
        sendOTP({ defaultPhone: defaultPhoneNumber, loginType: 'PHONE' });
        setScreen(SCREENS.OTP);
      }
      let clientTierId;
      if (tierId) {
        const tierDetailsResponse = await API.auth.GetTierDetails({ tierId: tierId as string });
        clientTierId = tierDetailsResponse?.data?.tierDetails?.clientTierId;
      }
      setClientTierId(clientTierId);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const clientId = params.get('clientId');
      setClientId(clientId || '');
      const { data, error } = await GetLoginCustomizationdata({ clientId: clientId || '' });
      if (error) {
        return message.error('Error while fetching data for customized login view');
      }
      setdata(data);
      const config = JSON.parse(data.uiConfig);
      setUiConfig(config?.signIn);
      setOtpConfig(config?.otp);

      subsLoginView(clientId || '');
    })();
  }, []);

  const subsLoginView = (clientId: string) => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SUBS_LOGIN,
      clientId,
      anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || ''),
      paywallId,
      landingPageId,
      tierId,
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const subsLoginClick = (firstTimeLogin: boolean, loginType: 'EMAIL' | 'MOBILE' | 'GOOGLE') => {
    const ssoLoginClickObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SUBS_LOGIN,
      clickAction: loginType,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || ''),
      paywallId,
      landingPageId,
      tierId,
      firstTimeLogin: !firstTimeLogin,
    };
    API.analyst.eventPost({ ...ssoLoginClickObject });
  };
  const subsOtpView = (firstTimeLogin: boolean) => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SUBS_OTP,
      clientId: clientId,
      anonId: anonId,
      landingPageId,
      paywallId,
      tierId,
      firstTimeLogin: !firstTimeLogin,

      url: decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || ''),
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };

  useEffect(() => {
    if (authStore.token) {
      if (authStore.userType === 'USER') initializeSubscriptions();
    }
  }, [authStore.token]);

  const sendOTP = async ({
    loginType,
    defaultPhone,
    defaultEmail,
  }: {
    loginType?: string;
    defaultPhone?: string;
    defaultEmail?: string;
  }) => {
    const params = new URLSearchParams(window.location.search);
    const clientId = params.get('clientId');
    if (loginType === 'PHONE') {
      const { accountDetails } = await phoneLogin({
        phoneNumber: defaultPhone || userPhone,
        clientId: clientId || '',
        isClientLoginFlow: true,
        internalUserId,
        eventLocation: eventLocation.SUBS_OTP,
        anonId: anonId,
        tierId: tierId || '',
        paywallId: paywallId || '',
        landingPageId: landingPageId || '',
      });
      setAlreadyRegistered(accountDetails?.alreadyRegistered);
      dispatch(setAnalyticsData({ alreadyRegistered: accountDetails?.alreadyRegistered }));
      subsLoginClick(accountDetails.alreadyRegistered, 'MOBILE');
      subsOtpView(accountDetails.alreadyRegistered);
    } else {
      const { accountDetails } = await emailLogin({
        email: defaultEmail || userEmail,
        isClientLoginFlow: true,
        eventLocation: eventLocation.SUBS_OTP,
        anonId: anonId,
        tierId: tierId,
        internalUserId,
        paywallId: paywallId || '',
        landingPageId: landingPageId || '',
        clientId: clientId || '',
      });
      setAlreadyRegistered(accountDetails?.alreadyRegistered);
      dispatch(setAnalyticsData({ alreadyRegistered: accountDetails?.alreadyRegistered }));
      subsLoginClick(accountDetails.alreadyRegistered, 'EMAIL');
      subsOtpView(accountDetails.alreadyRegistered);
    }
  };
  const onScreenChange = (screentype: SCREENS) => {
    setScreen(screentype);
    sendOTP({ loginType });
  };

  const submitOTP = async (otp: string) => {
    const loginResponse = await API.auth.LoginOtp({
      email: loginType === 'EMAIL' ? userEmail : undefined,
      phoneNumber: loginType === 'PHONE' ? userPhone : undefined,
      OTP: otp,
      clientId: clientId,
      eventLocation: 'SUBS_OTP',
      anonId: anonId,
      firstTimeLogin: !alreadyRegistered,
      paywallId: paywallId || '',
      landingPageId: landingPageId || '',
      tierId: tierId || '',
    });
    if (API.validators.checkSuccessCode(loginResponse)) {
      const { token, email, userId, sessionId, expiresAt, version, phoneNumber } = loginResponse.data as any;
      dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, phoneNumber, userType: 'USER', version }));
      await API.user.UpdateLoggedInClients({
        clientId,
      });
    } else {
      setError(loginResponse.data.message);
    }
  };

  return (
    // <>
    <div className={subscriptionStyle['container']}>
      {screen === SCREENS.LOGIN && (
        <UserSignUpAndLogin
          type="SUBS"
          clientId={clientId}
          setPhoneNumber={setUserPhone}
          setUserEmail={setUserEmail}
          setLoginType={setLoginType}
          onScreenChange={onScreenChange}
          data={data}
          uiConfig={uiConfig}
          loginClick={subsLoginClick}
        />
      )}
      {screen === SCREENS.OTP && (
        <VerifyOTP
          clientId={clientId}
          type="SUBS"
          data={data}
          userPhoneNumber={userPhone}
          userEmail={userEmail}
          loginType={loginType}
          otpConfig={otpConfig}
          submitOTP={submitOTP}
          setError={setError}
          error={error}
        />
      )}
    </div>
  );
};

export default SubscriptionLogin;
