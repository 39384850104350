import API from 'api';

export const phoneLogin = async function ({
  phoneNumber,
  clientId,
  internalUserId,
  isClientLoginFlow,
  utmParameters,
  eventLocation,
  anonId,
  tierId,
  paywallId,
  landingPageId,
}: {
  phoneNumber: string;
  clientId?: string;
  isClientLoginFlow?: boolean;
  internalUserId?: string;
  utmParameters?: Record<string, any>;
  eventLocation?: string;
  anonId?: string;
  tierId?: string;
  paywallId?: string;
  landingPageId?: string;
}) {
  let completedRegistration = false;
  let userId;
  let clientSpecificIds = [];
  const accountDetailsResponse = await API.user.AccountDetails({ phoneNumber: phoneNumber });
  if (!API.validators.checkSuccessCode(accountDetailsResponse)) {
    throw { message: 'Please try again later' };
  }
  userId = accountDetailsResponse?.data?.userId;

  if (internalUserId) {
    clientSpecificIds = accountDetailsResponse?.data?.clientSpecificIds;
    if (clientSpecificIds.length) {
      const clientInternalId = clientSpecificIds.find(
        (item: { clientId: string; id: string }) => item.clientId.toString() === clientId,
      );
      if (clientInternalId && clientInternalId.id !== internalUserId)
        throw { message: 'User already registerd with different details' };
    }
  }

  const accountDetails = accountDetailsResponse.data as { alreadyRegistered: boolean; hasPassword: boolean };
  if (!accountDetails.alreadyRegistered) {
    const createAccountResponse = await API.auth.CreateAccount({
      phoneNumber,
      clientId,
      utmParameters,
      eventLocation,
      anonId,
      paywallId,
      landingPageId,
      tierId,
    });
    if (!API.validators.checkSuccessCode(createAccountResponse)) {
      throw { message: 'Unable to create account, please try later' };
    }
    completedRegistration = true;
    userId = createAccountResponse?.data?.userId;
  }
  const OtpResponse = await API.auth.SendOtp({
    phoneNumber: phoneNumber,
    clientId: clientId,
    isClientLoginFlow: isClientLoginFlow,
    eventLocation,
    anonId,
    paywallId,
  });
  if (!API.validators.checkSuccessCode(OtpResponse)) {
    throw { message: OtpResponse.data.message };
  }
  return {
    accountDetails,
    completedRegistration,
    userId,
  };
};

export const emailLogin = async function ({
  email,
  clientId,
  internalUserId,
  isClientLoginFlow,
  utmParameters,
  eventLocation,
  anonId,
  tierId,
  paywallId,
  landingPageId,
}: {
  email: string;
  clientId?: string;
  isClientLoginFlow?: boolean;
  internalUserId?: string;
  utmParameters?: Record<string, any>;
  eventLocation?: string;
  anonId?: string;
  tierId?: string;
  paywallId?: string;
  landingPageId?: string;
}) {
  let completedRegistration = false;
  let userId;
  let clientSpecificIds = [];
  const accountDetailsResponse = await API.user.AccountDetails({ email: email });
  userId = accountDetailsResponse?.data?.userId;
  if (!API.validators.checkSuccessCode(accountDetailsResponse)) {
    throw { message: accountDetailsResponse.data.message };
  }

  if (internalUserId) {
    clientSpecificIds = accountDetailsResponse?.data?.clientSpecificIds;
    if (clientSpecificIds.length) {
      const clientInternalId = clientSpecificIds.find(
        (item: { clientId: string; id: string }) => item.clientId.toString() === clientId?.toString(),
      );
      if (clientInternalId && clientInternalId.id !== internalUserId)
        throw { message: 'User already registerd with different details' };
    }
  }

  const accountDetails = accountDetailsResponse.data as { alreadyRegistered: boolean; hasPassword: boolean };
  if (!accountDetails.alreadyRegistered) {
    const createAccountResponse = await API.auth.CreateAccount({
      email,
      clientId,
      utmParameters,
      eventLocation,
      anonId,
      paywallId,
      landingPageId,
      tierId,
    });
    if (!API.validators.checkSuccessCode(createAccountResponse)) {
      throw { message: 'Unable to create account, please try later' };
    }
    userId = createAccountResponse?.data?.userId;
    completedRegistration = true;
  }
  const emailResponse = await API.auth.SendVerificationEmail({
    email: email,
    clientId: clientId,
    paywallId,
    eventLocation,
    anonId,
  });
  if (!API.validators.checkSuccessCode(emailResponse)) {
    throw { message: emailResponse.data.message };
  }
  return {
    accountDetails,
    completedRegistration,
    userId,
  };
};
