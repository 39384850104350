import React, { createContext, useState, useEffect } from 'react';
import API from 'api';
import { logError } from 'utils';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { currencyType } from 'data/currencies';
import { userActions } from '../../consts';
import { AxiosResponse } from 'axios';

export const defaultUserDetails = {
  wallet: {
    balance: {
      $numberDecimal: '0',
    },
    currency: '' as currencyType,
  },
  phoneNumber: '',
  email: '',
  secondaryEmail: '',
  secondaryPhoneNumber: '',
  _id: '',
  name: '',
  address: {
    apartment: '',
    area: '',
    city: '',
    state: '',
    country: '',
    landmark: '',
    pincode: '',
  },
  defaultEmail: '',
  defaultPhone: '',
  defaultName: '',
  userDetailsSet: false,
};

export const defaultSubscriptionBundle = {
  _id: '',
  clientId: '',
  offerIds: [],
  selectedSubscriptions: [{ _id: '', tierId: '', clientId: '' }],
  price: 0,
  currency: '',
  clientUsecaseCode: '',
  utmParameters: {},
  name: '',
  phoneNumber: '',
  email: '',
  dateOfBirth: '',
  gender: '',
  employmentType: '',
  address: {
    apartment: '',
    area: '',
    pincode: '',
    landmark: '',
    city: '',
    state: '',
    country: '',
  },
};

interface selectedSubscription {
  _id: string;
  tierId: string;
  clientId: string;
}

export enum SUBSCRIPTION_BUNDLE_ROUTES {
  LOGIN = 'login',
  REVIEW_SUBSCRIPTION = 'REVIEW_SUBSCRIPTION',
  THANKYOU = 'thankYou',
  PAYMENT_PAGE = 'payment-page',
  DEFAULT_BLANK = '',
}

interface defaultSubscriptionBundleInterface {
  _id: string;
  clientId: string;
  offerIds: string[];
  selectedSubscriptions: selectedSubscription[];
  price: number;
  currency: string;
  clientUsecaseCode: string;
  utmParameters: Record<string, any>;
  name: string;
  phoneNumber: string;
  email: string;

  dateOfBirth: string;
  gender: string;
  employmentType: string;
  address?: {
    apartment: string;
    area: string;
    pincode: string;
    landmark?: string;
    city: string;
    state: string;
    country: string;
  };
}

export const BundleSubscriptionContext = createContext({
  userDetails: defaultUserDetails,
  setUserDetails: (o: any) => undefined as any,
  route: SUBSCRIPTION_BUNDLE_ROUTES.LOGIN as SUBSCRIPTION_BUNDLE_ROUTES,
  setRoute: (o: SUBSCRIPTION_BUNDLE_ROUTES) => undefined as any,
  bundledSubscriptionDetails: defaultSubscriptionBundle as defaultSubscriptionBundleInterface,
  initializeSubscriptionsBundle: () => undefined as any,
  detectedCountry: 'IN',
  detectedCurrency: 'INR',
  tempPhoneNumber: '',
  tempEmailId: '',
  referenceId: '',
  countryCode: false,
  viewId: '',
  setViewId: (o: string) => undefined as any,
  updateView: (o: { action: userActions; userId?: string }) => undefined as any,
  getUtmParameters: () => undefined as any,
  payForSubscriptionBundle: async ({
    setError,
    setLoading,
    referenceId,
  }: {
    setError?: (errorMsg: string) => any;
    setLoading?: (state: boolean) => any;
    referenceId: string;
  }) => undefined as any,
});

interface Props {
  children: JSX.Element;
}

export const ContextWrapper = ({ children }: Props) => {
  const [userDetails, setUserDetails] = useState(defaultUserDetails);
  const searchParams = new URLSearchParams(location.search);
  const authStore = useSelector((store: RootState) => store.auth);
  const [detectedCountry, setDetectedCountry] = useState('IN');
  const [detectedCurrency, setDetectedCurrency] = useState('INR');
  const [loading, setLoading] = useState(true);
  const referenceId = searchParams.get('referenceId') || '';
  const tempPhoneNumber = searchParams.get('phoneNumber') || '';
  const tempEmailId = searchParams.get('emailId') || '';
  const [viewId, setViewId] = useState(searchParams.get('viewId') || '');
  const [route, setRoute] = useState<SUBSCRIPTION_BUNDLE_ROUTES>(SUBSCRIPTION_BUNDLE_ROUTES.DEFAULT_BLANK);
  const [bundledSubscriptionDetails, setBundledSubscriptionDetails] = useState(
    defaultSubscriptionBundle as defaultSubscriptionBundleInterface,
  );

  const countryCode = tempPhoneNumber?.split('-')?.length > 1;
  const getUtmParameters = () => {
    try {
      const siteUrlString = new URLSearchParams(location.search).get('siteUrl');
      const siteUrl = siteUrlString ? new URL(decodeURIComponent(siteUrlString)) : undefined;
      const searchParams = siteUrl?.searchParams;
      const utmParams: Record<string, any> = {};
      const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
      utmKeys.map((utmKey) => {
        if (searchParams?.get(utmKey)) {
          utmParams[utmKey] = searchParams.get(utmKey);
        }
      });
      return utmParams;
    } catch (e) {
      return {};
    }
  };

  const initializeSubscriptionsBundle = async () => {
    if (searchParams.get('purchaseSubscriptionBundle') === 'true') {
      return setRoute(SUBSCRIPTION_BUNDLE_ROUTES.THANKYOU);
    }

    if (!authStore.token || authStore.userType !== 'USER') {
      return setRoute(SUBSCRIPTION_BUNDLE_ROUTES.LOGIN);
    }

    const userDetailsResponse = await API.user.Details();
    if (!API.validators.checkSuccessCode(userDetailsResponse)) {
      await logError({ error: 'Failed to get user details for user id: ' + authStore.userId });
      throw { message: 'Unable to fetch user details' };
    }

    const userDetailsData = userDetailsResponse.data as typeof defaultUserDetails;
    setUserDetails(userDetailsData);

    const TemporaryUserResponse = await API.user.TemporaryUser(referenceId);
    if (!API.validators.checkSuccessCode(TemporaryUserResponse)) {
      console.error('Failed to fetch purchase data for the user');
    } else {
      setBundledSubscriptionDetails(TemporaryUserResponse?.data);
    }

    const currencyAndCountryResponse = await API.auth.DetectCountry();
    if (!API.validators.checkSuccessCode(currencyAndCountryResponse)) {
      logError({ error: 'Unable to detect Country' });
    } else {
      setDetectedCountry(currencyAndCountryResponse.data.countryCode);
      setDetectedCurrency(currencyAndCountryResponse.data.currency);
    }

    if (window.location.href.includes('purchase-subscription-bundle')) {
      return;
    }

    if (authStore.token) {
      return setRoute(SUBSCRIPTION_BUNDLE_ROUTES.REVIEW_SUBSCRIPTION);
    }

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const currencyAndCountryResponse = await API.auth.DetectCountry();
      if (!API.validators.checkSuccessCode(currencyAndCountryResponse)) {
        logError({ error: 'Unable to detect Country' });
      } else {
        setDetectedCountry(currencyAndCountryResponse.data.countryCode);
        setDetectedCurrency(currencyAndCountryResponse.data.currency);
      }
      await initializeSubscriptionsBundle();
    })();
  }, []);

  const updateView = async ({ action, userId }: { action: userActions; userId?: string }) => {
    let newViewId = '';
    if (!viewId) {
      newViewId = await new Promise((res) => {
        window.addEventListener('view-id-created', (e) => {
          //@ts-ignore
          res(e.detail);
        });
      });
    }
    const updateViewResponse = await API.content.UpdateView({
      viewId: viewId || newViewId,
      action,
      userId: authStore.userId || userId,
      sessionId: authStore.sessionId,
    });
    const userData = updateViewResponse?.data?.view?.userData;
    setUserDetails({
      ...userDetails,
      defaultEmail: userData?.defaultEmail,
      defaultPhone: userData?.defaultPhone,
      defaultName: userData?.defaultName,
      userDetailsSet: true,
    });
  };

  async function payForSubscriptionBundle({
    // state functions from components that call this function
    setError,
    setLoading,
    referenceId,
  }: {
    setError?: (errorMsg: string) => any;
    setLoading?: (state: boolean) => any;
    referenceId: string;
  }) {
    setError?.('');
    setLoading?.(true);

    const purchaseSubscriptionBundle = (await API.user.PurchaseSubscriptionBundle(referenceId)) as AxiosResponse<any>;
    setLoading?.(false);

    if (!API.validators.checkSuccessCode(purchaseSubscriptionBundle)) {
      setError?.(purchaseSubscriptionBundle.data.message);
    }

    if (purchaseSubscriptionBundle.status === 201) {
      const purchaseSubscriptionData = purchaseSubscriptionBundle.data as any & {
        message?: string;
        purchaseId: string;
      };
      delete purchaseSubscriptionData.message;
      setTimeout(() => {
        return setRoute(SUBSCRIPTION_BUNDLE_ROUTES.THANKYOU);
      }, 1000);
    }
  }

  return (
    <BundleSubscriptionContext.Provider
      value={{
        userDetails,
        setUserDetails,
        route,
        setRoute,
        tempPhoneNumber,
        tempEmailId,
        bundledSubscriptionDetails,
        initializeSubscriptionsBundle,
        detectedCountry,
        detectedCurrency,
        viewId,
        setViewId,
        updateView,
        getUtmParameters,
        payForSubscriptionBundle,
        referenceId,
        countryCode,
      }}
    >
      {children}
    </BundleSubscriptionContext.Provider>
  );
};
