import React, { useEffect, useState, useContext } from 'react';
import style from './main.module.scss';
import {
  Balance,
  ConfirmPayment,
  Coupon,
  Login,
  PaymentConfirmed,
  TransitionThankYou,
  TransitionWelcome,
  Verify,
  Welcome,
} from './views';
import { OverlayContext, ROUTES } from './OverlayContext';
import AddInfo from '../Auth/AddInfo';

export default function Main() {
  const { route, standaloneMode } = useContext(OverlayContext);
  const [embedded, setEmbedded] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (searchParams.get('fullscreen')) {
      setEmbedded(false);
    }
  }, []);

  const RouteMap = {
    [ROUTES.WELCOME]: <Welcome />,
    [ROUTES.LOGIN]: <Login />,
    [ROUTES.VERIFY]: <Verify />,
    [ROUTES.ADD_INFO]: <AddInfo />,
    [ROUTES.BALANCE]: <Balance />,
    [ROUTES.TRANSITION_THANKYOU]: <TransitionThankYou />,
    [ROUTES.TRANSITION_WELCOME]: <TransitionWelcome />,
    [ROUTES.PAYMENT_CONFIRMED]: <PaymentConfirmed />,
    [ROUTES.CONFIRM_PAYMENT]: <ConfirmPayment />,
    [ROUTES.COUPON]: <Coupon />,
  } as Record<ROUTES, JSX.Element>;

  return (
    <>
      {/* embedded welcome page doesn't need container */}
      {route === ROUTES.WELCOME && embedded && !standaloneMode ? (
        <Welcome />
      ) : (
        <div className={style.container}>
          <div className={style['flex-container']}>{RouteMap[route]}</div>
        </div>
      )}
    </>
  );
}
