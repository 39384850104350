import React, { useState, useEffect, useContext, useRef } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import CSS from 'csstype';
import { fbPixelConfig, fireFacebookPixel } from '../../../utils/fbPixelConfig';
import { useHistory } from 'react-router-dom';
import { Wrapper, Button, Error, ButtonWithFooter, DurationSubtext } from '../../UserOverlay/components';
import * as paywallUtils from '../../UserOverlay/paywallUtils';
import { postMessage } from '../../UserOverlay/paywallUtils';
import { OverlayContext, ROUTES, PURCHASE_MODE } from '../../UserOverlay/OverlayContext';
import LoadingCircle from '../../UserOverlay/components/loaders/LoadingCircle';
import TransactionCheck from '../../UserOverlay/components/check/TransactionCheck';
import SmallAnimatedCheck from '../../UserOverlay/components/small-check/SmallAnimatedCheck';
import textStyle from '../../UserOverlay/styles/text.module.scss';
import conscentLogo from 'assets/Logo/ConsCent-LogoIcon.svg';
import { getCurrencySymbol } from '../../../data/getCurrencySymbol';
import style from '../../UserOverlay/views/welcome.module.scss';
import WelcomeHeader from 'components/WelcomeHeader';

const DefaultTemplate = () => {
  const wrapperRef = useRef<any>();
  const authStore = useSelector((store: RootState) => store.auth);
  const [showPaidCheck, setShowPaidCheck] = useState(false);
  const [scrolledToPaywall, setScrolledToPaywall] = useState(false);
  const [error, setError] = useState<JSX.Element | string>('');
  const history = useHistory();
  const {
    userDetails,
    redirectToSubscriptionPage,
    redirectToClientSignInPage,
    setGlobalError,
    setRoute,
    setPurchaseMode,
    setRouteData,
    payForContent,
    redirectMode,
    standaloneMode,
    couponDetails,
    couponForPass,
    loading,
    setLoading,
    hideSubscription,
    buttonMode,
    showLogin,
    accentColor,
    viewId,
    clientDetails,
    updateView,
    fullscreenMode,
    hideElementIfPossible,
  } = useContext(OverlayContext);
  const searchParams = new URLSearchParams(location.search);
  const isEmbedded = !standaloneMode && !fullscreenMode;

  useEffect(() => {
    if (wrapperRef?.current?.offsetHeight) {
      postMessage.SET_IFRAME_DIMENSION(
        wrapperRef.current.offsetHeight + 20,
        buttonMode ? wrapperRef.current.offsetWidth : 0,
      );
    }
  }, [wrapperRef.current?.offsetHeight, loading]);

  useEffect(() => {
    if (!updateView) return;
    if (!wrapperRef.current) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setScrolledToPaywall(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );
    observer.observe(wrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    if (scrolledToPaywall) updateView({ action: 'SCROLL' });
  }, [scrolledToPaywall]);

  useEffect(() => {
    if (!loading) {
      //@ts-ignore
      const facebookViewEvent = userDetails.contentPixels?.facebook?.events.find((o) => o.eventType === 'VIEW');
      //@ts-ignore
      const fbPixelId = userDetails.contentPixels?.facebook?.pixelId;
      if (facebookViewEvent) {
        fireFacebookPixel(fbPixelId, facebookViewEvent.name);
      }
    }
  }, [loading]);

  useEffect(() => {
    // if status is present, there is a redirect to add money page
    if (history.action === 'POP' && !searchParams.get('status')) {
      postMessage.WELCOME_PAGE_REACHED();
      if (authStore.token) {
        postMessage.LOGGEDIN(true, authStore.sessionId);
      }
    }
    if (searchParams.get('status') === 'FAILURE_PRICE') {
      if (searchParams.get('pageUrl')) {
        const clientUrl = searchParams.get('pageUrl')?.split('?')[0];
        window.location.href = clientUrl + '?error=FAILURE_PRICE';
      } else {
        postMessage.WELCOME_PAGE_REACHED();
        setError('Price of the content updated, please retry payment');
      }
    }

    if (searchParams.get('error') === 'FAILURE_PRICE') {
      setError('Price of the content updated, please retry payment');
    }
  }, [location.search]);

  useEffect(() => {
    fbPixelConfig('PageView');
  }, []);

  let passExists = userDetails?.passExists;
  let subscriptionExists = userDetails.subscriptionsExist || !hideSubscription;
  let micropricingExists = userDetails?.micropricingExists;

  // Smelly code that will hide element if possible according to preference
  const elementsActiveCount = (passExists ? 1 : 0) + (micropricingExists ? 1 : 0) + (subscriptionExists ? 1 : 0);
  const elementsThatCanBeHiddenCount = elementsActiveCount - 1;
  if (elementsThatCanBeHiddenCount > 0) {
    if (hideElementIfPossible.micropricing) micropricingExists = false;
    else if (hideElementIfPossible.subscription) subscriptionExists = false;
    else if (hideElementIfPossible.pass) passExists = false;
  }
  if (elementsThatCanBeHiddenCount > 1) {
    if (hideElementIfPossible.pass) passExists = false;
    else if (hideElementIfPossible.subscription) subscriptionExists = false;
    else if (hideElementIfPossible.micropricing) micropricingExists = false;
  }
  // smelly code ends here

  const subHeading = (
    <div className={style['middle-sub-heading']}>
      {userDetails.contentIsBundle ? (
        <>Unlock all Premium stories of this edition</>
      ) : (
        <>
          <span>
            {userDetails.micropaymentDisplaytext ? (
              userDetails.micropaymentDisplaytext
            ) : (
              <>
                Now pay only for what you {userDetails.consumptionVerb.toLowerCase()}
                {userDetails.consumptionVerb === 'listen' ? 'to' : ''}!
              </>
            )}
          </span>
        </>
      )}
    </div>
  );

  const AlreadyPurchasedText = () => {
    return !userDetails.loggedIn ? (
      <div
        className={
          micropricingExists && !subscriptionExists && !passExists
            ? style['small-sub-heading-one']
            : style['small-sub-heading']
        }
      >
        Already purchased?{' '}
        <span
          id="sel-login-phone"
          style={{ color: `${accentColor}`, cursor: 'pointer', fontSize: 14 }}
          onClick={userDetails.passExists ? purchasePassFunction : notLoggedInButtonClickFunction}
        >
          Log into ConsCent
        </span>
      </div>
    ) : (
      <></>
    );
  };

  const ConsCentBalanceText = ({ customStyle }: { customStyle?: CSS.Properties }) => {
    return (
      <>
        {userDetails.loggedIn ? (
          <div
            className={
              micropricingExists && !subscriptionExists && !passExists
                ? style['small-sub-heading-one']
                : style['small-sub-heading']
            }
            style={customStyle || { opacity: '0.6' }}
          >
            ConsCent Balance:{' '}
            {(getCurrencySymbol(userDetails.contentCurrency) as string) +
              parseFloat(userDetails.walletBalance.toFixed(2))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const notLoggedInButtonClickFunction = async () => {
    setPurchaseMode(PURCHASE_MODE.CONTENT);
    updateView({ action: 'CONTENT' });
    if (paywallUtils.shouldRedirect()) {
      postMessage.REDIRECT_TO_CONSCENT(
        window.location.href + `&viewId=${viewId}&purchaseMode=${PURCHASE_MODE.CONTENT}`,
      );
    } else {
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.LOGIN);
    }
  };

  const notLoginCouponContent = (
    <div
      onClick={() => notLoggedInButtonClickFunction()}
      className={
        micropricingExists && !subscriptionExists && !passExists ? style['coupon-title-one'] : style['coupon-title']
      }
    >
      <div>
        Have a Coupon Code?{' '}
        <span id="sel-coupon-code" style={{ color: '#15b7ca', cursor: 'pointer', fontSize: 16 }}>
          Click Here
        </span>
      </div>
    </div>
  );

  const couponContent = (
    <div
      onClick={() => {
        postMessage.WELCOME_PAGE_LEFT();
        setRouteData({ transition: true, hideBackButton: true });
        setRoute(ROUTES.COUPON);
      }}
      className={
        micropricingExists && !subscriptionExists && !passExists ? style['coupon-title-one'] : style['coupon-title']
      }
    >
      {couponDetails.code ? (
        <>
          <div>
            Use coupon <b>{couponDetails.code}</b> to {userDetails.consumptionVerb.toLowerCase()} for FREE.{' '}
            <b style={{ color: '#15b7ca', cursor: 'pointer' }}>Click&nbsp;Here</b>
          </div>
        </>
      ) : (
        <div>
          Have a Coupon Code?{' '}
          <span id="sel-coupon-code" style={{ color: '#15b7ca', cursor: 'pointer', fontSize: 16 }}>
            Click Here
          </span>
        </div>
      )}
    </div>
  );

  const getModalContent = (userHasSufficientBalance: boolean) => {
    const loggedInButtonClickFunction = () => {
      setPurchaseMode(PURCHASE_MODE.CONTENT);
      updateView({ action: 'CONTENT' });
      if (userHasSufficientBalance)
        return payForContent({
          setError,
          setLoading,
          setShowPaidCheck: standaloneMode ? () => setRoute(ROUTES.PAYMENT_CONFIRMED) : setShowPaidCheck,
          type: 'CONTENT',
        });
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.BALANCE);
      setGlobalError('Insufficient balance to purchase content');
    };

    const contentDurationSubtext = (args?: { hideDuration?: boolean }) => (
      <>
        {!args?.hideDuration && userDetails.loggedIn && (
          <DurationSubtext customStyle={{ width: '300px', margin: '0px auto' }} />
        )}
        <AlreadyPurchasedText />
      </>
    );

    const paymentHeadingText = (
      <>
        Pay {userDetails.formattedContentPrice.toUpperCase()} to {userDetails.consumptionVerb.toLowerCase()} now
      </>
    );

    let notLoggedInContent = (
      <>
        <div
          className={
            micropricingExists && !subscriptionExists && !passExists ? style['heading-wrap-one'] : style['heading-wrap']
          }
        >
          <div style={{ fontSize: micropricingExists && !subscriptionExists && !passExists ? '18px' : '14px' }}>
            {subHeading}
          </div>

          <div
            className={
              micropricingExists && !subscriptionExists && !passExists ? style['heading-one'] : style['heading']
            }
          >
            {paymentHeadingText}
          </div>
          {couponDetails.code ? (
            <div className={style['coupon-exposed']}>
              {couponDetails.newUsersOnly && <b>New User? </b>}Use coupon <b>{couponDetails.code}</b> at checkout to{' '}
              {userDetails.consumptionVerb.toLowerCase()} for <b>FREE</b>
            </div>
          ) : (
            <> {notLoginCouponContent}</>
          )}
          <ConsCentBalanceText />
        </div>

        <ButtonWithFooter
          buttonText={
            userDetails.consumptionVerb.charAt(0).toUpperCase() +
            userDetails.consumptionVerb.slice(1).toLowerCase() +
            ' ' +
            'Now'
          }
          onClickFunction={notLoggedInButtonClickFunction}
          id={'sel-beta-readNow-not-log'}
          monochromeLogo
          customTextStyle={{ fontSize: '16px' }}
          backgroundColor={accentColor}
        />

        {contentDurationSubtext({ hideDuration: !!couponDetails.code })}
      </>
    );

    let loggedInContent = (
      <>
        <div
          className={
            micropricingExists && !subscriptionExists && !passExists ? style['heading-wrap-one'] : style['heading-wrap']
          }
        >
          {subHeading}
          <div
            className={
              micropricingExists && !subscriptionExists && !passExists ? style['heading-one'] : style['heading']
            }
          >
            {paymentHeadingText}
          </div>
          {couponContent}
        </div>

        <ConsCentBalanceText customStyle={{ opacity: '1', marginTop: '16px', marginBottom: '-16px' }} />
        {userHasSufficientBalance ? (
          <>
            <ButtonWithFooter
              buttonText={`PAY ${userDetails.formattedContentPrice}`}
              onClickFunction={loggedInButtonClickFunction}
              id={'sel-welcome-payBtn'}
              monochromeLogo
              customTextStyle={{ fontSize: '16px' }}
              backgroundColor={accentColor}
            />
          </>
        ) : (
          <>
            <ButtonWithFooter
              buttonText={
                userDetails.consumptionVerb.charAt(0).toUpperCase() +
                userDetails.consumptionVerb.slice(1).toLowerCase() +
                ' ' +
                'Now'
              }
              onClickFunction={loggedInButtonClickFunction}
              id={'sel-readNow'}
              monochromeLogo
              backgroundColor={accentColor}
              customTextStyle={{ fontSize: '16px' }}
            />
          </>
        )}
      </>
    );

    if (buttonMode) {
      const paywallSettings = {
        buttonStyle: userDetails.buttonStyle,
      };
      let buttonText = `Unlock for ${userDetails.formattedContentPrice}`;
      if (showPaidCheck) buttonText = '';
      if (userDetails.buttonStyle) {
        //@ts-ignore
        if (!userDetails.buttonStyle['width']) paywallSettings.buttonStyle['width'] = 180;
        //@ts-ignore
        if (!userDetails.buttonStyle['height']) paywallSettings.buttonStyle['height'] = 40;
      }
      const ButtonOnly = (onclickFunction: any) => (
        <>
          {
            <div>
              <Button
                id="sel-button-mode"
                buttonText={buttonText}
                //@ts-ignore
                customStyle={paywallSettings.buttonStyle}
                onClickFunction={onclickFunction}
              >
                {showPaidCheck ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className={clsx(textStyle.button)}>Unlocked</span>
                    <SmallAnimatedCheck />
                  </div>
                ) : (
                  <></>
                )}
              </Button>
              <div className={(clsx(textStyle.text), style['powered-by'])} style={{ textAlign: 'center' }}>
                Powered By <img src={conscentLogo} style={{ height: 9, marginBottom: 4 }} />
              </div>
            </div>
          }
        </>
      );
      loggedInContent = ButtonOnly(loggedInButtonClickFunction);
      notLoggedInContent = ButtonOnly(notLoggedInButtonClickFunction);
    }

    return <>{userDetails.loggedIn ? loggedInContent : notLoggedInContent}</>;
  };

  const purchasePassFunction = async () => {
    updateView({ action: 'PASS' });
    setPurchaseMode(PURCHASE_MODE.PASS);
    if (userDetails.loggedIn) {
      if (userDetails.walletBalance >= userDetails.passPrice) {
        return payForContent({
          setError,
          setLoading,
          setShowPaidCheck: standaloneMode ? () => setRoute(ROUTES.PAYMENT_CONFIRMED) : setShowPaidCheck,
          type: 'PASS',
        });
      }
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.BALANCE);
      setGlobalError('Insufficient balance to purchase pass');
    } else {
      if (paywallUtils.shouldRedirect()) {
        postMessage.REDIRECT_TO_CONSCENT(window.location.href + `&viewId=${viewId}&purchaseMode=${PURCHASE_MODE.PASS}`);
      } else {
        postMessage.WELCOME_PAGE_LEFT();
        setRoute(ROUTES.LOGIN);
      }
    }
  };

  const onSubscriptionClick = async () => {
    if (userDetails.subscriptionsExist) {
      return redirectToSubscriptionPage();
    } else {
      // redirect to subscription url
      updateView({ action: 'SUB' });
      postMessage({
        type: 'subscription',
      });
    }
    // FIXME: remove later

    // setPurchaseMode(PURCHASE_MODE.SUBSCRIPTION);
    // updateView({ action: 'SUB' });

    // if (userDetails.loggedIn) {
    //   postMessage.WELCOME_PAGE_LEFT();
    //   return setRoute(ROUTES.BALANCE); // FIXME: implement subscription button click routing
    // }

    // if (paywallUtils.shouldRedirect()) {
    //   postMessage.REDIRECT_TO_CONSCENT(
    //     window.location.href + `&viewId=${viewId}&purchaseMode=${PURCHASE_MODE.SUBSCRIPTION}`,
    //   );
    // } else {
    //   postMessage.WELCOME_PAGE_LEFT();
    //   setRoute(ROUTES.LOGIN);
    // }
  };

  const SubscribeSection = () => {
    return (
      <>
        {subscriptionExists && (
          <div className={subscriptionExists && !micropricingExists && !passExists ? style['only-one-content'] : ''}>
            <div
              className={
                subscriptionExists && !micropricingExists && !passExists
                  ? style['subscription-container-one']
                  : style['pass-container']
              }
            >
              {subscriptionExists && !micropricingExists && !passExists ? (
                <div className={style['subscription-title-container']}>
                  {userDetails.subscriptionTitle ? (
                    <div className={style['subscription-title-one']}>{userDetails.subscriptionTitle}</div>
                  ) : (
                    <div className={style['subscription-title-one']}>{userDetails.subscriptionText}</div>
                  )}

                  {userDetails.subscriptionTitle && (
                    <div className={style['subscription-text-one']}>{userDetails.subscriptionText}</div>
                  )}
                </div>
              ) : (
                <div>
                  {userDetails.subscriptionTitle ? (
                    <div
                      className={
                        subscriptionExists && micropricingExists && passExists
                          ? style['subscription-title-margin']
                          : style['subscription-title']
                      }
                    >
                      {userDetails.subscriptionTitle}
                    </div>
                  ) : (
                    <div
                      className={clsx(style['subscription-text'], style['subscription-text-margin'])}
                      style={{ fontWeight: 600 }}
                    >
                      {userDetails.subscriptionText}
                    </div>
                  )}

                  {userDetails.subscriptionTitle && (
                    <div className={style['subscription-text']}>{userDetails.subscriptionText}</div>
                  )}
                </div>
              )}
              <Button
                id="sel-welcome-subscribe"
                buttonText="Subscribe Now"
                onClickFunction={onSubscriptionClick}
                customStyle={{
                  backgroundColor: `transparent`,
                  color: accentColor,
                  letterSpacing: '1px',
                  marginBottom: showLogin ? '8px' : '32px',
                  border: `1.2px solid ${accentColor}`,
                  borderRadius: '14px',
                  height: '45px',
                  marginTop: passExists ? '0px' : '26px',
                }}
                customMobileStyle={{
                  backgroundColor: `transparent`,
                  border: `1.2px solid ${accentColor}`,
                  color: accentColor,
                  borderRadius: '14px',
                  width: '250px',
                  marginTop: '24px',
                }}
                customTextStyle={{ fontSize: '16px' }}
              />
            </div>

            {showLogin && (
              <div
                className={
                  !micropricingExists && subscriptionExists && !passExists
                    ? style['small-heading-one']
                    : style['small-sub-heading']
                }
              >
                Already a subscriber?{' '}
                <a
                  href="#"
                  onClick={redirectToClientSignInPage}
                  id="sel-subscribe-sign"
                  style={{
                    cursor: 'pointer',
                    color: `${accentColor}` ? `${accentColor}` : '',
                    fontSize: 14,
                    fontFamily: 'Lato',
                  }}
                >
                  Sign in
                </a>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const couponForPassSection = () => {
    return (
      <>
        {userDetails.loggedIn ? (
          couponContent
        ) : (
          <>
            {couponForPass.code ? (
              <div className={style['pass-coupon-exposed']}>
                {couponForPass.newUsersOnly && <b>New User? </b>}Use coupon <b>{couponForPass.code}</b> at checkout to{' '}
                {userDetails.consumptionVerb.toLowerCase()} for <b>FREE</b>
              </div>
            ) : (
              <>{notLoginCouponContent}</>
            )}
          </>
        )}
      </>
    );
  };

  const PassSection = () => {
    return (
      <>
        {!micropricingExists && !subscriptionExists && passExists ? (
          <div style={{ paddingBottom: '24px' }} className={style['only-one-content']}>
            <div className={style['content-one']}>
              <div className={style['pass-text-container']}>
                <div style={{ fontSize: '18px' }}>{subHeading}</div>
                <div className={style['pass-text-one']}>
                  {userDetails.passDuration < 72
                    ? `${userDetails.passDuration}-hr${userDetails.passDuration > 1 ? "'s" : ''}`
                    : `${Math.floor(userDetails.passDuration / 24)} day`}{' '}
                  unlimited access to premium content
                </div>
              </div>

              {couponForPassSection()}

              <ButtonWithFooter
                buttonText={`Buy a pass for ${getCurrencySymbol(userDetails.passCurrency)}${
                  Math.ceil(userDetails.passPrice * 100) / 100
                }`}
                onClickFunction={purchasePassFunction}
                id={'sel-welcome-pass'}
                monochromeLogo
                backgroundColor={accentColor}
                customTextStyle={{ fontSize: '16px' }}
              />
            </div>
            <ConsCentBalanceText />
            <AlreadyPurchasedText />
          </div>
        ) : (
          <div className={style['pass-container']}>
            <div className={style['pass-text']}>
              {userDetails.passDuration < 72
                ? `${userDetails.passDuration}-hr${userDetails.passDuration > 1 ? "'s" : ''}`
                : `${Math.floor(userDetails.passDuration / 24)} day`}{' '}
              unlimited access to premium content
            </div>

            {couponForPassSection()}

            <ButtonWithFooter
              buttonText={`Buy a pass for ${getCurrencySymbol(userDetails.passCurrency)}${
                Math.ceil(userDetails.passPrice * 100) / 100
              }`}
              onClickFunction={purchasePassFunction}
              id={'sel-welcome-pass'}
              monochromeLogo
              backgroundColor={accentColor}
              transparentBackground={subscriptionExists && passExists && !micropricingExists ? false : true}
              customTextStyle={{ fontSize: '16px' }}
            />
            {!micropricingExists ? <ConsCentBalanceText /> : <></>}
          </div>
        )}
      </>
    );
  };

  if (buttonMode) {
    return (
      <>
        <div style={{ display: 'inline-block' }} ref={wrapperRef}>
          <>
            {userDetails.loggedIn ? (
              <> {getModalContent(userDetails.walletBalance >= userDetails.contentPrice)}</>
            ) : (
              <>{getModalContent(false)}</>
            )}
          </>
        </div>
      </>
    );
  }

  if (showPaidCheck || loading) {
    return (
      <Wrapper hideCross={true} hideFooter={true} embedded={isEmbedded} noPadding ref={wrapperRef} hasBorder>
        {showPaidCheck && <TransactionCheck progressBar useFixedHeight />}
        {loading && <LoadingCircle />}
      </Wrapper>
    );
  }

  let crossClickFunction = undefined;
  if (redirectMode && searchParams.get('pageUrl')) {
    crossClickFunction = () => window.location.replace(decodeURIComponent(searchParams.get('pageUrl') as string));
  } else if (!isEmbedded && !redirectMode) {
    crossClickFunction = () => {
      if (userDetails.crossButtonClickUrl) {
        postMessage.REDIRECT_TO_URL(userDetails.crossButtonClickUrl);
      } else {
        postMessage.HIDE_IFRAME();
      }
    };
  }

  const headerContent = `Continue ${
    userDetails.consumptionVerb.charAt(0) + userDetails.consumptionVerb.slice(1).toLowerCase()
  }ing...`;

  return (
    <>
      <Wrapper
        hideCross={crossClickFunction === undefined}
        crossClickFunction={crossClickFunction}
        hideFooter={true}
        embedded={isEmbedded}
        noPadding
        ref={wrapperRef}
        transparentBackground={true}
        customWidth={window.innerWidth <= 749 ? '95%' : isEmbedded ? '100%' : '750px'}
      >
        <div className={style['container']}>
          {micropricingExists && subscriptionExists && passExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <div className={style['content']}>
                <div className={style['left-col']}>
                  {getModalContent(userDetails.walletBalance >= userDetails.contentPrice)}
                </div>
                <div className={style['middle-col']}>
                  <div className={clsx(style['vertical-line'], style['middle-col-margin'])}>
                    <div className={style['or-write']}>OR</div>
                  </div>
                </div>

                <div className={style['right-col']}>
                  <PassSection />
                  <div className={style['mobile-vertical-line']}>
                    <div className={style['vertical-line']}>
                      <div className={style['or-write']}>OR</div>
                    </div>
                  </div>
                  <SubscribeSection />
                </div>
              </div>
            </>
          )}

          {micropricingExists && subscriptionExists && !passExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <div className={style['content']}>
                <div className={style['left-col']}>
                  {getModalContent(userDetails.walletBalance >= userDetails.contentPrice)}
                </div>
                <div className={style['middle-col']}>
                  <div className={style['vertical-line']}>
                    <div className={style['or-write']}>OR</div>
                  </div>
                </div>

                <div className={style['right-col-one-item']}>
                  <SubscribeSection />
                </div>
              </div>
            </>
          )}

          {micropricingExists && passExists && !subscriptionExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <div className={style['content']}>
                <div className={style['left-col']}>
                  {getModalContent(userDetails.walletBalance >= userDetails.contentPrice)}
                </div>
                <div className={style['middle-col']}>
                  <div className={style['vertical-line']}>
                    <div className={style['or-write']}>OR</div>
                  </div>
                </div>

                <div className={style['right-col-one-item']}>
                  <PassSection />
                </div>
              </div>
            </>
          )}

          {micropricingExists && !subscriptionExists && !passExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <div className={clsx(style['content-one'], style['only-one-content'])}>
                {getModalContent(userDetails.walletBalance >= userDetails.contentPrice)}
              </div>
            </>
          )}

          {subscriptionExists && !micropricingExists && !passExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <SubscribeSection />
            </>
          )}

          {passExists && !micropricingExists && !subscriptionExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <PassSection />
            </>
          )}

          {subscriptionExists && passExists && !micropricingExists && (
            <>
              <WelcomeHeader content={headerContent} customStyle={{ backgroundColor: '#f4f4f4' }} />
              <div className={style['content']}>
                <div className={style['left-col']}>
                  <PassSection />
                </div>
                <div className={style['middle-col']}>
                  <div className={style['vertical-line']}>
                    <div className={style['or-write']}>OR</div>
                  </div>
                </div>

                <div className={style['right-col-one-item']}>
                  <SubscribeSection />
                </div>
              </div>
            </>
          )}
          {error && <Error customStyle={{ paddingBottom: '12px' }}>{error}</Error>}
        </div>
      </Wrapper>
    </>
  );
};

export default DefaultTemplate;
